export const loanApplicationSummary = [
  "Total Loans Applied",
  "Average Applied Loan Amount",
  "Average Bureau Score for Applied Loans",
  "Average Monthly Income for Applied Loans",
  "Average Application Score for Applied Loan",
  "Average Loan Tenure for Applied Loans",
];
export const loanApprovalSummary = [
  "Total Loans Approved",
  "Average Application Score for Approved Loan",
  "Average Loan Tenure for Approved Loans",
];
export const applicantsSummary = [
  "Total Customers",
  "Total Coborrowers",
  "Total Guarantors",
];
export const loanDeclinedSummary = [
  "Total Loans Declined",
  "Total Loans Declined due to BRE Evaluation",
  "Total Loans Declined due to MRAC failed",
  "Total Loans Declined due to Inactive Company",
  "Average Declined Loan Amount",
  "Average Bureau Score for Declined Loans",
  "Average Loan Tenure for Declined Loans",
];
export const loanPendingSummary = [
  "Total Loans_Pending Decision > 7D",
  "Total Loans_Pending Decision > 30D",
];
export const collateralSummary = [
  "Total Loans with Collateral",
  "Percentage of Loans with Collateral",
];
export const loanTenure = [
  "Average Loan Tenure for Applied Loans",
  "Average Loan Tenure for Approved Loans",
  "Average Loan Tenure for Declined Loans",
];
export const applicantIncomeSummary = [
  "Average Monthly Income for Applied Loans",
  "Average Monthly Income for Approved Loans",
  "Average Monthly Income for Declined Loans",
];
export const applicantScoreSummary = [
  "Average Application Score for Applied Loan",
  "Average Application Score for Approved Loan",
  "Average Application Score for Declined Loan",
  "Average Bureau Score for Applied Loans",
  "Average Bureau Score for Approved Loans",
  "Average Bureau Score for Declined Loans",
];
export const breSummary = ["Total Loans Declined due to BRE Evaluation"];
export const disbursalSummary = [
  "Total count of loans Disbursed",
  "Total Loan Amount Disbursed",
];
export const reportDataColumns = [
  // {field: "dimension", headerName: "Dimension"},
  { field: "total_customers", headerName: "Total Customers" },
  { field: "total_applied_loans", headerName: "Total applied loans" },
  { field: "total_coborrowers", headerName: "Total Co-borrowers" },
  { field: "total_guarantors", headerName: "Total Guarantors" },
  { field: "total_loans_processed", headerName: "Total loans processed" },
  { field: "total_loans_approved", headerName: "Total loans (approved)" },

  {
    field: "sum_approved_loan_amount",
    headerName: "Sum of approved loan amounts",
  },

  { field: "total_loans_disbursed", headerName: "Total loans disbursed" },

  {
    field: "total_loan_amount_disbursed",
    headerName: "Total loan amount disbursed",
  },
  { field: "total_loans_declined", headerName: "Total Loans (declined)" },
  {
    field: "total_loans_declined_bre",
    headerName: "Total loans (declined from BRE)",
  },
  {
    field: "total_loans_declined_inactive",
    headerName: "Total loans declined (inactive)",
  },
  {
    field: "total_loans_declined_mrac",
    headerName: "Total loans declined (MRAC)",
  },
  {
    field: "total_loans_pending_3days",
    headerName: "Total pending loans (3 days)",
  },
  {
    field: "total_loans_pending_7days",
    headerName: "Total pending loans (7 days",
  },
  {
    field: "total_loans_pending_30days",
    headerName: "Total pending loans (30 days)",
  },
  {
    field: "average_applied_loan_amount",
    headerName: "Average loan amount (applied loans)",
  },
  {
    field: "average_approved_loan_amount",
    headerName: "Average Loan amount (approved loans)",
  },
  {
    field: "average_declined_loan_amount",
    headerName: "Average Loan amount (declined loans)",
  },
  {
    field: "average_monthly_income_applied_loans",
    headerName: "Average Monthly income (applied loans)",
  },
  {
    field: "average_monthly_income_approved_loans",
    headerName: "Average Monthly income (approved loans)",
  },
  {
    field: "average_monthly_income_declined_loans",
    headerName: "Average Monthly income (declined loans)",
  },
  {
    field: "average_bureau_score_applied_loans",
    headerName: "Average bureau score (applied loans)",
  },
  {
    field: "average_bureau_score_approved_loans",
    headerName: "Average Bureau score (approved loans)",
  },
  {
    field: "average_bureau_score_declined_loans",
    headerName: "Average Bureau score (declined loans)",
  },
  {
    field: "average_application_score_applied_loans",
    headerName: "Average application score (applied loans)",
  },
  {
    field: "average_application_score_approved_loans",
    headerName: "Average application score (approved loans)",
  },
  {
    field: "average_application_score_declined_loans",
    headerName: "Average application score (declined score)",
  },

  {
    field: "average_loan_tenure_applied_loans",
    headerName: "Average Loan Tenure (applied loans)",
  },
  {
    field: "average_loan_tenure_approved_loans",
    headerName: "Average Loan Tenure (approved loans)",
  },
  {
    field: "average_loan_tenure_declined_loans",
    headerName: "Average Loan Tenure (declined loans)",
  },
  { field: "total_loans_with_stp", headerName: "Total loans with STP" },
  {
    field: "percentage_loans_with_stp",
    headerName: "Percentage loans with STP",
  },

  {
    field: "total_loans_with_collateral",
    headerName: "Total loans with Collateral",
  },
  {
    field: "percentage_loans_with_collateral",
    headerName: "Percentage loans with Collateral",
  },
  {
    field: "total_loans_verification_completed",
    headerName: "Total collateral verfiications completed",
  },
  {
    field: "total_loans_verification_pending",
    headerName: "Total collateral verifications pending",
  },
];
