import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Alert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress";
import {
  mainListItems,
  secondaryListItems,
  mainListItemsIndividual,
} from "../Side_Nav/listItems";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TextField } from "@material-ui/core";
import LogoutIcon from "@mui/icons-material/Logout";
import Tooltip from "@mui/material/Tooltip";
import LoanDetails from "../Common/basicLoanDetails";
import { base_url1 } from "../../constants";
import Axios from "axios";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useRef } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BankTransactions from "./../PrecisaBankStatementAnalysis/transaction";
import { useDispatch } from "react-redux";
import { precisaBankDetails } from "../../redux/actions/LoanActions";
import Category from "./../PrecisaBankStatementAnalysis/category";
import CashFlow from "./../PrecisaBankStatementAnalysis/cashFlow";
import DailyBalance from "./../PrecisaBankStatementAnalysis/dailyBalance";
import Overview from "./../PrecisaBankStatementAnalysis/overview";
import Irregularities from "./../PrecisaBankStatementAnalysis/irregularity";
import Summary from "./../PrecisaBankStatementAnalysis/summary";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import AppHeader from "./../Common/header";
import SessionModal from "./../Common/SessionModal";
import {
  uploadCMSDocuments,
  getCMSDocuments,
  getBankStatementDocument,
  getBankStatementDocumentFin360,
  pennyDrop,
  rerunBRE,
  sendBreMail,
  getBankNames,
  refreshBankStatement,
  BREreRun,
  getDownloadUrl,
} from "./../apiCalls/api";
import Footer from "./../Common/footer";
import MenuItem from "@mui/material/MenuItem";
import RefreshIcon from "@mui/icons-material/Refresh";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));
const mdTheme = createTheme();
const handleChangeInput = (event) => {};
function BaskStatement() {
  const [value, setValue] = React.useState(0);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const navigate = useNavigate();
  const redux = useSelector((state) => state.allApplications?.application);
  const reduxData = useSelector(
    (state) => state.applications?.loan_basic_info?.Loan_Application_id
  );
  const loanType = useSelector(
    (state) => state.applications?.loan_basic_info?.loan_type
  );
  const dispatch = useDispatch();
  const [showResults, setShowResults] = React.useState(false);
  const [isAPILoading, setisAPILoading] = React.useState(false);
  const [uploadFailed, setuploadFailed] = React.useState(false);
  const [showInputs1, setShowInputs1] = React.useState(false);
  const [buttonDisable, setButtonDisable] = React.useState(true);
  const [password1, setpassword1] = React.useState(null);
  const [bankDropDown, setbankDropDown] = React.useState([]);
  const [bankDetails, setbankDetails] = React.useState();
  const [bankExtractionFlag, setbankExtractionFlag] = React.useState(false);
  const [selectedValue, setSelectedValue] = useState(0);
  const [bankDetailsDropDown, setbankDetailsDropDown] = React.useState([]);
  const [bankDetailsList, setbankDetailsList] = React.useState();
  const currentUser = useSelector((state) => state.currentUserDetails);
  const [documentList, setdocumentList] = React.useState([]);
  const [filteredDocuments, setFilteredDocuments] = React.useState([]);
  const [filteredDocumentsGuarantor, setFilteredDocumentsGuarantor] =
    React.useState([]);
  const [filterValue, setFilterValue] = React.useState("BankStatement");
  const [filterValueGuarantor, setFilterValueGuarantor] = React.useState(
    "GuarantorBankStatement"
  );
  const [isRefreshLoading, setIsRefreshLoading] = React.useState(false);
  const [error, setError] = useState(null);
  const [downloadUrl, setDownloadUrl] = React.useState();
  const inputRef = useRef(null);
  const viewDocument = async () => {
    const filteredList = documentList.filter((item) => {
      return item.DocumentType.startsWith(filterValue);
    });
    downloadFile(filteredList);

    // window.open(bankDetails.bank_file_name_with_path, "_blank");
  };
  const downloadFile = async (filteredList) => {
    try {
        for (const item of filteredList) {
            try {
                const result = await getDownloadUrl(item.Filepath);
                window.open(result.data.response_data.download_url, "_blank");
            } catch (error) {
                if (error.message) {
                  console.error("Error:", error);
                }
            }
        }
    } catch (error) {
        console.error("Error:", error);
    }
  };
  // const performAction = () => {
  //   if (redux == undefined) {
  //     // Perform action when data is empty
  //     navigate("/login");

  //   } else {
  //     // Perform action when data is not empty

  //   }
  // };
  const getBankDocumentsFunction = async () => {
    try {
      const result = await getBankStatementDocument(reduxData);
      if (result.data.data.length != 0) {
        setbankDetails(result.data.data[0]);
      } else {
        setbankDetails({});
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const getCMSDocumentsFunction = async () => {
    try {
      const result = await getCMSDocuments(reduxData);
      if (result.data.data.length != 0) {
        setdocumentList(result.data.data);
        const filteredList = result.data.data.filter((item) => {
          return item.DocumentType.startsWith(filterValue);
        });
        const filteredList1 = result.data.data.filter((item) => {
          return item.DocumentType.startsWith(filterValueGuarantor);
        });
        setFilteredDocuments(filteredList);
        setFilteredDocumentsGuarantor(filteredList1);
      } else {
        setdocumentList([]);
        setFilteredDocuments([]);
        setFilteredDocumentsGuarantor([]);
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const getFin360Function = async () => {
    try {
      const result = await getBankStatementDocumentFin360(reduxData);
      if (result.data.data.length != 0) {
        setbankExtractionFlag(true);
        const secondStructure = result.data.data.map((subArray, index) => ({
          label: subArray[0].file_name,
          value: index,
        }));
        setbankDropDown(secondStructure);
        console.log(JSON.stringify(secondStructure));
        setbankDetailsDropDown(result.data.data);
        dispatch(precisaBankDetails(result.data.data[0]));
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const getBankNamesList = async () => {
    try {
      const result = await getBankNames(reduxData);
      if (result) {
        setbankDetailsList(result.data);
        setButtonDisable(false);
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  useEffect(() => {
    function getData() {
      getBankDocumentsFunction();
      getCMSDocumentsFunction();
      getFin360Function();
      getBankNamesList();
    }
    getData();
  }, [reduxData]);
  const columns = [
    {
      field: "Filename",
      headerName: "File Name",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "cms_upload",
      headerName: "Uploaded from Credit Ops",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "DocumentType",
      headerName: "Document Type",
      flex: 1,
      minWidth: 90,
    },
  ];
  const actionColumn = [
    {
      field: "action",
      headerName: "Actions",
      width: 90,
      flex: 1,
      minWidth: 90,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div className="viewButton">
              <Tooltip title="Download File" sx={{ mt: -1 }}>
                <IconButton
                  onClick={(e) => downloadSingleFile(params.row)}
                  style={{ color: "#3f48cc" }}
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="View File" sx={{ mt: -1 }}>
                <IconButton
                  onClick={(e) => handleViewClick(params.row)}
                  style={{ color: "#3f48cc" }}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip> */}
            </div>
          </div>
        );
      },
    },
  ];
  const actionColumn1 = [
    {
      field: "refresh",
      headerName: "Refresh",
      width: 90,
      flex: 1,
      minWidth: 90,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div className="viewButton">
              <Tooltip title="Refresh Statement" sx={{ mt: -1 }}>
                <IconButton
                  onClick={(e) => refresh(params.row)}
                  style={{
                    color: isRefreshLoading ? "grey" : "#3f48cc",
                    cursor: isRefreshLoading ? "not-allowed" : "pointer",
                    opacity: isRefreshLoading ? 0.5 : 1,
                  }}
                  disabled={isRefreshLoading}
                >
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];
  const downloadSingleFile = async (e) => {
    try {
      const result = await getDownloadUrl(
        e.Filepath
      );
      window.open(result.data.response_data.download_url, "_blank");
    }catch (error) {
      if (error.message) {
        console.error("Error:", error);
      }
    }
  };

  const handleChangeBankStatementType = async (e) => {
    setSelectedValue(e);
    try {
      const result = await getBankStatementDocumentFin360(reduxData);
      if (result.data.data.length != 0) {
        dispatch(precisaBankDetails(result.data.data[e]));
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const handleViewClick = (e) => {
    const blobUrl = e.Filepath;
    viewFile(blobUrl);
  };
  const viewFile = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;

    const isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    if (isChrome) {
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.target = "_blank";
      anchor.click();
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    navigate("/login");
  };
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const pennyDrop1 = async () => {
    try {
      const result = await pennyDrop(
        reduxData,
        bankDetails.ifsc_code,
        bankDetails.account_number
      );
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const onFileInputChange = async (event) => {
    const loanApplicationId = reduxData;
    let data = null;
    data = new FormData();
    data.append("LoanApplicationId", loanApplicationId);
    data.append("BankStatement1", event.target.files[0]);
    data.append("document_comment", "Testing");
    try {
      const documents = await uploadCMSDocuments(data);

      const result = await getCMSDocuments(reduxData);
      if (result.data.data.length != 0) {
        setdocumentList(result.data.data);
        const filteredList = result.data.data.filter((item) => {
          return item.DocumentType.startsWith(filterValue);
        });
        const filteredList1 = result.data.data.filter((item) => {
          return item.DocumentType.startsWith(filterValueGuarantor);
        });
        setFilteredDocuments(filteredList);
        setFilteredDocumentsGuarantor(filteredList1);
      } else {
        setdocumentList([]);
        setFilteredDocuments([]);
        setFilteredDocumentsGuarantor([]);
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const handleRowSelection = (selectionModel) => {
    setSelectedRowIds(selectionModel);
  };
  const rerunBRE1 = async () => {
    try {
      setShowResults(true);
      const rerunBre = await BREreRun(reduxData);
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setisAPILoading(false);
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
    // try {
    //   setShowResults(true);
    //   const sendMessage = await sendBreMail(reduxData);
    // } catch (error) {
    //   if (error.message === "AxiosError: Request failed with status code 401") {
    //     setisAPILoading(false);
    //     setIsErrorModalOpen(true);
    //     console.error(error);
    //   }
    // }
  };
  const refresh = async (e) => {
    setisAPILoading(true);
    setIsRefreshLoading(true);
    try {
      const refresh = await refreshBankStatement(e.ApplicantDocumentId);
      if (refresh) {
        setisAPILoading(false);
        setIsRefreshLoading(false);
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsRefreshLoading(false);
        setisAPILoading(false);
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
    // try {
    //   setShowResults(true);
    //   const sendMessage = await sendBreMail(reduxData);
    // } catch (error) {
    //   if (error.message === "AxiosError: Request failed with status code 401") {
    //     setisAPILoading(false);
    //     setIsErrorModalOpen(true);
    //     console.error(error);
    //   }
    // }
  };
  const [selectedRow, setSelectedRow] = useState([]);

  const handleCheckboxChange = (rowId) => {
    setSelectedRow(rowId);
  };

  const updatedColumns = columns.map((column) => {
    if (column.field === "__checkbox") {
      return {
        ...column,
        renderCell: (params) => (
          <Checkbox
            checked={params.row.id === selectedRow}
            onChange={() => handleCheckboxChange(params.row.id)}
          />
        ),
      };
    }
    return column;
  });
  const fileUpload = () => {
    inputRef.current.click();
  };
  const filePopup = () => {
    setShowInputs1(!showInputs1);
    setpassword1(null);
    setBankName1(null);
    setBankStatement1(null);
    setuploadFailed(false);
  };
  const handleBankNameChange1 = (e) => {
    setBankName1(e.target.value);
  };
  const [bankName1, setBankName1] = React.useState();
  const [bankStatement1, setBankStatement1] = React.useState();
  const allowedFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];

  const fileCheck = (file) => {
    const fileType = file.type;
    const maxFilesize = 15 * 1024 * 1024;
  
    if (file.size > maxFilesize) {
      return 'File size exceeds the limit (15MB).';
    } else if (!allowedFileTypes.includes(fileType)) {
      return 'Invalid File Type. Only JPEG, PNG and PDF allowed.';
    } else {
      return null; // No error
    }
  };
  
  const handleBankStatement1 = (e) => {
    const selectedFile = e.target.files[0];
    const error = fileCheck(selectedFile);
    
    if (error) {
      setError(error);
      e.target.value = '';
      alert(error);
    } else {
      setBankStatement1(selectedFile);
    }
  };
  const handlePasswordChange1 = (e) => {
    setpassword1(e.target.value);
  };
  const uploadCMSFile = async () => {
    setisAPILoading(true);
    const loanApplicationId = reduxData;
    let data = null;
    data = new FormData();
    data.append("LoanApplicationId", loanApplicationId);
    data.append("document_comment", "Testing");
    data.append("BankName2", bankName1);
    data.append("BankStatement2", bankStatement1);
    if (password1 != undefined) {
      data.append("BankStatement2_password", password1);
    }
    try {
      const documents = await uploadCMSDocuments(data);
      if (documents.data.upload_status[0].errorCode != 200) {
        console.log("DOc is not correct");
        setuploadFailed(true);
        setisAPILoading(false);
      } else {
        const result = await getCMSDocuments(reduxData);
        if (result.data.data.length != 0) {
          setdocumentList(result.data.data);
          const filteredList = result.data.data.filter((item) => {
            return item.DocumentType.startsWith(filterValue);
          });
          const filteredList1 = result.data.data.filter((item) => {
            return item.DocumentType.startsWith(filterValueGuarantor);
          });
          setFilteredDocuments(filteredList);
          setFilteredDocumentsGuarantor(filteredList1);
        } else {
          setdocumentList([]);
          setFilteredDocuments([]);
          setFilteredDocumentsGuarantor([]);
        }
        setisAPILoading(false);
        filePopup();
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsErrorModalOpen(true);
        console.error(error);
        setisAPILoading(false);
      }
    }
  };

  const handleCellClick = (params, event) => {
    if (event.target.type == "checkbox") {
      if (
        selectedRow.some(
          (selectedRows) => selectedRows === params.row.account_uid
        )
      ) {
        setSelectedRow(
          selectedRow.filter(
            (selectedRows) => selectedRows !== params.row.account_uid
          )
        );
      } else {
        setSelectedRow([...selectedRow, params.row.account_uid]);
      }
    }
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const Results = () => <div id="results" className="search-results"></div>;
  return (
    <ThemeProvider theme={mdTheme}>
      <div>
        {/* Your component content */}
        <SessionModal
          isOpen={isErrorModalOpen}
          onClose={handleCloseErrorModal}
        />
      </div>
      <Box sx={{ display: "flex", mb: 6 }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <AppHeader
              open={open}
              toggleDrawer={toggleDrawer}
              title="Bank Statement"
            />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          {loanType == "Individual" ? (
            <List component="nav">
              {mainListItemsIndividual}
              <Divider />
              {currentUser.role_name === "credit_head" ||
              currentUser.role_name === "credit_ops" ? (
                <>{secondaryListItems}</>
              ) : null}
            </List>
          ) : (
            <List component="nav">
              {mainListItems}
              <Divider />
              {currentUser.role_name === "credit_head" ||
              currentUser.role_name === "credit_ops" ? (
                <>{secondaryListItems}</>
              ) : null}
            </List>
          )}
        </Drawer>

        <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
          {isAPILoading ? (
            <Grid item xs={12}>
              <LinearProgress
                sx={{
                  color: "#3f48cc",
                }}
                size={40}
                thickness={4}
              />
            </Grid>
          ) : null}
          {currentUser.role_name != "branch_manager" ? (
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Bank Statement"
                  {...a11yProps(0)}
                  style={{ textTransform: "capitalize" }}
                />
                <Tab
                  label="Guarantor's Bank Statement"
                  {...a11yProps(1)}
                  style={{ textTransform: "capitalize" }}
                />
                {/* {filteredDocumentsGuarantor.length > 0 ? (
               
              ) : null} */}

                <Tab
                  label="Bank Overview"
                  {...a11yProps(2)}
                  style={{ textTransform: "capitalize" }}
                  disabled={!bankExtractionFlag}
                />
                <Tab
                  label="Bank Transactions"
                  {...a11yProps(3)}
                  style={{ textTransform: "capitalize" }}
                  disabled={!bankExtractionFlag}
                />
                <Tab
                  label="Summary"
                  {...a11yProps(4)}
                  style={{ textTransform: "capitalize" }}
                  disabled={!bankExtractionFlag}
                />
              </Tabs>
            </Box>
          ) : (
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Bank Statement"
                  {...a11yProps(0)}
                  style={{ textTransform: "capitalize" }}
                />
                <Tab
                  label="Guarantor's Bank Statement"
                  {...a11yProps(1)}
                  style={{ textTransform: "capitalize" }}
                />
              </Tabs>
            </Box>
          )}
          <div style={{ marginTop: "1%" }}>
            <LoanDetails></LoanDetails>
            <TabPanel value={value} index={0}>
              <Grid
                item
                xs={12}
                md={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                style={{
                  width: "100%",
                }}
              >
                <Button
                  sx={{ textTransform: "none" }}
                  variant="contained"
                  className="download-button"
                  onClick={viewDocument}
                  disabled={buttonDisable}
                >
                  Download Documents
                </Button>
                <Button
                  sx={{ textTransform: "none" }}
                  variant="contained"
                  className="download-button"
                  onClick={filePopup}
                  disabled={buttonDisable}
                >
                  Upload Document
                </Button>
              </Grid>

              {showInputs1 ? (
                <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                  <div className="details-box">
                    <Grid container spacing={2}>
                      <>
                        {isAPILoading ? (
                          <Grid item xs={12}>
                            <LinearProgress
                              sx={{
                                color: "#3f48cc",
                              }}
                              size={40}
                              thickness={4}
                            />
                          </Grid>
                        ) : null}
                        <Grid item xs={12} sm={12} md={12}>
                          {uploadFailed ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  ml: 5,
                                  ml: 0,
                                }}
                              >
                                <Alert severity="error">
                                  Sorry! Either the password or bank statement
                                  is incorrect.
                                </Alert>
                              </Grid>
                            </>
                          ) : null}
                          <Typography
                            sx={{ mr: 4, flex: 1, pt: 2, pb: 2 }}
                            component="div"
                            style={{ fontWeight: "800", color: "#3f48cc" }}
                          >
                            Upload a Document
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            select
                            id="Choose Bank"
                            label="Choose Bank"
                            variant="outlined"
                            onChange={handleBankNameChange1}
                            fullWidth
                          >
                            {bankDetailsList.response_data &&
                              bankDetailsList.response_data.map((option) => (
                                <MenuItem
                                  key={option.bank_name}
                                  value={option.bank_name}
                                  sx={{
                                    fontFamily:
                                      "'Roboto Condensed', sans-serif",
                                    "&:hover": {
                                      borderLeft: "5px solid #3f48cc",
                                      borderRadius: 1,
                                    },
                                  }}
                                >
                                  {option.bank_name}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <div
                            style={{
                              border: "1px solid #dadada",
                              height: 55,
                              borderRadius: 3,
                            }}
                          >
                            <form id="inputType1">
                              <input
                                style={{
                                  marginTop: 15,
                                  marginLeft: 10,
                                  width: "91%",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                                type="file"
                                onChange={handleBankStatement1}
                                name="bankStatement"
                              ></input>
                            </form>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            type="password"
                            variant="outlined"
                            name="Password1"
                            label="Password"
                            onChange={handlePasswordChange1}
                            value={password1}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          style={{
                            width: "100%",
                          }}
                        >
                          <Button
                            sx={{ textTransform: "none" }}
                            variant="contained"
                            className="download-button"
                            onClick={uploadCMSFile}
                          >
                            Confirm
                          </Button>
                        </Grid>
                      </>
                    </Grid>
                  </div>
                </Grid>
              ) : null}
              {/* {showResults ? <Results /> : null} */}
              {bankDetails != undefined ? (
                <div className="details-box">
                  <Grid item xs={12} sx={{ pt: 4 }}>
                    <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                      <Grid item xs={6} sm={8} md={8}>
                        <Typography
                          sx={{ mr: 4, flex: 1, pt: 2, pb: 2 }}
                          component="div"
                          style={{ fontWeight: "800", color: "#3f48cc" }}
                        >
                          Bank Details
                        </Typography>
                      </Grid>

                      {currentUser.role_name != "branch_manager" ? (
                        <>
                          {bankExtractionFlag ? (
                            <Grid item xs={12} md={4}>
                              <TextField
                                select
                                variant="outlined"
                                value={selectedValue}
                                onChange={(e) =>
                                  handleChangeBankStatementType(e.target.value)
                                }
                                fullWidth
                                label="Select Bank Statement"
                              >
                                {bankDropDown.map((option, key) => (
                                  <MenuItem
                                    key={key}
                                    value={option.value}
                                    sx={{
                                      fontFamily:
                                        "'Roboto Condensed', sans-serif",
                                      "&:hover": {
                                        borderLeft: "5px solid #3f48cc",
                                        borderRadius: 1,
                                      },
                                    }}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                          ) : null}
                        </>
                      ) : null}
                      {/* <Grid
                        item
                        xs={6}
                        md={6}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        style={{
                          width: "100%",
                        }}
                      >
                        <Button
                          sx={{ textTransform: "none" }}
                          variant="contained"
                          className="download-button"
                          onClick={pennyDrop1}
                        >
                          Penny Drop
                        </Button>
                      </Grid> */}
                      {/* {Object.entries(bankDetails).map(([key, value]) =>
                        key ? (
                          <Grid key={key} item xs={12} sm={6} md={6} mt={3}>
                            <TextField
                              fullWidth
                              type="text"
                              variant="outlined"
                              name={key}
                              label={key
                                .replace(/_/g, " ")
                                .toLowerCase()
                                .split(" ")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ")}
                              value={value}
                              // onChange={(e) => handleInputChange(e)}
                            />
                          </Grid>
                        ) : null
                      )} */}
                    </Grid>
                  </Grid>
                  {filteredDocuments.length > 0 ? (
                    <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
                      {showResults ? (
                        <Grid
                          item
                          xs={12}
                          sx={{ display: "flex", ml: 5, ml: 0 }}
                        >
                          <Alert severity="success">
                            BRE run is currently in progress. Please note this
                            might take some time to complete.
                          </Alert>
                        </Grid>
                      ) : null}
                      {/* <img src={pic1} style={{ margin: "1%" }} /> */}
                      <Container maxWidth align="center">
                        <Grid item xs={12} sx={{ pt: 4 }}>
                          <Grid>
                            <div style={{ width: "98%" }}>
                              <b>List of Documents</b>
                              <div className="table-container1">
                                <DataGrid
                                  rows={filteredDocuments}
                                  columns={updatedColumns.concat(
                                    actionColumn1,
                                    actionColumn
                                  )}
                                  getRowId={(row) => row.ApplicantDocumentId}
                                  density="comfortable"
                                  // checkboxSelection
                                  // onCellClick={handleCellClick}
                                  // selectionModel={
                                  //   selectedRow ? [selectedRow] : []
                                  // }
                                  // disableSelectionOnClick // disable selecting whole row
                                  sx={{
                                    "&.MuiDataGrid-root": {
                                      border: "none ",
                                    },
                                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within":
                                      {
                                        outline: "none !important",
                                      },
                                    "&.MuiDataGrid-root .MuiDataGrid-columnHeader--sortable":
                                      {
                                        outline: "none !important",
                                      },
                                    "&.MuiDataGrid-root .MuiDataGrid-cell": {
                                      whiteSpace: "normal !important",
                                      wordWrap: "break-word !important",
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Container>
                      <Grid
                        item
                        xs={12}
                        md={2}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        style={{
                          width: "100%",
                        }}
                      >
                        <Button
                          sx={{ textTransform: "none" }}
                          variant="contained"
                          disabled={currentUser.role_name != "credit_head"}
                          className="download-button"
                          onClick={rerunBRE1}
                        >
                          Re-Run BRE
                        </Button>
                      </Grid>
                    </Box>
                  ) : null}
                </div>
              ) : null}
            </TabPanel>
            {filteredDocumentsGuarantor.length > 0 ? (
              <TabPanel value={value} index={1}>
                <div className="details-box">
                  <Grid item xs={12} sx={{ pt: 4 }}>
                    <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography
                          sx={{ mr: 4, flex: 1, pt: 2, pb: 2 }}
                          component="div"
                          style={{ fontWeight: "800", color: "#3f48cc" }}
                        >
                          Guarantor's Bank Details
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {filteredDocumentsGuarantor.length > 0 ? (
                    <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
                      <Container maxWidth align="center">
                        <Grid item xs={12} sx={{ pt: 4 }}>
                          <Grid>
                            <div style={{ width: "98%" }}>
                              <b>List of Documents</b>
                              <div className="table-container1">
                                <DataGrid
                                  rows={filteredDocumentsGuarantor}
                                  columns={columns.concat(actionColumn)}
                                  getRowId={(row) => row.ApplicantDocumentId}
                                  density="comfortable"
                                  disableSelectionOnClick={true}
                                  sx={{
                                    "&.MuiDataGrid-root": {
                                      border: "none ",
                                    },
                                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within":
                                      {
                                        outline: "none !important",
                                      },
                                    "&.MuiDataGrid-root .MuiDataGrid-columnHeader--sortable":
                                      {
                                        outline: "none !important",
                                      },
                                    "&.MuiDataGrid-root .MuiDataGrid-cell": {
                                      whiteSpace: "normal !important",
                                      wordWrap: "break-word !important",
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Container>
                    </Box>
                  ) : null}
                </div>
              </TabPanel>
            ) : null}
            {currentUser.role_name != "branch_manager" ? (
              <>
                <TabPanel
                  value={value}
                  index={2}
                  style={{ marginTop: "-3%", width: "97%", marginLeft: "2%" }}
                >
                  <Overview />
                </TabPanel>
                <TabPanel value={value} index={3} style={{ marginTop: "-4%" }}>
                  <BankTransactions />
                </TabPanel>
                <TabPanel
                  value={value}
                  index={4}
                  style={{ marginTop: "-3%", width: "94%", marginLeft: "3%" }}
                >
                  <Summary />
                </TabPanel>
              </>
            ) : null}
            {/* <TabPanel value={value} index={4} style={{ marginTop: "-4%" }}>
              <DailyBalance />
            </TabPanel>
            <TabPanel
              value={value}
              index={5}
              style={{ marginTop: "-3%", width: "94%", marginLeft: "3%" }}
            >
              <Category />
            </TabPanel>
            <TabPanel value={value} index={6} style={{ marginTop: "-4%" }}>
              <CashFlow />
            </TabPanel> */}
          </div>
        </Box>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}

export default function Bankstatement() {
  return <BaskStatement />;
}
