import React from "react";

const VideoPlayer = ({ base64Data }) => {
  const videoSource = `data:video/mp4;base64,${base64Data}`;

  return (
    <div>
      <video
        controls
        style={{
          height: "95%",
          width: "100%",
        }}
      >
        <source src={videoSource} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;
