import React from "react";
import { useState } from "react";
import {
  Box,
  Container,
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
  Typography,
  TextField,
  Button,
  Divider,
} from "@material-ui/core";
import Grid from "@mui/material/Grid";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  setLoanApplications,
  selectedApplication,
  makerCheckAccess,
} from "../../redux/actions/LoanActions";
import { useSelector } from "react-redux";
import Axios from "axios";
import { base_url1 } from "../../constants";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  getAllApplications,
  getApplication,
  getBREDecision,
  getrejectReason,
} from "./../apiCalls/api";
import SessionModal from "./../Common/SessionModal";
import { useNavigate } from "react-router-dom";
export const theme = createTheme({
  typography: {
    //fontFamily: "Times New Roman",
  },
});
const columns = [
  {
    field: "node_name",
    headerName: "Node Name",
    flex: 1,
    maxWidth: 200,
  },
  {
    field: "expression",
    headerName: "Condition",
    flex: 1,
    minWidth: 70,
  },
];
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#ffffff",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
export default function LoanDetails() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [reason, setReason] = React.useState();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [details, setData] = useState([]);
  const [loanDescription, setloanDescription] = useState(null);
  const dispatch = useDispatch();
  const [visitedNodes, setVisitedNodes] = useState([]);
  const currentUser = useSelector((state) => state.currentUserDetails);
  const [selectedValue, setSelectedValue] = useState();
  const [selectedValue1, setSelectedValue1] = useState();
  const [rejectReason, setrejectReason] = useState("");
  // subscribe to the relevant slice of the Redux store
  const reduxData = useSelector((state) => state.applications?.loan_basic_info);
  const roleAccess = useSelector((state) => state.applications?.role_access);
  const selectedApplications = useSelector(
    (state) => state.applicationsdropdown[0]
  );

  // const details = useSelector((state) => state.applications?.loan_basic_info);

  useEffect(() => {
    // Check if selectedApplications is undefined or empty
    if (!selectedApplications || selectedApplications.length === 0) {
      // Redirect to the login page (replace '/login' with the correct login page URL)
      //window.location.href = "/dashboard";
      navigate("/details");
      return;
    } else {
      const value = selectedApplications.findIndex(
        (item) => item.value === reduxData?.Loan_Application_id
      );
      setSelectedValue1(value);
    }
  }, [selectedApplications]);
  // useEffect(() => {
  //   try {
  //     const parsedData = JSON.parse(details.loan_description);
  //     if (parsedData != "") {
  //       setloanDescription(parsedData);
  //     }
  //   } catch (error) {
  //     setloanDescription("");
  //     console.error("Error parsing JSON:", error);
  //     // Handle the error or set a default value for loanDescription
  //   }
  // }, [reduxData, details]);
  useEffect(async () => {
    try {
      const rejectReason = await getrejectReason(
        reduxData.Loan_Application_id.toString()
      );
      const uniqueCategories = [
        ...new Set(rejectReason.data.data.map((item) => item.decline_category)),
      ];
      const formattedCategories = uniqueCategories.join(", ");
      setrejectReason(formattedCategories);
    } catch (error) {
      setrejectReason("");
      console.error("Error parsing JSON:", error);
      // Handle the error or set a default value for loanDescription
    }
  }, [reduxData]);
  const FraudCheckResults = ({ fraudResults }) => {
    return (
      <div>
        {fraudResults.map((result, index) => (
          <div key={index}>
            <h6>
              {result.hasOwnProperty("FRAUD_RULE_NAME") && (
                <b>Fraud Rule: {result.FRAUD_RULE_NAME}</b>
              )}
            </h6>
            {result.hasOwnProperty("FRAUD_CHECK_RESULT") && (
              <p>Result: {result.FRAUD_CHECK_RESULT}</p>
            )}
            {result.hasOwnProperty("FRAUD_CHECK_RESULT_REASON") && (
              <p>Reason: {result.FRAUD_CHECK_RESULT_REASON}</p>
            )}
            {result.hasOwnProperty("FRAUD_RULE_id") && (
              <p>Rule ID: {result.FRAUD_RULE_id}</p>
            )}
            {result.hasOwnProperty("FRAUD_CHECK_NAME_SIMILARITY") && (
              <p>Name Similarity: {result.FRAUD_CHECK_NAME_SIMILARITY}</p>
            )}
          </div>
        ))}
      </div>
    );
  };
  useEffect(() => {
    // Check if selectedApplications is defined and not empty
    if (selectedApplications && selectedApplications.length > 0) {
      setData(reduxData);
      // Check if selectedValue1 is within the valid range
      if (selectedValue1 >= 0 && selectedValue1 < selectedApplications.length) {
        setSelectedValue(selectedApplications[selectedValue1]);
      } else {
        setSelectedValue(null); // Invalid selectedValue1, reset to null
      }

      checkRoleAccess();
    }
  }, [reduxData, selectedValue1, selectedApplications]);

  // const selectedValue = selectedApplications.indexOf("banana");
  const checkRoleAccess = () => {
    if (roleAccess == "Maker") {
      dispatch(makerCheckAccess(true));
    } else {
      dispatch(makerCheckAccess(false));
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const viewDecision = async () => {
    setOpen1(true);
    // try {
    //   const breTree = await getBREDecision(reduxData.Loan_Application_id);
    //   if (breTree.data.data !== []) {
    //     const rows = JSON.parse(breTree.data.data[0].bre_result).data[0]
    //       .visited_nodes;
    //     setVisitedNodes(rows);
    //     setOpen1(true);
    //   } else {
    //     //if there is no data in the database
    //   }
    // } catch (error) {
    //   if (error.message === "AxiosError: Request failed with status code 401") {
    //     setIsErrorModalOpen(true);
    //     console.error(error);
    //   }
    // }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };
  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    navigate("/login");
  };
  const submitReason = (e) => {};
  const handleChange = async (e) => {
    setSelectedValue(e);
    try {
      const result = await getApplication(e.value);
      if (result.data.data.length > 0) {
        dispatch(selectedApplication(result.data.data[0]));
      } else {
        //if there is no data in the database
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  return (
    <ThemeProvider theme={responsiveFontSizes(theme)}>
      <div>
        {/* Your component content */}
        <SessionModal
          isOpen={isErrorModalOpen}
          onClose={handleCloseErrorModal}
        />
      </div>
      {details != undefined ? (
        <div className="details-box">
          <Grid
            item
            xs={4}
            lg={4}
            style={{ width: "30%", textAlign: "center" }}
          ></Grid>
          <Grid item xs={12} sx={{ pt: 4 }}>
            <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
              {selectedApplications != undefined ? (
                <Grid item xs={12} sm={3} md={3}>
                  <Typography
                    sx={{ mr: 4, flex: 1 }}
                    component="div"
                    style={{ fontWeight: "700", fontSize: "0.8rem" }}
                  >
                    Reference Number:
                  </Typography>
                  <Typography sx={{ mr: 4, flex: 1 }} component="div">
                    <Select
                      placeholder="Select Option"
                      value={selectedValue} // set selected value
                      options={selectedApplications} // set list of the data
                      onChange={handleChange} // assign onChange function
                    />{" "}
                  </Typography>
                </Grid>
              ) : null}
              <Grid item xs={12} sm={2} md={2}>
                <Typography
                  sx={{ mr: 4, flex: 1 }}
                  component="div"
                  style={{ fontWeight: "700", fontSize: "0.8rem" }}
                >
                  Loan amount Requested:
                </Typography>
                <Typography
                  sx={{ mr: 4, flex: 1 }}
                  component="div"
                  style={{ fontSize: "0.9rem" }}
                >
                  {details && details.loan_amount
                    ? details.loan_amount.toLocaleString()
                    : ""}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={3} md={3}>
                <Typography
                  sx={{ mr: 4, flex: 1 }}
                  component="div"
                  style={{ fontWeight: "700", fontSize: "0.8rem" }}
                >
                  Product Applied for:
                </Typography>
                <Typography
                  sx={{ mr: 4, flex: 1 }}
                  component="div"
                  style={{ fontSize: "0.9rem" }}
                >
                  {details.loan_product_name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <Typography
                  sx={{ mr: 4, flex: 1 }}
                  component="div"
                  style={{ fontWeight: "700", fontSize: "0.8rem" }}
                >
                  Decision Status:
                </Typography>
                <Typography
                  sx={{ mr: 4, flex: 1 }}
                  component="div"
                  style={{ fontSize: "0.9rem" }}
                >
                  {details.loan_status}
                </Typography>
              </Grid>
              {currentUser.role_name == "credit_head" ? (
                <Grid item xs={12} sm={2} md={2}>
                  <Typography
                    sx={{ mr: 4, flex: 1 }}
                    component="div"
                    style={{ fontWeight: "700", fontSize: "0.8rem" }}
                  >
                    Decision Reason:
                  </Typography>
                  <Typography
                    sx={{ mr: 4, flex: 1 }}
                    component="div"
                    style={{ fontSize: "0.9rem" }}
                  >
                    {rejectReason}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          {/* <Grid item xs={12} sx={{ pt: 4 }}>
            <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                style={{
                  width: "100%",
                }}
              >
                <BootstrapDialog
                  onClose={handleClose}
                  aria-labelledby="customized-dialog-title"
                  open={open}
                >
                  <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                  >
                    Reason for Decision
                    <Typography
                      sx={{ mr: 4, flex: 1 }}
                      component="div"
                      style={{ fontWeight: "800" }}
                    >
                      Please mention the reason for decision.
                    </Typography>
                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": { m: 10, width: "100%" },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        value={reason}
                        name="Reason"
                        style={{ marginTop: "6%" }}
                        onChange={(event) => handleReasonChange(event)}
                      />
                    </Box>
                  </BootstrapDialogTitle>
                  <DialogContent></DialogContent>
                  <DialogActions>
                    <Button
                      autoFocus
                      onClick={submitReason}
                      className="download-button"
                    >
                      Save changes
                    </Button>
                  </DialogActions>
                </BootstrapDialog>
              </Grid>
            </Grid>
          </Grid>
          <BootstrapDialog
            className="visitedNodes"
            onClose={handleClose1}
            aria-labelledby="customized-dialog-title"
            open={open1}
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              style={{
                background: "#3f48cc",
                color: "white",
                textAlign: "center",
                fontWeight: "700",
              }}
              onClose={handleClose1}
            >
              Results of BRE and Fraud Checks
            </BootstrapDialogTitle>
            <DialogContent>
              <Grid item xs={12} sx={{ pt: 4 }}>
                <Grid>
                  {loanDescription != undefined &&
                  loanDescription != null &&
                  loanDescription != "" ? (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div
                          style={{
                            fontSize: "1rem",
                            fontWeight: "bold",
                            marginBottom: "0.5rem",
                          }}
                        >
                          BRE Result: {loanDescription.bre_result}
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div
                          style={{
                            fontSize: "1rem",
                            fontWeight: "bold",
                            marginBottom: "0.5rem",
                          }}
                        >
                          Fraud Results:
                        </div>
                        <p>BRE Result: {loanDescription.bre_result}</p>
                        <FraudCheckResults
                          fraudResults={loanDescription.fraud_result}
                        />
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </DialogContent>
          </BootstrapDialog> */}
        </div>
      ) : null}
    </ThemeProvider>
  );
}
{
  /* <div style={{ width: "98%" }}>
                    <b>Loan Application Details</b>
                    <div className="table-container">
                      <DataGrid
                        rows={visitedNodes}
                        columns={columns}
                        getRowId={(row) => row.node_id}
                        // onCellClick={handleEvent}
                        disableSelectionOnClick={true} // disable selecting whole row
                        sx={{
                          "&.MuiDataGrid-root": {
                            border: "none ",
                          },
                          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                            outline: "none !important",
                          },
                          "&.MuiDataGrid-root .MuiDataGrid-columnHeader--sortable":
                            {
                              outline: "none !important",
                            },
                          "&.MuiDataGrid-root .MuiDataGrid-cell": {
                            whiteSpace: "normal !important",
                            wordWrap: "break-word !important",
                          },
                        }}
                      />
                    </div>
                  </div> */
}
