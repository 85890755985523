import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  verificationSideNav,
  secondaryListItemsDetails,
  secondaryListItems,
  disbursementSideNav,
} from "../Side_Nav/listItems";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TextField } from "@material-ui/core";
import LoanDetails from "../Common/basicLoanDetails";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import Footer from "./../Common/footer";
import AppHeader from "./../Common/header";
import {
  getDisbursementMemo,
  getCSVReports,
  getListofDocuments,
  getrepaymentSchedule,
} from "./../apiCalls/api";
import SessionModal from "./../Common/SessionModal";
import { useState } from "react";
import { useEffect } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import Tooltip from "@mui/material/Tooltip";
import Alert from "@mui/material/Alert";
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));
const columns = [
  {
    field: "id",
    headerName: "ID",
    flex: 1,
    maxWidth: 90,
  },
  {
    field: "Payment_Number",
    headerName: "Payment Number",
    flex: 1,
    minWidth: 110,
  },
  {
    field: "Payment_Date",
    headerName: "Payment Date",
    flex: 1,
    minWidth: 110,
  },
  {
    field: "EMI_Payment",
    headerName: "EMI Payment",
    flex: 1,
    minWidth: 110,
  },
  {
    field: "Principal",
    headerName: "Principal",
    flex: 1,
    minWidth: 110,
  },

  {
    field: "Interest",
    headerName: "Interest",
    flex: 1,
    minWidth: 110,
  },
  {
    field: "Closing_Principal_Balance",
    headerName: "Closing Principal Balance",
    flex: 1,
    maxWidth: 110,
  },
];
const mdTheme = createTheme();
function RepaymentSchedule() {
  const [value, setValue] = React.useState(0);
  const [open1, setOpen1] = React.useState(false);
  const [rows, setrows] = React.useState([]);
  const [tenure, setTenure] = React.useState(null);
  const [amount, setAmount] = React.useState(null);
  const currentUser = useSelector((state) => state.currentUserDetails);
  const reduxData = useSelector(
    (state) => state.applications?.loan_basic_info?.Loan_Application_id
  );
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  useEffect(() => {
    async function getData() {
      try {
        const result = await getrepaymentSchedule(reduxData);
        console.log("Results", result);
        setrows(result.data.emi_data);
        setTenure(result.data.tenure);
        setAmount(result.data.emi_amount);
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setIsErrorModalOpen(true);
          console.error(error);
        }
      }
    }
    getData();
  }, [reduxData]);
  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    navigate("/login");
  };
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <div>
        {/* Your component content */}
        <SessionModal
          isOpen={isErrorModalOpen}
          onClose={handleCloseErrorModal}
        />
      </div>
      <Box sx={{ display: "flex", mb: 6 }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <AppHeader
              open={open}
              toggleDrawer={toggleDrawer}
              title="Print Contract"
            />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {verificationSideNav}
            <Divider />
            {currentUser.role_name === "credit_head" ||
            currentUser.role_name === "credit_ops" ? (
              <>{secondaryListItemsDetails}</>
            ) : null}
          </List>
        </Drawer>
        <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
          <div style={{ marginTop: "1%" }}>
            <LoanDetails></LoanDetails>
          </div>
          <div style={{ marginTop: "2%" }}>
            {/* <LoanDetails></LoanDetails> */}
            {rows != null && rows.length > 0 ? (
              <Container maxWidth align="center">
                <div className="details-box">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      margin: "1%",
                    }}
                  >
                    <div style={{ width: "50%" }}>
                      <b>EMI Amount- </b>
                      {amount}
                    </div>{" "}
                    <div style={{ width: "50%" }}>
                      <b>Tenure- </b>
                      {tenure}
                    </div>
                  </div>
                  <Grid item xs={12}>
                    <div style={{ width: "98%" }}>
                      <b>Repayment Schedule</b>
                    </div>
                  </Grid>

                  <DataGrid
                    columns={columns}
                    rows={rows}
                    style={{ height: "52vh", width: "96%" }}
                  />
                </div>
              </Container>
            ) : (
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  m: 5,
                }}
              >
                <Alert severity="success">
                  Decision Not Yet Finalized. Please try Again.
                </Alert>
              </Grid>
            )}
          </div>
        </Box>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}

export default function repaymentSchedule() {
  return <RepaymentSchedule />;
}
