import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  verificationSideNav,
  secondaryListItems,
  disbursementSideNav,
} from "../Side_Nav/listItems";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TextField } from "@material-ui/core";
import LoanDetails from "../Common/basicLoanDetails";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import Footer from "./../Common/footer";
import AppHeader from "./../Common/header";
import {
  getDisbursementMemo,
  getCSVReports,
  getListofDocuments,
  getDownloadUrl
} from "./../apiCalls/api";
import SessionModal from "./../Common/SessionModal";
import { useState } from "react";
import { useEffect } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import Tooltip from "@mui/material/Tooltip";
const columns = [
  {
    field: "id",
    headerName: "S.No.",
    flex: 1,
    maxWidth: 50,
  },
  {
    field: "title",
    headerName: "Template",
    flex: 1,
    minWidth: 90,
  },
];
// const rows = [
//   {
//     id: 1,
//     title: "Loan Agreement",
//     status: true,
//     comments: "",
//   },
// ];
const downloadSingleFile = async (e) => {
  try {
    const result = await getDownloadUrl(
      e.comments
    );
    window.open(result.data.response_data.download_url, "_blank");
  }catch (error) {
    if (error.message) {
      console.error("Error:", error);
    }
  }  
};
const actionColumn = [
  {
    field: "action",
    headerName: "Download",
    width: 90,
    flex: 1,
    minWidth: 90,
    renderCell: (params) => {
      return (
        <div className="cellAction">
          <div className="viewButton">
            <Tooltip title="Download File" sx={{ mt: -1 }}>
              <IconButton
                onClick={(e) => downloadSingleFile(params.row)}
                style={{ color: "#3f48cc" }}
              >
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      );
    },
  },
];
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();
const handleChangeInput = (event) => {};
function Discussion() {
  const [value, setValue] = React.useState(0);
  const [open1, setOpen1] = React.useState(false);
  const [rows, setrows] = React.useState([]);
  const [downloadUrl, setDownloadUrl] = React.useState();
  const reduxData = useSelector(
    (state) => state.applications?.loan_basic_info?.Loan_Application_id
  );
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const handleClick = () => {
    setOpen1(!open1);
  };
  const downloadFile = async () => {
    try {
        for (const item of rows) {
            try {
                const result = await getDownloadUrl(item.comments);
                const downloadUrl = result.data.response_data.download_url;
                window.open(result.data.response_data.download_url, "_blank");
            } catch (error) {
                if (error.message) {
                  console.error("Error:", error);
                }
            }
        }
    } catch (error) {
        console.error("Error:", error);
    }
  };
  const printContract = async () => {
    try {
      // reduxData.toString();
      const result = await getDisbursementMemo(reduxData.toString());
      console.log(result.data.files);
      if (result.data) {
        if (result.data.files["Stamped Aggreement"]?.status === true) {
          window.open(result.data.files["Stamped Aggreement"].url, "_blank");
          return;
        }
        if (result.data.files["Signed Aggreement"]?.status === true) {
          window.open(result.data.files["Signed Aggreement"].url, "_blank");
          return;
        }
        if (result.data.files["Unsigned Aggreement"]?.status === true) {
          window.open(result.data.files["Unsigned Aggreement"].url, "_blank");
          return;
        }
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
    // window.open(result.data.file_url, "_blank");
  };
  useEffect(() => {
    async function getData() {
      try {
        let row = [];
        const result = await getListofDocuments(reduxData);
        if (result.data.response_data.application_form_url !== null) {
          row.push({
            id: 1,
            title: "Application Form",
            status: true,
            comments: result.data.response_data.application_form_url,
          });
        }
        if (result.data.response_data.esanction_url !== null) {
          row.push({
            id: 2,
            title: "Sanction Letter",
            status: true,
            comments: result.data.response_data.esanction_url,
          });
        }
        if (result.data.response_data.loan_agreement_url !== null) {
          row.push({
            id: 3,
            title: "Loan Agreement",
            status: true,
            comments: result.data.response_data.loan_agreement_url,
          });
        }
        setrows(row);
        if (result) {
          console.log(result);
        } else {
        }
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setIsErrorModalOpen(true);
          console.error(error);
        }
      }
    }
    getData();
  }, [reduxData]);
  const printReport = async () => {
    try {
      const result = await getCSVReports(reduxData);
      console.log(result.data);
      window.open(result.data.url);
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    navigate("/login");
  };
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <div>
        {/* Your component content */}
        <SessionModal
          isOpen={isErrorModalOpen}
          onClose={handleCloseErrorModal}
        />
      </div>
      <Box sx={{ display: "flex", mb: 6 }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <AppHeader
              open={open}
              toggleDrawer={toggleDrawer}
              title="Print Contract"
            />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {disbursementSideNav}
            <Divider sx={{ my: 1 }} />
            {/* {secondaryListItems} */}
          </List>
        </Drawer>
        <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
          <div style={{ marginTop: "1%" }}>
            <LoanDetails></LoanDetails>
          </div>
          <div style={{ marginTop: "2%" }}>
            {/* <LoanDetails></LoanDetails> */}
            <Container maxWidth align="center">
              <Grid item xs={12}>
                <div style={{ width: "98%" }}>
                  <b>Print Contract</b>
                </div>
              </Grid>
              <div className="details-box">
                <DataGrid
                  columns={columns.concat(actionColumn)}
                  rows={rows}
                  style={{ height: "40vh", width: "99%" }}
                />
              </div>
            </Container>
            <Grid
              item
              xs={12}
              md={2}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              style={{
                width: "100%",
              }}
            >
              <Button
                sx={{ textTransform: "none" }}
                variant="contained"
                className="download-button"
                onClick={printReport}
              >
                Print Report File
              </Button>
              <Button
                sx={{ textTransform: "none" }}
                variant="contained"
                className="download-button"
                onClick={downloadFile}
              >
                Download All Documents
              </Button>
            </Grid>
          </div>
        </Box>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}

export default function discussion() {
  return <Discussion />;
}
