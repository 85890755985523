// URL
//export const base_url = "https://ajit-backend-los-dev-eastus.azurewebsites.net";
// URL
export const base_url1 = "https://goshree-dla-prod-backend-ap-south-india.azurewebsites.net";
//export const base_url1 = "https://dla-backend-dev-south-india.azurewebsites.net";
//process.env.REACT_APP_BACKEND_URL;
//"https://dla-backend-uat-south-india.azurewebsites.net";
// "https://dla-backend-dev-south-india.azurewebsites.net";
//UAT
// https://ajit-backend-dla-uat-eastus.azurewebsites.net/
//DEV
//https://ajit-backend-dla-dev-eastus.azurewebsites.net
//Prod
//https://goshree-dla-prod-backend-ap-south-india.azurewebsites.net/
