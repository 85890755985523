import * as React from "react";
import Box from "@mui/material/Box";
import Axios from "axios";
import { base_url1 } from "../../constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
function Irregularities() {
  const reduxData = useSelector(
    (state) => state.applications?.loan_basic_info?.Loan_Application_id
  );
  const columns = [
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      maxWidth: 100,
    },
    {
      field: "particular",
      headerName: "Particular",
      flex: 1,
      minWidth: 400,
    },
    {
      field: "counterparty",
      headerName: "Counterparty",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "amount",
      headerName: "amount",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "balance",
      headerName: "Balance(Rs)",
      flex: 1,
      minWidth: 90,
    },
  ];
  const bankDetails = useSelector((state) => state.bankDetails);
  const [bankIrregularities, setIrregularities] = useState(null);
  useEffect(() => {
    async function getData() {
      getirregularities();
    }
    getData();
  }, [reduxData, bankDetails]);
  const getirregularities = () => {
    const data = bankDetails.filter(
      (item) => item.report_type == "irregularities"
    );
    const inputJson = JSON.parse(data[0].report_json.replace(/\\/g, ""));
    // Find the index of the object with key "files"
    const filesIndex = inputJson.findIndex((item) =>
      item.hasOwnProperty("files")
    );

    // Remove the object at the filesIndex
    if (filesIndex !== -1) {
      inputJson.splice(filesIndex, 1);
    }
    // const outputArray = inputJson.map((item) => {
    //   if (item.transactions != undefined) {
    //     return {
    //       activity: item.activity,
    //       count: item.transactions.length,
    //     };
    //   } else {
    //     return {
    //       activity: item.activity,
    //       count: item.monthlyTransactions.length,
    //     };
    //   }
    // });
    // const modifiedData = inputJson.map((item, index) => {
    //   if (item.transactions) {
    //     return {
    //       ...item,
    //       id: index,
    //       transactions: item.transactions,
    //       monthlyTransactions: undefined,
    //     };
    //   }
    //   if (item.monthlyTransactions) {
    //     return {
    //       ...item,
    //       id: index,
    //       transactions: item.monthlyTransactions,
    //       monthlyTransactions: undefined,
    //     };
    //   }
    //   return item;
    // });

    setIrregularities(inputJson);
  };
  return (
    <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
      <div className="accordion" id="accordionExample">
        {bankIrregularities != null ? (
          <>
            {bankIrregularities.map((transaction, index) => {
              // Generate unique IDs for each accordion item
              const headerId = `accordionHeader-${index}`;
              const collapseId = `accordionCollapse-${index}`;

              return (
                <div className="accordion-item" key={index}>
                  <h2 className="accordion-header" id={headerId}>
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#${collapseId}`}
                      aria-expanded="false"
                      aria-controls={collapseId}
                    >
                      {transaction.activity}
                    </button>
                  </h2>
                  <div
                    id={collapseId}
                    className="accordion-collapse collapse"
                    aria-labelledby={headerId}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {transaction.transactions != undefined ? (
                        <DataGrid
                          style={{
                            height: "36vh",
                          }}
                          rows={transaction.transactions}
                          columns={columns}
                          density="compact"
                          sx={{
                            "&.MuiDataGrid-root": {
                              border: "none ",
                            },
                            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within":
                              {
                                outline: "none !important",
                              },
                            "&.MuiDataGrid-root .MuiDataGrid-columnHeader--sortable":
                              {
                                outline: "none !important",
                              },
                            "&.MuiDataGrid-root .MuiDataGrid-cell": {
                              whiteSpace: "normal !important",
                              wordWrap: "break-word !important",
                            },
                          }}
                        />
                      ) : null}
                      {transaction.monthlyTransactions != undefined ? (
                        <DataGrid
                          style={{
                            height: "36vh",
                          }}
                          rows={transaction.monthlyTransactions.map(
                            (row, index) => ({
                              ...row,
                              id: index, // Add a unique id property based on the index
                            })
                          )}
                          columns={columns}
                          density="compact"
                          sx={{
                            "&.MuiDataGrid-root": {
                              border: "none ",
                            },
                            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within":
                              {
                                outline: "none !important",
                              },
                            "&.MuiDataGrid-root .MuiDataGrid-columnHeader--sortable":
                              {
                                outline: "none !important",
                              },
                            "&.MuiDataGrid-root .MuiDataGrid-cell": {
                              whiteSpace: "normal !important",
                              wordWrap: "break-word !important",
                            },
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : null}
        {/* {bankIrregularities != null ? (
          <>
            {bankIrregularities.map((transaction, index) => (
              <div className="accordion-item">
                <h2 className="accordion-header" id={index.toString()}>
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    {transaction.activity}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    {bankIrregularities != null &&
                    transaction.transactions != undefined ? (
                      <DataGrid
                        style={{
                          height: "36vh",
                        }}
                        rows={transaction.transactions}
                        columns={columns}
                        density="compact"
                        sx={{
                          "&.MuiDataGrid-root": {
                            border: "none ",
                          },
                          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                            outline: "none !important",
                          },
                          "&.MuiDataGrid-root .MuiDataGrid-columnHeader--sortable":
                            {
                              outline: "none !important",
                            },
                          "&.MuiDataGrid-root .MuiDataGrid-cell": {
                            whiteSpace: "normal !important",
                            wordWrap: "break-word !important",
                          },
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : null} */}
      </div>
    </Box>
  );
}

export default function irregularities() {
  return <Irregularities />;
}
