import * as React from "react";
import Box from "@mui/material/Box";
import Axios from "axios";
import { base_url1 } from "../../constants";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
function Summary() {
  const [BankSummary, setBankSummary] = React.useState(null);
  const reduxData = useSelector(
    (state) => state.applications?.loan_basic_info?.Loan_Application_id
  );
  const columnsATM = [
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "bank",
      headerName: "Bank",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 90,
    },
  ];
  const columnsAverageMonthlyBalance = [
    {
      field: "monthAndYear",
      headerName: "Month and Year",
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "netAverageBalance",
      headerName: "Net Average Balance",
      flex: 1,
      minWidth: 90,
    },
  ];
  const columnsAverageQuartlyBalance = [
    {
      field: "monthAndYear",
      headerName: "Month and Year",
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "netAverageBalance",
      headerName: "Net Average Balance",
      flex: 1,
      minWidth: 90,
    },
  ];
  const expenses = [
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "merchantType",
      headerName: "Merchant Type",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "purpose",
      headerName: "Purpose",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "partyName",
      headerName: "Party Name",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "mode",
      headerName: "Mode",
      flex: 1,
      minWidth: 90,
    },
  ];
  const money_received_transactions = [
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "transactionType",
      headerName: "Transaction Type",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "balanceAfterTransaction",
      headerName: "Balance After Transaction",
      flex: 1,
      minWidth: 90,
    },
  ];
  const bankDetails = useSelector((state) => state.bankDetails);
  useEffect(() => {
    async function getData() {
      const BankOverviewDetails = bankDetails.filter(
        (item) => item.report_type == "summary"
      );
      const Data1 = JSON.parse(
        BankOverviewDetails[0].report_result_json.replace(/\\/g, "")
      );
      //   setBankTransactions(Data1);
      if (!Data1.Error) {
        setBankSummary(Data1);
      }
    }
    getData();
  }, [reduxData, bankDetails]);
  return (
    <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
      {BankSummary != null && BankSummary != undefined ? (
        <div className="accordion" id="accordionExample">
          <div className="accordion-item" key="1">
            <h2 className="accordion-header" id="accordionHeader-1">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionCollapse-1"
                aria-expanded="false"
                aria-controls="accordionCollapse-1"
              >
                ATM Withdrawls
              </button>
            </h2>
            <div
              id="accordionCollapse-1"
              className="accordion-collapse collapse"
              aria-labelledby="accordionHeader-1"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <DataGrid
                  style={{
                    height: "36vh",
                  }}
                  rows={BankSummary.atm_withdrawls}
                  columns={columnsATM}
                  getRowId={(row) => row.date}
                  density="compact"
                  sx={{
                    "&.MuiDataGrid-root": {
                      border: "none ",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                      outline: "none !important",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
                      outline: "none !important",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell": {
                      whiteSpace: "normal !important",
                      wordWrap: "break-word !important",
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="accordion-item" key="2">
            <h2 className="accordion-header" id="accordionHeader-2">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionCollapse-2"
                aria-expanded="false"
                aria-controls="accordionCollapse-2"
              >
                Average Monthly Balance
              </button>
            </h2>
            <div
              id="accordionCollapse-2"
              className="accordion-collapse collapse"
              aria-labelledby="accordionHeader-2"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <DataGrid
                  style={{
                    height: "36vh",
                  }}
                  rows={BankSummary.averageMonthlyBalance}
                  columns={columnsAverageMonthlyBalance}
                  getRowId={(row) => row.netAverageBalance}
                  density="compact"
                  sx={{
                    "&.MuiDataGrid-root": {
                      border: "none ",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                      outline: "none !important",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
                      outline: "none !important",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell": {
                      whiteSpace: "normal !important",
                      wordWrap: "break-word !important",
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="accordion-item" key="3">
            <h2 className="accordion-header" id="accordionHeader-3">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionCollapse-3"
                aria-expanded="false"
                aria-controls="accordionCollapse-3"
              >
                Average Quarterly Balance
              </button>
            </h2>
            <div
              id="accordionCollapse-3"
              className="accordion-collapse collapse"
              aria-labelledby="accordionHeader-3"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <DataGrid
                  style={{
                    height: "36vh",
                  }}
                  rows={BankSummary.averageQuarterlyBalance}
                  columns={columnsAverageQuartlyBalance}
                  getRowId={(row) => row.netAverageBalance}
                  density="compact"
                  sx={{
                    "&.MuiDataGrid-root": {
                      border: "none ",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                      outline: "none !important",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
                      outline: "none !important",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell": {
                      whiteSpace: "normal !important",
                      wordWrap: "break-word !important",
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="accordion-item" key="4">
            <h2 className="accordion-header" id="accordionHeader-4">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionCollapse-4"
                aria-expanded="false"
                aria-controls="accordionCollapse-4"
              >
                Expenses
              </button>
            </h2>
            <div
              id="accordionCollapse-4"
              className="accordion-collapse collapse"
              aria-labelledby="accordionHeader-4"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <DataGrid
                  style={{
                    height: "36vh",
                  }}
                  rows={BankSummary.expenses}
                  columns={expenses}
                  getRowId={(row) => row.amount}
                  density="compact"
                  sx={{
                    "&.MuiDataGrid-root": {
                      border: "none ",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                      outline: "none !important",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
                      outline: "none !important",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell": {
                      whiteSpace: "normal !important",
                      wordWrap: "break-word !important",
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="accordion-item" key="5">
            <h2 className="accordion-header" id="accordionHeader-5">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionCollapse-5"
                aria-expanded="false"
                aria-controls="accordionCollapse-5"
              >
                Income
              </button>
            </h2>
            <div
              id="accordionCollapse-5"
              className="accordion-collapse collapse"
              aria-labelledby="accordionHeader-5"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <DataGrid
                  style={{
                    height: "36vh",
                  }}
                  rows={BankSummary.incomes}
                  columns={expenses}
                  getRowId={(row) => row.amount}
                  density="compact"
                  sx={{
                    "&.MuiDataGrid-root": {
                      border: "none ",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                      outline: "none !important",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
                      outline: "none !important",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell": {
                      whiteSpace: "normal !important",
                      wordWrap: "break-word !important",
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="accordion-item" key="6">
            <h2 className="accordion-header" id="accordionHeader-6">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionCollapse-6"
                aria-expanded="false"
                aria-controls="accordionCollapse-6"
              >
                Money Received Transactions
              </button>
            </h2>
            <div
              id="accordionCollapse-6"
              className="accordion-collapse collapse"
              aria-labelledby="accordionHeader-6"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <DataGrid
                  style={{
                    height: "36vh",
                  }}
                  rows={BankSummary.money_received_transactions}
                  columns={money_received_transactions}
                  getRowId={(row) => row.amount}
                  density="compact"
                  sx={{
                    "&.MuiDataGrid-root": {
                      border: "none ",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                      outline: "none !important",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
                      outline: "none !important",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell": {
                      whiteSpace: "normal !important",
                      wordWrap: "break-word !important",
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </Box>
  );
}

export default function summary() {
  return <Summary />;
}
