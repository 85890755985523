//import storage from "redux-persist/lib/storage"; // You can use session storage instead if desired
import sessionStorage from "redux-persist/lib/storage/session";
import { persistReducer } from "redux-persist";

const persistConfig = {
  key: "root", // Key for the root of the Redux store
  storage: sessionStorage, // Storage method (e.g., local storage or session storage)
};

export default persistConfig;
