import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import {
  Container,
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
  TextField,
  Divider,
  Link,
} from "@material-ui/core";
import Alert from "@mui/material/Alert";
import "./../styles.css";
import Typography from "@mui/material/Typography";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import { base_url1 } from "../../constants";
import List from "@mui/material/List";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Button, ListItem, ListItemText } from "@mui/material";
import {
  GridRenderCellParams,
  GridColDef,
  useGridApiContext,
} from "@mui/x-data-grid";
import {
  mainListItems2,
  secondaryListItems,
  externalMenu,
} from "../Side_Nav/listItems";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TaskIcon from "@mui/icons-material/Task";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoanApplications,
  selectedApplication,
  selectedApplicationdropdown,
} from "../../redux/actions/LoanActions";
import LogoutIcon from "@mui/icons-material/Logout";
import LinearProgress from "@mui/material/LinearProgress";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import SessionModal from "../Common/SessionModal";
import {
  getEligibileCustomer,
  getOffersSent,
  sendBulkOffers,
} from "../apiCalls/api";
import AppHeader from "../Common/header";
import Footer from "../Common/footer";
import SendIcon from "@mui/icons-material/Send";
import { notificationServices } from "../Side_Nav/listItems";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRigthButton from "@mui/icons-material/ArrowCircleRight";
import { TroubleshootSharp } from "@mui/icons-material";

export const theme = createTheme({
  typography: {
    // fontFamily: "Times New Roman",
  },
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TopUpOffer() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const mdTheme = createTheme();
  const [errorMessage, seterrorMessage] = React.useState("");
  const [isAPILoading, setisAPILoading] = React.useState(false);
  const [templateDescription, setTemplateDescription] = useState(
    "Hi Customer,\n\nWelcome to Gosree Finance Limited. Humanising the experience of Finance! \n\nYou are pre-selected for a topup loan with Gosree Finance Limited. \n\nPlease click on the url below to apply:{{apply_url}}"
  );
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [rows, setrows] = useState([]);
  const [rows1, setrows1] = useState([]);
  const currentUser = useSelector((state) => state.currentUserDetails);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    navigate("/login");
  };
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectionModelChange = (selection) => {
    setSelectedRows(selection.selectionModel);
  };
  const pushCheckedItemsToArray = async () => {
    const checkedItems = selectedRows.map((id) =>
      rows.find((row) => row.id === id)
    );
    console.log(checkedItems);
    const updatedRows = rows.map((row) => {
      if (checkedItems.some((item) => item.id === row.id)) {
        return { ...row, offerSent: true };
      }
      return row;
    });

    console.log(updatedRows);
    setrows(updatedRows);
    const panList = checkedItems.map((row) => ({ pan: row.customer_pan }));
    setisAPILoading(true);
    try {
      const result = await sendBulkOffers(panList);
      if (result) {
        setisAPILoading(false);
      } else {
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setisAPILoading(false);
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
    // Do whatever you need with the checked items array
  };
  const handleSelectionChange = (newSelection) => {
    setSelectedRows(newSelection);
  };
  useEffect(() => {
    async function getData() {
      setisAPILoading(true);
      try {
        const result = await getEligibileCustomer(currentPage);
        if (result.data.response_data?.topup_eligible_offer_list?.length != 0) {
          const rowsWithId =
            result.data.response_data.topup_eligible_offer_list.map(
              (item, index) => ({
                ...item,
                id: index,
                offerSent: false,
              })
            );
          setisAPILoading(false);
          setrows(rowsWithId);
          setTotalPages(result.data.response_data.total_pages);
        } else {
        }
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setisAPILoading(false);
          setIsErrorModalOpen(true);
          console.error(error);
        }
      }
      try {
        const result1 = await getOffersSent();
        if (result1.data.response_data?.topup_offers_sent_list.length != 0) {
          setisAPILoading(false);
          setrows1(result1.data.response_data.topup_offers_sent_list);
        } else {
        }
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setisAPILoading(false);
          setIsErrorModalOpen(true);
          console.error(error);
        }
      }
    }
    getData();
  }, []);
  const sendOffer = async (row) => {
    // Add logic to send offer...

    // Update rows state to mark the offer as sent
    setrows((prevRows) =>
      prevRows.map((prevRow) =>
        prevRow.id === row.id ? { ...prevRow, offerSent: true } : prevRow
      )
    );
    const panList = [{ pan: row.customer_pan }];
    setisAPILoading(true);
    try {
      const result = await sendBulkOffers(panList);
      if (result) {
        setisAPILoading(false);
      } else {
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setisAPILoading(false);
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const handleOnClickPageChange = async (event, action) => {
    if (currentPage > 1 && action === "previous") {
      setCurrentPage(currentPage - 1);
    }
    if (currentPage < totalPages && action === "next") {
      setCurrentPage(currentPage + 1);
    }
    setisAPILoading(true);
    try {
      const result = await getEligibileCustomer(currentPage + 1);
      if (result.data.response_data?.topup_eligible_offer_list?.length != 0) {
        const rowsWithId =
          result.data.response_data.topup_eligible_offer_list.map(
            (item, index) => ({
              ...item,
              id: index,
              offerSent: false,
            })
          );
        setisAPILoading(false);
        setrows(rowsWithId);
        setTotalPages(result.data.response_data.total_pages);
      } else {
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setisAPILoading(false);
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const columns = [
    { field: "customer_branch", headerName: "Customer Branch", width: 150 },
    { field: "customer_name", headerName: "Customer Name", width: 150 },
    { field: "customer_pan", headerName: "Customer Pan", width: 120 },
    { field: "customer_phone", headerName: "Customer Phone", width: 100 },
    {
      field: "send",
      headerName: "Send Offer",
      width: 50,
      renderCell: (params) => (
        <IconButton
          onClick={() => sendOffer(params.row)}
          style={{ color: params.row.offerSent ? "#A9A9A9" : "#1976d2" }}
          disabled={params.row.offerSent}
        >
          <SendIcon />
        </IconButton>
      ),
    },
  ];
  const columns1 = [
    { field: "branch", headerName: "Branch", width: 150 },
    { field: "customer_name", headerName: "Customer Name", width: 150 },
    { field: "pan", headerName: "Customer Pan", width: 120 },
    { field: "phone_no", headerName: "Customer Phone", width: 100 },
    {
      field: "inserted_datetime",
      headerName: "Date",
      width: 150,
    },
  ];
  return (
    <ThemeProvider theme={mdTheme}>
      <div>
        {/* Your component content */}
        <SessionModal
          isOpen={isErrorModalOpen}
          onClose={handleCloseErrorModal}
        />
      </div>
      <Box sx={{ display: "flex", mb: 6 }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <AppHeader
              open={open}
              toggleDrawer={toggleDrawer}
              title="Notification Services"
            />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {notificationServices}
            <Divider sx={{ my: 1 }} />
            {/* {secondaryListItems} */}
          </List>
        </Drawer>

        <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
          {isAPILoading ? (
            <Grid item xs={12}>
              <LinearProgress
                sx={{
                  color: "#3f48cc",
                }}
                size={40}
                thickness={4}
              />
            </Grid>
          ) : null}
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Send Offer"
                {...a11yProps(0)}
                style={{ textTransform: "capitalize" }}
              />
              <Tab
                label="Already Sent Offers"
                {...a11yProps(1)}
                style={{ textTransform: "capitalize" }}
              />
            </Tabs>
          </Box>
          <div style={{ marginTop: "1%" }}>
            <TabPanel value={value} index={0}>
              <Container maxWidth align="center">
                <Grid item xs={12} sx={{ pt: 4 }}>
                  <Grid>
                    {errorMessage ? (
                      <Alert severity="error">
                        Error sending whatsapp message.
                      </Alert>
                    ) : null}
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <Grid container spacing={2}></Grid>
                        <div style={{ marginTop: "2%" }}>
                          <div style={{ height: "52vh", width: "100%" }}>
                            <DataGrid
                              rows={rows}
                              columns={columns}
                              pageSize={10}
                              checkboxSelection
                              onRowSelectionModelChange={(ids) =>
                                handleSelectionChange(ids)
                              }
                              selectionModel={selectedRows}
                              hideFooterPagination={true}
                              style={{ height: "52vh" }}
                              sx={{
                                "&.MuiDataGrid-root": {
                                  whiteSpace: "normal !important",
                                  wordWrap: "break-word !important",
                                },
                                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within":
                                  {
                                    outline: "none !important",
                                  },
                                // "&.MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
                                //   outline: "none !important",
                                // },
                                "& .MuiDataGrid-cell": {
                                  whiteSpace: "normal !important",
                                  wordWrap: "break-word !important",
                                  "&:first-of-type": {
                                    borderRight: "1px solid gray",
                                  },
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                  borderBottom: "1px solid gray",
                                },
                              }}
                            />
                          </div>
                        </div>
                        <Grid container justifyContent="flex-end">
                          <Grid item xs={2} sm={3} md={4}>
                            <IconButton
                              color="primary"
                              onClick={(event) => {
                                handleOnClickPageChange(event, "previous");
                              }}
                            >
                              <ArrowCircleLeftIcon />
                            </IconButton>
                            Page {currentPage} of {totalPages}
                            <IconButton
                              color="primary"
                              onClick={(event) => {
                                handleOnClickPageChange(event, "next");
                              }}
                            >
                              <ArrowCircleRigthButton />
                            </IconButton>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sx={{ m: 4 }}
                          style={{ alignContent: "right" }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={pushCheckedItemsToArray}
                            fullWidth
                          >
                            Send Offer
                          </Button>
                        </Grid>
                      </Grid>

                      <Grid item xs={3} sx={{ m: 5 }}>
                        <Typography variant="h6">Template</Typography>
                        <TextField
                          label="Template Description"
                          value={templateDescription}
                          variant="outlined"
                          fullWidth
                          style={{ margin: "2%" }}
                          multiline
                          rows={12}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </TabPanel>

            <TabPanel value={value} index={1}>
              <Grid sx={{ m: 5 }}>
                <div style={{ marginTop: "2%" }}>
                  <div style={{ height: "60vh", width: "100%" }}>
                    <DataGrid
                      rows={rows1}
                      columns={columns1}
                      pageSize={5}
                      sx={{
                        "&.MuiDataGrid-root": {
                          whiteSpace: "normal !important",
                          wordWrap: "break-word !important",
                        },
                        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                          outline: "none !important",
                        },
                        // "&.MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
                        //   outline: "none !important",
                        // },
                        "& .MuiDataGrid-cell": {
                          whiteSpace: "normal !important",
                          wordWrap: "break-word !important",
                          "&:first-of-type": {
                            borderRight: "1px solid gray",
                          },
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          borderBottom: "1px solid gray",
                        },
                      }}
                    />
                  </div>
                </div>
              </Grid>
            </TabPanel>
          </div>
        </Box>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}
