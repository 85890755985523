import * as React from "react";
import Box from "@mui/material/Box";
import Axios from "axios";
import { base_url1 } from "../../constants";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
function Transactions() {
  const [BankTransactions, setBankTransactions] = React.useState([]);
  const reduxData = useSelector(
    (state) => state.applications?.loan_basic_info?.Loan_Application_id
  );
  const columns = [
    {
      field: "dateTime",
      headerName: "Date",
      flex: 1,
      maxWidth: 90,
    },
    {
      field: "bank",
      headerName: "Bank",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "balanceAfterTransaction",
      headerName: "Balance After Transaction",
      flex: 1,
      minWidth: 90,
    },
  ];
  const bankDetails = useSelector((state) => state.bankDetails);
  useEffect(() => {
    async function getData() {
      const BankTransactionDetails = bankDetails.filter(
        (item) => item.report_type == "transactions"
      );
      const Data1 = JSON.parse(
        BankTransactionDetails[0].report_result_json.replace(/\\/g, "")
      );
      // const Deposits = Data1.dataDeposits;
      // const Withdrawals = Data1.dataWithdrawals;
      // const transactions1 = Deposits.Deposits.data.flatMap(
      //   (item) => item.transactions
      // );
      // const transactions2 = Withdrawals.Withdrawals.data.flatMap(
      //   (item) => item.transactions
      // );
      // const mergeArray = [...transactions1, ...transactions2];
      setBankTransactions(Data1);
    }
    getData();
  }, [reduxData, bankDetails]);
  return (
    <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
      <div>
        <div className="details-box">
          <DataGrid
            rows={BankTransactions}
            getRowId={(row) => row.amount}
            columns={columns}
            slots={{ toolbar: GridToolbar }}
            density="comfortable"
            sx={{
              "&.MuiDataGrid-root": {
                border: "none ",
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "&.MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
                outline: "none !important",
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell": {
                whiteSpace: "normal !important",
                wordWrap: "break-word !important",
              },
            }}
          />
        </div>
      </div>
    </Box>
  );
}

export default function transactions() {
  return <Transactions />;
}
