import * as React from "react";
import Box from "@mui/material/Box";
import Axios from "axios";
import { base_url1 } from "../../constants";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
function DailyBalance() {
  const [DailyBankBalance, setDaily] = React.useState([]);
  const reduxData = useSelector(
    (state) => state.applications?.loan_basic_info?.Loan_Application_id
  );
  const columns = [
    { field: "day", headerName: "Day of Month", width: 100 },
    { field: "May 2022", headerName: "May 2022", width: 150 },
    { field: "Jun 2022", headerName: "Jun 2022", width: 150 },
    { field: "Jul 2022", headerName: "Jul 2022", width: 150 },
    { field: "Aug 2022", headerName: "Aug 2022", width: 150 },
    { field: "Sep 2022", headerName: "Sep 2022", width: 150 },
    { field: "Oct 2022", headerName: "Oct 2022", width: 150 },
    { field: "Average", headerName: "Average", width: 150 },
  ];
  const bankDetails = useSelector((state) => state.bankDetails);
  useEffect(() => {
    async function getData() {
      const BankDailyBalanceDetails = bankDetails.filter(
        (item) => item.report_type == "dailyBalance"
      );

      const Data1 = JSON.parse(
        BankDailyBalanceDetails[0].report_json.replace(/\\/g, "")
      );

      const test_json = [];
      const days_dict = Data1.month;
      const days_array = Object.values(days_dict);
      for (var i = 1; i <= 31; i++) {
        const current_json = {};
        current_json["day"] = i;
        for (var j = 1; j <= days_array.length; j++) {
          current_json[Data1.day[i][j].month] = Data1.day[i][j].amount;
        }
        test_json[i] = current_json;
      }
      test_json.splice(0, 1);
      setDaily(test_json);
    }
    getData();
  }, [reduxData]);
  return (
    <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
      <div>
        {DailyBankBalance != null ? (
          <div className="details-box">
            <DataGrid
              rows={DailyBankBalance}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              getRowId={(row) => row.day}
              density="comfortable"
              sx={{
                "&.MuiDataGrid-root": {
                  border: "none ",
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
                "&.MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
                  outline: "none !important",
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell": {
                  whiteSpace: "normal !important",
                  wordWrap: "break-word !important",
                },
              }}
            />
          </div>
        ) : null}
      </div>
    </Box>
  );
}

export default function dailyBalance() {
  return <DailyBalance />;
}
