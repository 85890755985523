import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector } from "react-redux";
import {
  mainListItems,
  secondaryListItems,
  mainListItemsIndividual,
} from "../Side_Nav/listItems";
import Button from "@mui/material/Button";
import LoanDetails from "../Common/basicLoanDetails";
import { TextField } from "@material-ui/core";
import AppHeader from "./../Common/header";
import Footer from "./../Common/footer";
import {
  getCibilResponse,
  getCibil,
  updateCreditInfo,
  getBREFraudResults,
} from "./../apiCalls/api";
import { useState } from "react";
import SessionModal from "./../Common/SessionModal";
import { useEffect } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { Card, CardContent } from "@mui/material";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

function DashboardContent() {
  const [value, setValue] = React.useState(0);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [companyData, setcompanyData] = useState({});
  const [individualData, setindividualData] = useState({});
  const [isInfoLoaded, setisInfoLoaded] = useState(false);
  const [creditInformation, setcreditInformation] = useState({});
  const [isAPILoading, setisAPILoading] = React.useState(false);
   const [bre3Results, setbre3Results] = React.useState([]);
  const [bre2Results, setbre2Results] = React.useState([]);
  const [bre1Results, setbre1Results] = React.useState([]);
  const [fraudResults, setfraudResults] = React.useState([]);
  const currentUser = useSelector((state) => state.currentUserDetails);
  const loanType = useSelector(
    (state) => state.applications?.loan_basic_info?.loan_type
  );
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   setCreditInformation((prevCreditInfo) => ({
  //     ...prevCreditInfo,
  //     [name]: value,
  //   }));
  // };
  const handleCreditInformation = (e) => {
    setcreditInformation({
      ...creditInformation,
      [e.target.name]: e.target.value,
    });
  };
  const updateCreditDetails = async () => {
    setisAPILoading(true);
    const newCreditInformation = { ...creditInformation, loan_id: reduxData };
    console.log(newCreditInformation);
    try {
      const result = await updateCreditInfo(newCreditInformation);
      setisAPILoading(false);
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsErrorModalOpen(true);
        setisAPILoading(false);
        console.error(error);
      }
    }
  };
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    navigate("/login");
  };

  // Helper function to parse JSON strings in the results
  const parseResult = (result) => {
    try {
      return JSON.parse(result);
    } catch (error) {
      return null;
    }
  };
  function numberWithCommas(x) {
    if (!x) return ""; // Handle null or undefined values
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const reduxData = useSelector(
    (state) => state.applications?.loan_basic_info?.Loan_Application_id
  );
  const flattenData = (data) => {
    let flattenedData = {};
    const flattenObject = (obj, prefix = "") => {
      for (let key in obj) {
        if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
          flattenObject(obj[key], prefix + key + "_");
        } else {
          flattenedData[prefix + key] = obj[key];
        }
      }
    };
    flattenObject(data);
    return flattenedData;
  };
  const getCibilData = async () => {
    try {
      const result = await getCibil(reduxData);
      if (result.data) {
        setisAPILoading(false);
        console.log("My Data is ", result.data.data);
        setcreditInformation(result.data.data);
        setisInfoLoaded(true);
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsErrorModalOpen(true);
        console.error(error);
        setisAPILoading(false);
      }
    }
  };
  const getBREFraud = async () => {
    try {
      const result = await getBREFraudResults(reduxData);
      if (result.data != undefined) {
        console.log("BRE results", result.data);
        setbre1Results(result.data.bre1_results);
        setbre2Results(result.data.bre2_results);
        setbre3Results(result.data.bre3_results);
        setfraudResults(result.data.fraud_results);
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsErrorModalOpen(true);
        console.error(error);
        setisAPILoading(false);
      }
    }
  };
  useEffect(() => {
    async function getData() {
      setisAPILoading(true);
      getCibilData();
      getBREFraud();
    }
    getData();
  }, [reduxData]);

  return (
    <ThemeProvider theme={mdTheme}>
      <div>
        {/* Your component content */}
        <SessionModal
          isOpen={isErrorModalOpen}
          onClose={handleCloseErrorModal}
        />
      </div>
      <Box sx={{ display: "flex", mb: 6 }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <AppHeader
              open={open}
              toggleDrawer={toggleDrawer}
              title="Credit Information"
            />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          {loanType == "Individual" ? (
            <List component="nav">
              {mainListItemsIndividual}
              <Divider />
              {currentUser.role_name === "credit_head" ||
              currentUser.role_name === "credit_ops" ? (
                <>{secondaryListItems}</>
              ) : null}
            </List>
          ) : (
            <List component="nav">
              {mainListItems}
              <Divider />
              {currentUser.role_name === "credit_head" ||
              currentUser.role_name === "credit_ops" ? (
                <>{secondaryListItems}</>
              ) : null}
            </List>
          )}
        </Drawer>
        <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
          {isAPILoading ? (
            <Grid item xs={12}>
              <LinearProgress
                sx={{
                  color: "#3f48cc",
                }}
                size={40}
                thickness={4}
              />
            </Grid>
          ) : null}
          {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Credit Bureau Data"
                {...a11yProps(0)}
                style={{ textTransform: "capitalize" }}
              />
              <Tab
                label="Individual's Credit Bureau Data"
                {...a11yProps(1)}
                style={{ textTransform: "capitalize" }}
              />
            </Tabs>
          </Box> */}
          <div style={{ marginTop: "1%" }}>
            <LoanDetails></LoanDetails>
            {isInfoLoaded ? (
              <Grid item xs={12}>
                {currentUser.role_name != "branch_manager" &&
                currentUser.role_name != "external_agency" &&
                currentUser.role_name != "lawyer" ? (
                  <>
                    <Grid container sx={{ mt: 2 }}>
                      <Grid item xs={12} sm={6} md={6}>
                        <div className="details-box">
                          <Grid item xs={12} sx={{ pt: 4, m: 2 }}>
                            <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                              <Grid item xs={12} sm={12} md={12}>
                                <Typography
                                  sx={{
                                    m: 4,
                                    flex: 1,
                                    pt: 2,
                                    pb: 2,
                                    display: "flex", // Make the container a flex container
                                    alignItems: "center", // Center vertically
                                    justifyContent: "center", // Center horizontally
                                    fontWeight: 800,
                                    color: "#3f48cc",
                                  }}
                                  component="div"
                                >
                                  Score
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <Tooltip
                                  title={"CIBIL Score - Credit Vision"}
                                  arrow
                                  placement="top"
                                >
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name="CIBIL_SCORE_CreditVision_1"
                                    label="CIBIL Score - Credit Vision"
                                    value={
                                      creditInformation.CIBIL_SCORE_CreditVision_1
                                    }
                                    onChange={(e) => handleCreditInformation(e)}
                                    InputLabelProps={{
                                      style: {
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        lineHeight: "1.2",
                                        cursor: "pointer",
                                        width: "100%",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <Tooltip
                                  title={"CIBIL Score - Personal Loan"}
                                  arrow
                                  placement="top"
                                >
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name="CIBIL_SCORE_PersonalLoan_1"
                                    label="CIBIL Score - Personal Loan"
                                    value={
                                      creditInformation.CIBIL_SCORE_PersonalLoan_1
                                    }
                                    onChange={(e) => handleCreditInformation(e)}
                                    InputLabelProps={{
                                      style: {
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        lineHeight: "1.2",
                                        cursor: "pointer",
                                        width: "100%",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} mt={3}>
                                <Tooltip
                                  title={"Internal Score - Credit"}
                                  arrow
                                  placement="top"
                                >
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name="Credit_score_onus_2"
                                    label="Internal Score - Credit"
                                    value={
                                      creditInformation.Credit_score_onus_2
                                    }
                                    onChange={(e) => handleCreditInformation(e)}
                                    InputLabelProps={{
                                      style: {
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        lineHeight: "1.2",
                                        cursor: "pointer",
                                        width: "100%",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <div className="details-box">
                          <Grid item xs={12} sx={{ pt: 4, m: 2 }}>
                            <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                              <Grid item xs={12} sm={12} md={12}>
                                <Typography
                                  sx={{
                                    m: 4,
                                    flex: 1,
                                    pt: 2,
                                    pb: 2,
                                    display: "flex", // Make the container a flex container
                                    alignItems: "center", // Center vertically
                                    justifyContent: "center", // Center horizontally
                                    fontWeight: 800,
                                    color: "#3f48cc",
                                  }}
                                  component="div"
                                >
                                  Delinquency
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} mt={3}>
                                <Tooltip
                                  title={
                                    "CIBIL Delinquency - 150+DPD in Last 18-months"
                                  }
                                  arrow
                                  placement="top"
                                >
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name="CIBIL_150PLUS_DPD_L18M_1"
                                    label="CIBIL Delinquency - 150+DPD in Last 18-months"
                                    value={
                                      creditInformation.CIBIL_150PLUS_DPD_L18M_1
                                    }
                                    onChange={(e) => handleCreditInformation(e)}
                                    InputLabelProps={{
                                      style: {
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        lineHeight: "1.2",
                                        cursor: "pointer",
                                        width: "100%",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} mt={3}>
                                <Tooltip
                                  title={
                                    "CIBIL Delinquency - 90+DPD in Last 12-months"
                                  }
                                  arrow
                                  placement="top"
                                >
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name="CIBIL_90PLUS_DPD_L12M_1"
                                    label="CIBIL Delinquency - 90+DPD in Last 12-months"
                                    value={
                                      creditInformation.CIBIL_90PLUS_DPD_L12M_1
                                    }
                                    onChange={(e) => handleCreditInformation(e)}
                                    InputLabelProps={{
                                      style: {
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        lineHeight: "1.2",
                                        cursor: "pointer",
                                        width: "100%",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} mt={3}>
                                <Tooltip
                                  title={
                                    "CIBIL Worst Delinquency in the latest month"
                                  }
                                  arrow
                                  placement="top"
                                >
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name="CIBIL_DELINQUENCY_LATEST_1"
                                    label="CIBIL Worst Delinquency in the latest month"
                                    value={
                                      creditInformation.CIBIL_DELINQUENCY_LATEST_1
                                    }
                                    onChange={(e) => handleCreditInformation(e)}
                                    InputLabelProps={{
                                      style: {
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        lineHeight: "1.2",
                                        cursor: "pointer",
                                        width: "100%",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container sx={{ mt: 2 }}>
                      <Grid item xs={12} sm={6} md={6}>
                        <div className="details-box">
                          <Grid item xs={12} sx={{ pt: 4, m: 2 }}>
                            <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                              <Grid item xs={12} sm={12} md={12}>
                                <Typography
                                  sx={{
                                    m: 4,
                                    flex: 1,
                                    pt: 2,
                                    pb: 2,
                                    display: "flex", // Make the container a flex container
                                    alignItems: "center", // Center vertically
                                    justifyContent: "center", // Center horizontally
                                    fontWeight: 800,
                                    color: "#3f48cc",
                                  }}
                                  component="div"
                                >
                                  Repayment Capacity
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} mt={3}>
                                <Tooltip
                                  title={"Debt Servicing Ratio [DSR]"}
                                  arrow
                                  placement="top"
                                >
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name="r_DSR_2"
                                    label="Debt Servicing Ratio [DSR]"
                                    value={creditInformation.r_DSR_2}
                                    onChange={(e) => handleCreditInformation(e)}
                                    InputLabelProps={{
                                      style: {
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        lineHeight: "1.2",
                                        cursor: "pointer",
                                        width: "100%",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} mt={3}>
                                <Tooltip
                                  title={"Total Unsecured Debt to Income [DTI]"}
                                  arrow
                                  placement="top"
                                >
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name="x_DTI_2"
                                    label="Total Unsecured Debt to Income [DTI]"
                                    value={creditInformation.x_DTI_2}
                                    onChange={(e) => handleCreditInformation(e)}
                                    InputLabelProps={{
                                      style: {
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        lineHeight: "1.2",
                                        cursor: "pointer",
                                        width: "100%",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} mt={3}>
                                <Tooltip
                                  title={"Bank Statement: #Cheques Bounced"}
                                  arrow
                                  placement="top"
                                >
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name="BankStatement_n_cheque_bounced_total_l6m_2"
                                    label="Bank Statement: #Cheques Bounced"
                                    value={
                                      creditInformation.BankStatement_n_cheque_bounced_total_l6m_2
                                    }
                                    onChange={(e) => handleCreditInformation(e)}
                                    InputLabelProps={{
                                      style: {
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        lineHeight: "1.2",
                                        cursor: "pointer",
                                        width: "100%",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} mt={3}>
                                <Tooltip
                                  title={
                                    "Bank Statement: Cash Flow as a ratio of Total Credits and Total Debits"
                                  }
                                  arrow
                                  placement="top"
                                >
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name="x_Cashflow_2"
                                    label="Bank Statement: Cash Flow as a ratio of Total Credits and Total Debits"
                                    value={creditInformation.x_Cashflow_2}
                                    onChange={(e) => handleCreditInformation(e)}
                                    InputLabelProps={{
                                      style: {
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        lineHeight: "1.2",
                                        cursor: "pointer",
                                        width: "100%",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} mt={3}>
                                <Tooltip
                                  title={"Net Sales"}
                                  arrow
                                  placement="top"
                                >
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name="BankStatement_credits_avg_6m_2"
                                    label="Net Sales"
                                    value={
                                      isNaN(
                                        creditInformation.BankStatement_credits_avg_6m_2
                                      )
                                        ? creditInformation.BankStatement_credits_avg_6m_2
                                        : Number(
                                            creditInformation.BankStatement_credits_avg_6m_2
                                          ).toLocaleString()
                                    }
                                    onChange={(e) => {
                                      const newValue = e.target.value.replace(
                                        /,/g,
                                        ""
                                      ); // Remove commas
                                      handleCreditInformation({
                                        target: {
                                          name: "BankStatement_credits_avg_6m_2",
                                          value: newValue,
                                        },
                                      });
                                    }}
                                    InputLabelProps={{
                                      style: {
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        lineHeight: "1.2",
                                        cursor: "pointer",
                                        width: "100%",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} mt={3}>
                                <Tooltip
                                  title={"Total Unsecured Current Balance"}
                                  arrow
                                  placement="top"
                                >
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name="CIBIL_a_debt_total_1"
                                    label="Total Unsecured Current Balance"
                                    value={
                                      creditInformation.CIBIL_a_debt_total_1
                                    }
                                    onChange={(e) => handleCreditInformation(e)}
                                    InputLabelProps={{
                                      style: {
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        lineHeight: "1.2",
                                        cursor: "pointer",
                                        width: "100%",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} mt={3}>
                                <Tooltip
                                  title={"Total Secured Current Balance"}
                                  arrow
                                  placement="top"
                                >
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name="CIBIL_a_debt_monthly_secured_1"
                                    label="Total Secured Current Balance"
                                    value={
                                      creditInformation.CIBIL_a_debt_monthly_secured_1
                                    }
                                    onChange={(e) => handleCreditInformation(e)}
                                    InputLabelProps={{
                                      style: {
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        lineHeight: "1.2",
                                        cursor: "pointer",
                                        width: "100%",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} mt={3}>
                                <Tooltip
                                  title={"Total Outstanding Balance"}
                                  arrow
                                  placement="top"
                                >
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name="CIBIL_TOTAL_OUTSTANDING_BALANCE_1"
                                    label="Total Outstanding Balance"
                                    value={
                                      creditInformation.CIBIL_TOTAL_OUTSTANDING_BALANCE_1
                                    }
                                    onChange={(e) => handleCreditInformation(e)}
                                    InputLabelProps={{
                                      style: {
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        lineHeight: "1.2",
                                        cursor: "pointer",
                                        width: "100%",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} mt={3}>
                                <Tooltip
                                  title={"Total Current Balance"}
                                  arrow
                                  placement="top"
                                >
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name="CIBIL_a_debt_monthly_1"
                                    label="Current Balance Monthly"
                                    value={
                                      creditInformation.CIBIL_a_debt_monthly_1
                                    }
                                    onChange={(e) => handleCreditInformation(e)}
                                    InputLabelProps={{
                                      style: {
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        lineHeight: "1.2",
                                        cursor: "pointer",
                                        width: "100%",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <div className="details-box">
                          <Grid item xs={12} sx={{ pt: 4, m: 2 }}>
                            <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                              <Grid item xs={12} sm={12} md={12}>
                                <Typography
                                  sx={{
                                    m: 4,
                                    flex: 1,
                                    pt: 2,
                                    pb: 2,
                                    display: "flex", // Make the container a flex container
                                    alignItems: "center", // Center vertically
                                    justifyContent: "center", // Center horizontally
                                    fontWeight: 800,
                                    color: "#3f48cc",
                                  }}
                                  component="div"
                                >
                                  Others
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} mt={3}>
                                <Tooltip
                                  title={"CIBIL Vintage"}
                                  arrow
                                  placement="top"
                                >
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name="CIBIL_n_vintage_months_1"
                                    label="CIBIL Vintage"
                                    value={
                                      creditInformation.CIBIL_n_vintage_months_1
                                    }
                                    onChange={(e) => handleCreditInformation(e)}
                                    InputLabelProps={{
                                      style: {
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        lineHeight: "1.2",
                                        cursor: "pointer",
                                        width: "100%",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} mt={3}>
                                <Tooltip
                                  title={
                                    "CIBIL Enquiries - #New Enquiries for Unsecured products in Last 3-months"
                                  }
                                  arrow
                                  placement="top"
                                >
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name="CIBIL_k_enquiries_newunsec_L3M_1"
                                    label="CIBIL Enquiries - #New Enquiries for Unsecured products in Last 3-months"
                                    value={
                                      creditInformation.CIBIL_k_enquiries_newunsec_L3M_1
                                    }
                                    onChange={(e) => handleCreditInformation(e)}
                                    InputLabelProps={{
                                      style: {
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        lineHeight: "1.2",
                                        cursor: "pointer",
                                        width: "100%",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} mt={3}>
                                <Tooltip
                                  title={
                                    "CIBIL : Has Housing Loan of INR 25lacs or more"
                                  }
                                  arrow
                                  placement="top"
                                >
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name="CIBIL_f_home_loan_gt25lacs_1"
                                    label="CIBIL : Has Housing Loan of INR 25lacs or more"
                                    value={
                                      creditInformation.CIBIL_f_home_loan_gt25lacs_1
                                    }
                                    onChange={(e) => handleCreditInformation(e)}
                                    InputLabelProps={{
                                      style: {
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        lineHeight: "1.2",
                                        cursor: "pointer",
                                        width: "100%",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} mt={3}>
                                <Tooltip
                                  title={"Secured Amount Overdue in CIBIL"}
                                  arrow
                                  placement="top"
                                >
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name="CIBIL_a_overdue_sec_1"
                                    label="Secured Amount Overdue in CIBIL"
                                    value={
                                      creditInformation.CIBIL_a_overdue_sec_1
                                    }
                                    onChange={(e) => handleCreditInformation(e)}
                                    InputLabelProps={{
                                      style: {
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        lineHeight: "1.2",
                                        cursor: "pointer",
                                        width: "100%",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} mt={3}>
                                <Tooltip
                                  title={"Unsecured Amount Overdue in CIBIL"}
                                  arrow
                                  placement="top"
                                >
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name="CIBIL_a_overdue_unsec_1"
                                    label="Unsecured Amount Overdue in CIBIL"
                                    value={
                                      creditInformation.CIBIL_a_overdue_unsec_1
                                    }
                                    onChange={(e) => handleCreditInformation(e)}
                                    InputLabelProps={{
                                      style: {
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        lineHeight: "1.2",
                                        cursor: "pointer",
                                        width: "100%",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} mt={3}>
                                <Tooltip
                                  title={"Total Amount Overdue in CIBIL"}
                                  arrow
                                  placement="top"
                                >
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name="CIBIL_a_overdue_1"
                                    label="Total Amount Overdue in CIBIL"
                                    value={creditInformation.CIBIL_a_overdue_1}
                                    onChange={(e) => handleCreditInformation(e)}
                                    InputLabelProps={{
                                      style: {
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        lineHeight: "1.2",
                                        cursor: "pointer",
                                        width: "100%",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} mt={3}>
                                <Tooltip
                                  title={"Secured Credit Card Count"}
                                  arrow
                                  placement="top"
                                >
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name="CIBIL_K_SEC_CC_COUNT_1"
                                    label="Secured Credit Card Count"
                                    value={
                                      creditInformation.CIBIL_K_SEC_CC_COUNT_1
                                    }
                                    onChange={(e) => handleCreditInformation(e)}
                                    InputLabelProps={{
                                      style: {
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        lineHeight: "1.2",
                                        cursor: "pointer",
                                        width: "100%",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} mt={3}>
                                <Tooltip
                                  title={"Unsecured Credit Card Count"}
                                  arrow
                                  placement="top"
                                >
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name="CIBIL_K_UNSEC_CC_COUNT_1"
                                    label="Unsecured Credit Card Count"
                                    value={
                                      creditInformation.CIBIL_K_UNSEC_CC_COUNT_1
                                    }
                                    onChange={(e) => handleCreditInformation(e)}
                                    InputLabelProps={{
                                      style: {
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        lineHeight: "1.2",
                                        cursor: "pointer",
                                        width: "100%",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container sx={{ mt: 2 }}>
                      <Grid item xs={12} sm={6} md={6}>
                        <div className="details-box">
                          <Grid item xs={12} sx={{ pt: 4, m: 2 }}>
                            <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                              <Grid item xs={12} sm={12} md={12}>
                                <Typography
                                  sx={{
                                    m: 4,
                                    flex: 1,
                                    pt: 2,
                                    pb: 2,
                                    display: "flex", // Make the container a flex container
                                    alignItems: "center", // Center vertically
                                    justifyContent: "center", // Center horizontally
                                    fontWeight: 800,
                                    color: "#3f48cc",
                                  }}
                                  component="div"
                                >
                                  Relationship
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} mt={3}>
                                <Tooltip
                                  title={
                                    "Relationship type: New or Existing to Gosree"
                                  }
                                  arrow
                                  placement="top"
                                >
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name="CIBIL_FLAG_GOSREE_1"
                                    label="Relationship type: New or Existing to Gosree"
                                    value={
                                      creditInformation.CIBIL_FLAG_GOSREE_1
                                    }
                                    onChange={(e) => handleCreditInformation(e)}
                                    InputLabelProps={{
                                      style: {
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        lineHeight: "1.2",
                                        cursor: "pointer",
                                        width: "100%",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              </Grid>

                              <Grid item xs={12} sm={6} md={6} mt={3}>
                                <Tooltip
                                  title={"Last Loan Amount Disbursed by Gosree"}
                                  arrow
                                  placement="top"
                                >
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name="CIBIL_A_LOAN_GOSREE_LATEST_1"
                                    label="Last Loan Amount Disbursed by Gosree"
                                    value={
                                      creditInformation.CIBIL_A_LOAN_GOSREE_LATEST_1
                                    }
                                    onChange={(e) => handleCreditInformation(e)}
                                    InputLabelProps={{
                                      style: {
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        lineHeight: "1.2",
                                        cursor: "pointer",
                                        width: "100%",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <div className="details-box">
                          <Grid item xs={12} sx={{ pt: 4, m: 2 }}>
                            <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                              <Grid item xs={12} sm={12} md={12}>
                                <Typography
                                  sx={{
                                    m: 4,
                                    flex: 1,
                                    pt: 2,
                                    pb: 2,
                                    display: "flex", // Make the container a flex container
                                    alignItems: "center", // Center vertically
                                    justifyContent: "center", // Center horizontally
                                    fontWeight: 800,
                                    color: "#3f48cc",
                                  }}
                                  component="div"
                                >
                                  Loan Eligibility
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} mt={3}>
                                <Tooltip
                                  title={"Maximum Loan Eligibility"}
                                  arrow
                                  placement="top"
                                >
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name="maximum_loan_eligibility_2"
                                    label="Maximum Loan Eligibility"
                                    value={
                                      isNaN(
                                        creditInformation.maximum_loan_eligibility_2
                                      )
                                        ? creditInformation.maximum_loan_eligibility_2
                                        : Number(
                                            creditInformation.maximum_loan_eligibility_2
                                          ).toLocaleString()
                                    }
                                    onChange={(e) => {
                                      const newValue = e.target.value.replace(
                                        /,/g,
                                        ""
                                      ); // Remove commas
                                      handleCreditInformation({
                                        target: {
                                          name: "maximum_loan_eligibility_2",
                                          value: newValue,
                                        },
                                      });
                                    }}
                                    InputLabelProps={{
                                      style: {
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        lineHeight: "1.2",
                                        cursor: "pointer",
                                        width: "100%",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={4} sx={{ pl: 1, pr: 1, mt: 2 }}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        style={{
                          width: "100%",
                        }}
                      >
                        <Button
                          sx={{ textTransform: "none" }}
                          disabled={currentUser.role_name != "credit_head"}
                          variant="contained"
                          className="download-button"
                          onClick={updateCreditDetails}
                        >
                          Update
                        </Button>
                      </Grid>
                      {/* <TabPanel value={value} index={0} style={{ width: "100%" }}>
                  <Grid item xs={12} sx={{ pt: 4 }}>
                    {companyData != undefined ? (
                      <div className="details-box">
                        <Grid item xs={12} sx={{ pt: 4 }}>
                          <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                            <Grid item xs={12} sm={12} md={12}>
                              <Typography
                                sx={{ mr: 4, flex: 1, pt: 2, pb: 2 }}
                                component="div"
                                style={{ fontWeight: "800", color: "#3f48cc" }}
                              >
                                Credit Information
                              </Typography>
                            </Grid>
                            {Object.entries(companyData).map(([key, value]) =>
                              typeof value !== "object" ? (
                                <Grid
                                  key={key}
                                  item
                                  xs={12}
                                  sm={6}
                                  md={6}
                                  mt={3}
                                >
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name={key}
                                    label={key
                                      .replace(/_/g, " ")
                                      .toLowerCase()
                                      .split(" ")
                                      .map(
                                        (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                      )
                                      .join(" ")}
                                    value={value}
                                    onChange={(e) => handleInputChange(e)}
                                  />
                                </Grid>
                              ) : null
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    ) : null}
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={1} style={{ width: "100%" }}>
                  <Grid item xs={12} sx={{ pt: 4 }}>
                    {individualData != undefined ? (
                      <div className="details-box">
                        <Grid item xs={12} sx={{ pt: 4 }}>
                          <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                            <Grid item xs={12} sm={12} md={12}>
                              <Typography
                                sx={{ mr: 4, flex: 1, pt: 2, pb: 2 }}
                                component="div"
                                style={{ fontWeight: "800", color: "#3f48cc" }}
                              >
                                Individual's Credit Information
                              </Typography>
                            </Grid>

                            {Object.entries(individualData).map(
                              ([key, value]) =>
                                typeof value !== "object" ? (
                                  <Grid
                                    key={key}
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    mt={3}
                                  >
                                    <TextField
                                      fullWidth
                                      type="text"
                                      variant="outlined"
                                      name={key}
                                      label={key
                                        .replace(/_/g, " ")
                                        .toLowerCase()
                                        .split(" ")
                                        .map(
                                          (word) =>
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1)
                                        )
                                        .join(" ")}
                                      value={value}
                                      onChange={(e) => handleInputChange(e)}
                                    />
                                  </Grid>
                                ) : null
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    ) : null}
                  </Grid>
                </TabPanel> */}
                    </Grid>
                    <Grid container sx={{ mt: 2 }}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          sx={{
                            m: 4,
                            flex: 1,
                            pt: 2,
                            pb: 2,
                            display: "flex", // Make the container a flex container
                            alignItems: "center", // Center vertically
                            justifyContent: "center", // Center horizontally
                            fontWeight: "800 !important",
                            color: "#3f48cc",
                          }}
                          component="div"
                        >
                          Fraud Results
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Grid item xs={12}>
                          <div className="details-box">
                            <CardContent
                              sx={{
                                display: "grid",
                                padding: "0px 8px",
                                width: "100%",
                                overflow: "auto",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  display: "grid",
                                  fontSize: "0.9rem",
                                }}
                              >
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Rule Name</th>
                                      <th>Result</th>
                                      <th>Result Reason</th>
                                      {/* Add more table headers as needed */}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {fraudResults.map((result, index) => (
                                      <tr key={index}>
                                        <td
                                          style={{
                                            fontSize: "0.9rem",
                                            color: "#424242",
                                          }}
                                        >
                                          {result.FRAUD_RULE_NAME}
                                        </td>
                                        <td
                                          style={{
                                            fontSize: "0.9rem",
                                            color: "#424242",
                                          }}
                                        >
                                          {result.FRAUD_CHECK_RESULT}
                                        </td>
                                        <td
                                          style={{
                                            fontSize: "0.9rem",
                                            color: "#424242",
                                          }}
                                        >
                                          {result.FRAUD_CHECK_RESULT_REASON}
                                        </td>
                                        {/* Add more table cells as needed */}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </CardContent>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container sx={{ mt: 2 }}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          sx={{
                            m: 4,
                            flex: 1,
                            pt: 2,
                            pb: 2,
                            display: "flex", // Make the container a flex container
                            alignItems: "center", // Center vertically
                            justifyContent: "center", // Center horizontally
                            fontWeight: "800 !important",
                            color: "#3f48cc",
                          }}
                          component="div"
                        >
                          BRE Results
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Grid item xs={12}>
                          <div className="details-box">
                            <Grid item xs={12} sm={12} md={12}>
                              <Typography
                                sx={{
                                  m: 4,
                                  flex: 1,
                                  pt: 2,
                                  pb: 2,
                                  display: "flex", // Make the container a flex container
                                  alignItems: "center", // Center vertically
                                  justifyContent: "center", // Center horizontally
                                  color: "#3f48cc",
                                }}
                                component="div"
                              >
                                BRE 1 Results
                              </Typography>
                            </Grid>
                            <Typography sx={{ fontSize: "10px" }}>
                              <strong>
                                Test Output {""}
                                {parseResult(bre1Results.bre_result) !==
                                null ? (
                                  <>
                                    (
                                    {parseResult(bre1Results.bre_result) ===
                                    "" ? (
                                      <span style={{ color: "red" }}>
                                        error with the tree - invalid
                                      </span>
                                    ) : (
                                      <span style={{ color: "#3f48cc" }}>
                                        {
                                          parseResult(bre1Results.bre_result)
                                            ?.final_output
                                        }
                                      </span>
                                    )}
                                    )
                                  </>
                                ) : null}
                              </strong>
                            </Typography>
                            <Typography sx={{ fontSize: "10px" }}>
                              <strong>
                                Score {""}
                                {parseResult(bre1Results.bre_result) !==
                                null ? (
                                  <>
                                    (
                                    {parseResult(bre1Results.bre_result) ===
                                    "" ? (
                                      <span style={{ color: "red" }}>
                                        error with the tree - invalid
                                      </span>
                                    ) : (
                                      <span style={{ color: "#3f48cc" }}>
                                        {
                                          parseResult(bre1Results.bre_result)
                                            ?.score_value
                                        }
                                      </span>
                                    )}
                                    )
                                  </>
                                ) : null}
                              </strong>
                            </Typography>

                            <CardContent
                              sx={{
                                display: "grid",
                                padding: "0px 8px",
                                width: "100%",
                                overflow: "auto",
                              }}
                            >
                              {parseResult(bre1Results.bre_result) !== null ? (
                                <div
                                  style={{
                                    width: "100%",
                                    display: "grid",
                                    fontSize: "0.9rem",
                                  }}
                                >
                                  <table>
                                    <thead>
                                      <tr>
                                        <th colSpan="3">Visited Node:</th>
                                      </tr>
                                      <tr>
                                        <th>No.</th>
                                        <th>Node</th>
                                        <th>Condition</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {parseResult(
                                        bre1Results.bre_result
                                      )?.visited_nodes.map((item, index) => {
                                        return (
                                          <>
                                            <tr>
                                              <td
                                                style={{
                                                  fontSize: "0.9rem",
                                                  color: "#424242",
                                                }}
                                              >
                                                {index + 1}
                                              </td>
                                              <td
                                                style={{
                                                  fontSize: "0.9rem",
                                                  color: "#424242",
                                                }}
                                              >
                                                {item.node_name}
                                              </td>
                                              <td
                                                style={{
                                                  fontSize: "0.9rem",
                                                  color: "#424242",
                                                }}
                                              >
                                                {item.expression}
                                              </td>
                                              {console.log(item)}
                                            </tr>
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              ) : null}
                            </CardContent>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Grid item xs={12}>
                          <div className="details-box">
                            <Grid item xs={12} sm={12} md={12}>
                              <Typography
                                sx={{
                                  m: 4,
                                  flex: 1,
                                  pt: 2,
                                  pb: 2,
                                  display: "flex", // Make the container a flex container
                                  alignItems: "center", // Center vertically
                                  justifyContent: "center", // Center horizontally
                                  color: "#3f48cc",
                                }}
                                component="div"
                              >
                                BRE 2 Results
                              </Typography>
                            </Grid>
                            <Typography sx={{ fontSize: "10px" }}>
                              <strong>
                                Test Output {""}
                                {parseResult(bre2Results.bre_result) !==
                                null ? (
                                  <>
                                    (
                                    {parseResult(bre2Results.bre_result) ===
                                    "" ? (
                                      <span style={{ color: "red" }}>
                                        error with the tree - invalid
                                      </span>
                                    ) : (
                                      <span style={{ color: "#3f48cc" }}>
                                        {
                                          parseResult(bre2Results.bre_result)
                                            ?.final_output
                                        }
                                      </span>
                                    )}
                                    )
                                  </>
                                ) : null}
                              </strong>
                            </Typography>
                            <Typography sx={{ fontSize: "10px" }}>
                              <strong>
                                Score {""}
                                {parseResult(bre2Results.bre_result) !==
                                null ? (
                                  <>
                                    (
                                    {parseResult(bre2Results.bre_result) ===
                                    "" ? (
                                      <span style={{ color: "red" }}>
                                        error with the tree - invalid
                                      </span>
                                    ) : (
                                      <span style={{ color: "#3f48cc" }}>
                                        {
                                          parseResult(bre2Results.bre_result)
                                            ?.score_value
                                        }
                                      </span>
                                    )}
                                    )
                                  </>
                                ) : null}
                              </strong>
                            </Typography>

                            <CardContent
                              sx={{
                                display: "grid",
                                padding: "0px 8px",
                                width: "100%",
                                overflow: "auto",
                              }}
                            >
                              {parseResult(bre2Results.bre_result) !== null ? (
                                <div
                                  style={{
                                    width: "100%",
                                    display: "grid",
                                    fontSize: "0.9rem",
                                  }}
                                >
                                  <table>
                                    <thead>
                                      <tr>
                                        <th colSpan="3">Visited Node:</th>
                                      </tr>
                                      <tr>
                                        <th>No.</th>
                                        <th>Node</th>
                                        <th>Condition</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {parseResult(
                                        bre2Results.bre_result
                                      )?.visited_nodes.map((item, index) => {
                                        return (
                                          <>
                                            <tr>
                                              <td
                                                style={{
                                                  fontSize: "0.9rem",
                                                  color: "#424242",
                                                }}
                                              >
                                                {index + 1}
                                              </td>
                                              <td
                                                style={{
                                                  fontSize: "0.9rem",
                                                  color: "#424242",
                                                }}
                                              >
                                                {item.node_name}
                                              </td>
                                              <td
                                                style={{
                                                  fontSize: "0.9rem",
                                                  color: "#424242",
                                                }}
                                              >
                                                {item.expression}
                                              </td>
                                              {console.log(item)}
                                            </tr>
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              ) : null}
                            </CardContent>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Grid item xs={12}>
                          <div className="details-box">
                            <Grid item xs={12} sm={12} md={12}>
                              <Typography
                                sx={{
                                  m: 4,
                                  flex: 1,
                                  pt: 2,
                                  pb: 2,
                                  display: "flex", // Make the container a flex container
                                  alignItems: "center", // Center vertically
                                  justifyContent: "center", // Center horizontally
                                  color: "#3f48cc",
                                }}
                                component="div"
                              >
                                BRE 3 Results
                              </Typography>
                            </Grid>
                            <Typography sx={{ fontSize: "10px" }}>
                              <strong>
                                Test Output {""}
                                {parseResult(bre3Results.bre_result) !==
                                null ? (
                                  <>
                                    (
                                    {parseResult(bre3Results.bre_result) ===
                                    "" ? (
                                      <span style={{ color: "red" }}>
                                        error with the tree - invalid
                                      </span>
                                    ) : (
                                      <span style={{ color: "#3f48cc" }}>
                                        {
                                          parseResult(bre3Results.bre_result)
                                            ?.final_output
                                        }
                                      </span>
                                    )}
                                    )
                                  </>
                                ) : null}
                              </strong>
                            </Typography>
                            <Typography sx={{ fontSize: "10px" }}>
                              <strong>
                                Score {""}
                                {parseResult(bre3Results.bre_result) !==
                                null ? (
                                  <>
                                    (
                                    {parseResult(bre3Results.bre_result) ===
                                    "" ? (
                                      <span style={{ color: "red" }}>
                                        error with the tree - invalid
                                      </span>
                                    ) : (
                                      <span style={{ color: "#3f48cc" }}>
                                        {
                                          parseResult(bre3Results.bre_result)
                                            ?.score_value
                                        }
                                      </span>
                                    )}
                                    )
                                  </>
                                ) : null}
                              </strong>
                            </Typography>

                            <CardContent
                              sx={{
                                display: "grid",
                                padding: "0px 8px",
                                width: "100%",
                                overflow: "auto",
                              }}
                            >
                              {parseResult(bre3Results.bre_result) !== null ? (
                                <div
                                  style={{
                                    width: "100%",
                                    display: "grid",
                                    fontSize: "0.9rem",
                                  }}
                                >
                                  <table>
                                    <thead>
                                      <tr>
                                        <th colSpan="3">Visited Node:</th>
                                      </tr>
                                      <tr>
                                        <th>No.</th>
                                        <th>Node</th>
                                        <th>Condition</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {parseResult(
                                        bre3Results.bre_result
                                      )?.visited_nodes.map((item, index) => {
                                        return (
                                          <>
                                            <tr>
                                              <td
                                                style={{
                                                  fontSize: "0.9rem",
                                                  color: "#424242",
                                                }}
                                              >
                                                {index + 1}
                                              </td>
                                              <td
                                                style={{
                                                  fontSize: "0.9rem",
                                                  color: "#424242",
                                                }}
                                              >
                                                {item.node_name}
                                              </td>
                                              <td
                                                style={{
                                                  fontSize: "0.9rem",
                                                  color: "#424242",
                                                }}
                                              >
                                                {item.expression}
                                              </td>
                                              {console.log(item)}
                                            </tr>
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              ) : null}
                            </CardContent>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            ) : null}
          </div>
        </Box>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
