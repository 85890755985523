import React from "react";
import {
  Box,
  Container,
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
  Typography,
  TextField,
  Button,
  Divider,
} from "@material-ui/core";
import "./../styles.css";
import pic1 from "./../../assets/corestrat.png";
import pic2 from "./../../assets/COVER_CMS.png";
import Grid from "@mui/material/Grid";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { useState } from "react";
import { base_url1 } from "../../constants";
import Axios from "axios";
export const theme = createTheme({
  typography: {
    // fontFamily: "Times New Roman",
  },
});

export default function CoverPage() {
  const [error, setError] = React.useState(false);
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const handleInputChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  return (
    <ThemeProvider theme={responsiveFontSizes(theme)}>
      <Container
        maxWidth
        align="center"
        style={{
          background: "linear-gradient(90deg, #fafafa 50%, #fff 50%)",
          height: "80vh",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12}>
          <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}></Grid>
          <a href="/login">
            <img className="cover_img" src={pic2} />
          </a>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
