import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  verificationSideNav,
  secondaryListItems,
  disbursementSideNav,
} from "../Side_Nav/listItems";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TextField } from "@material-ui/core";
import LoanDetails from "../Common/basicLoanDetails";
import LogoutIcon from "@mui/icons-material/Logout";
import Tooltip from "@mui/material/Tooltip";
import { base_url1 } from "../../constants";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import AppHeader from "./../Common/header";
import Footer from "./../Common/footer";
import { useNavigate } from "react-router-dom";
import SessionModal from "./../Common/SessionModal";
import {
  createModifyUpdateDisbursement,
  getUpdateDisbursement,
} from "./../apiCalls/api";
import { useState } from "react";
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();
const handleChangeInput = (event) => {};
function UpdateDisbursement() {
  const reduxData = useSelector(
    (state) => state.applications?.loan_basic_info?.Loan_Application_id
  );
  const [updateDisbursementList, setupdateDisbursementList] = React.useState({
    loan_id: reduxData,
    collateral_type: "",
    tranche_no: "",
    amount_approved_for_disbursement: "",
    disbursement_date: "",
    actual_disbursement_date: "",
    bpv_no: "",
    bpv_date: "",
    remarks: "",
    loan_amount_number: "",
    id: 0,
  });
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [open1, setOpen1] = React.useState(false);
  const navigate = useNavigate();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const handleClick = () => {
    setOpen1(!open1);
  };
  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    navigate("/login");
  };
  const handleInputChange = (e) => {
    setupdateDisbursementList({
      ...updateDisbursementList,
      [e.target.name]: e.target.value,
    });
  };
  const update = async () => {
    const item = {
      loan_id: reduxData,
      collateral_type: updateDisbursementList.collateral_type,
      tranche_no: updateDisbursementList.tranche_no,
      amount_approved_for_disbursement:
        updateDisbursementList.amount_approved_for_disbursement,
      disbursement_date: updateDisbursementList.disbursement_date,
      actual_disbursement_date: updateDisbursementList.actual_disbursement_date,
      bpv_no: updateDisbursementList.bpv_no,
      bpv_date: updateDisbursementList.bpv_date,
      remarks: updateDisbursementList.remarks,
      loan_amount_number: updateDisbursementList.loan_amount_number,
      id: 0,
    };
    try {
      const result = await createModifyUpdateDisbursement(item);
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  useEffect(() => {
    async function getData() {
      try {
        const result = await getUpdateDisbursement(reduxData);
        if (result.data.data == "") {
          update();
        } else {
          setupdateDisbursementList(result.data.data.update_disbursement);
        }
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setIsErrorModalOpen(true);
          console.error(error);
        }
      }
    }
    getData();
  }, [reduxData]);

  return (
    <ThemeProvider theme={mdTheme}>
      <div>
        {/* Your component content */}
        <SessionModal
          isOpen={isErrorModalOpen}
          onClose={handleCloseErrorModal}
        />
      </div>
      <Box sx={{ display: "flex", mb: 6 }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <AppHeader
              open={open}
              toggleDrawer={toggleDrawer}
              title="Update Disbursement"
            />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {disbursementSideNav}
            <Divider sx={{ my: 1 }} />
            {/* {secondaryListItems} */}
          </List>
        </Drawer>
        <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
          <div style={{ marginTop: "1%" }}>
            <LoanDetails></LoanDetails>
            <div className="details-box">
              <Grid item xs={12} sx={{ pt: 4 }}>
                <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                  {updateDisbursementList != undefined ? (
                    <Grid item xs={12} sx={{ pt: 4 }}>
                      <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            sx={{ m: 3, flex: 1, pt: 2, pb: 2 }}
                            component="div"
                            style={{ fontWeight: "800", color: "#3f48cc" }}
                          >
                            Update Disbursement
                          </Typography>
                        </Grid>
                        {Object.entries(updateDisbursementList).map(
                          ([key, value]) => (
                            <>
                              {key != "id" && key != "loan_id" ? (
                                <Grid
                                  key={key}
                                  item
                                  xs={12}
                                  sm={6}
                                  md={6}
                                  mt={3}
                                >
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name={key}
                                    label={key
                                      .replace(/_/g, " ")
                                      .toLowerCase()
                                      .split(" ")
                                      .map(
                                        (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                      )
                                      .join(" ")}
                                    value={
                                      isNaN(value)
                                        ? value
                                        : Number(value).toLocaleString()
                                    }
                                    onChange={(e) => {
                                      const newValue = e.target.value.replace(
                                        /,/g,
                                        ""
                                      ); // Remove commas
                                      handleInputChange({
                                        target: {
                                          name: key,
                                          value: newValue,
                                        },
                                      });
                                    }}
                                  />
                                </Grid>
                              ) : null}
                            </>
                          )
                        )}
                      </Grid>
                    </Grid>
                  ) : null}
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    style={{
                      width: "100%",
                    }}
                  >
                    <Button
                      sx={{ textTransform: "none" }}
                      variant="contained"
                      className="download-button"
                      onClick={update}
                    >
                      Update
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </Box>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}

export default function updateDisbursement() {
  return <UpdateDisbursement />;
}
