export const blueButtonStyle = {
  "&.MuiButton-text": {
    backgroundColor: "#3f48cc",
    color: "#ffffff",
    textTransform: "none",
  },

  "&.MuiButton-text:hover": {
    backgroundColor: "#0076c9 ",
  },
  whiteSpace: "nowrap",
};
export const greyButtonStyle = {
  "&.MuiButton-text": {
    backgroundColor: "#d9d9d9",
    color: "#000000",
    textTransform: "none",
  },

  "&.MuiButton-text:hover": {
    backgroundColor: "#999999",
  },
  whiteSpace: "nowrap",
};
