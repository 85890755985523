import React, { useState } from "react";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Footer from "./../Common/footer";
import SessionModal from "../Common/SessionModal";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  MenuItem,
  TextField,
  Select,
  OutlinedInput,
  Chip,
  FormControl,
  InputLabel,
  Button,
  Pagination,
  IconButton,
} from "@mui/material";
import {
  getAllBranches,
  getAllLoanCategories,
  generateReport,
  getAllUserChannelMaster,
} from "../apiCalls/api";
import { DataGrid } from "@mui/x-data-grid";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRigthButton from "@mui/icons-material/ArrowCircleRight";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { height } from "@mui/system";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { reportDataColumns } from "./reportsFilter";
const mdTheme = createTheme();

const transposeData = (columns, rows, dimensionValue) => {
  let newColumns = [{ field: "metric", headerName: "Metric", width: 400 }];
  let newRows = [];
  if(dimensionValue == "month_name"){
    rows?.forEach((item) => {
      const [year, month] = item.dimension.split("-");
      const monthAbbreviation = new Date(`${year}-${month}-01`).toLocaleString('default', { month: 'short' });
      const formattedDate = `${monthAbbreviation} ${year}`;
      newColumns.push({
        field: item.dimension,
        headerName: formattedDate,
        width: 150,
      });
    });
    columns?.forEach((item) => {
      let newRow = {};
      rows?.forEach((row) => {
        newRow[row["dimension"]] = row[item.field]?.toLocaleString();
      });
      newRows.push({ metric: item.headerName, ...newRow });
    });
    return [newColumns, newRows];
  }else if(dimensionValue == "create_date"){
    rows?.forEach((item) => {
      const dateObj = new Date(item.dimension);
      const formattedDate = dateObj.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
      newColumns.push({
        field: item.dimension,
        headerName: formattedDate,
        width: 150,
      });
    });
    columns?.forEach((item) => {
      let newRow = {};
      rows?.forEach((row) => {
        newRow[row["dimension"]] = row[item.field]?.toLocaleString();
      });
      newRows.push({ metric: item.headerName, ...newRow });
    });
    return [newColumns, newRows];
  }else{
    rows?.forEach((item) => {
      newColumns.push({
        field: item.dimension,
        headerName: item.dimension,
        width: 150,
      });
    });
    columns?.forEach((item) => {
      let newRow = {};
      rows?.forEach((row) => {
        newRow[row["dimension"]] = row[item.field]?.toLocaleString();
      });
      newRows.push({ metric: item.headerName, ...newRow });
    });
    return [newColumns, newRows];
  }
};

export default function Reports() {
  const navigate = useNavigate();

  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [branchName, setBranchName] = useState([]);
  const [allBranchValues, setAllBranchValues] = useState([]);
  const [filterLoanCategories, setFilterLoanCategories] = useState([]);
  const [filterUserChannelValues, setFilterUserChannelValues] = useState([]);
  const [allLoanCategoryValues, setAllLoanCategoryValues] = useState([]);
  const [allUserChannelValues, setAllUserChannelValues] = useState([]);
  const [dimensionValue, setDimensionValue] = useState("create_date");
  const [reportRows, setReportRows] = useState(null);
  const [reportCols, setReportCols] = useState(null);
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [isRportTableActive, setIsReportTableActive] = useState(false);

  const allDimensions = [
    { label: "Branch Name", key: "branch" },
    { label: "Loan Category", key: "loan_product" },
    { label: "User Channel", key: "channel_name" },
    { label: "Daily", key: "create_date" },
    { label: "Monthly", key: "month_name" },
    { label: "Yearly", key: "current_year" },
  ];

  useEffect(() => {
    const fetchAllBranchValues = async () => {
      let allBranches = [];
      try {
        allBranches = await getAllBranches();
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setIsSessionExpired(true);
        }
      }
      setAllBranchValues(allBranches);
    };
    const fetchAllLoanCategories = async () => {
      let allLoanCategories = [];
      try {
        allLoanCategories = await getAllLoanCategories();
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setIsSessionExpired(true);
        }
      }
      setAllLoanCategoryValues(allLoanCategories);
    };
    const fetchAllUserChannels = async () => {
      let allUserChannels = [];
      try {
        allUserChannels = await getAllUserChannelMaster();
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setIsSessionExpired(true);
        }
      }
      setAllUserChannelValues(allUserChannels);
    };
    fetchAllBranchValues();
    fetchAllLoanCategories();
    fetchAllUserChannels();
  }, []);

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    navigate("/login");
  };

  const handleOnBranchNameChange = (event) => {
    let selectedValues = event.target.value;
    if (selectedValues.includes("<clear>")) {
      setBranchName([]);
    } else {
      setBranchName(event.target.value);
    }
  };
  const handleClearAllBranchName = () => {
    setBranchName([]);
  };
  const handleClearAll = () => {
    setFilterLoanCategories([]);
  };
  const handleOnLoanCategoryChange = (event) => {
    let selectedValues = event.target.value;
    if (selectedValues.includes("<clear>")) {
      setFilterLoanCategories([]);
    } else {
      setFilterLoanCategories(event.target.value);
    }
  };

  const handleOnDimensionChange = (event) => {
    let selectedValue = event.target.value;
    if (selectedValue === "<clear>") {
      setDimensionValue(null);
    } else {
      setDimensionValue(selectedValue);
    }
  };

  const handleOnUserChannelChange = (event) => {
    let selectedValues = event.target.value;
    if (selectedValues.includes("<clear>")) {
      setFilterUserChannelValues([]);
    } else {
      setFilterUserChannelValues(event.target.value);
    }
  };
  const handleClearAllChannel = () => {
    setFilterUserChannelValues([]);
  };
  const handleOnGenerateReport = async (event) => {
    let newCols = [];
    let newRows = [];
    let oldCols = reportDataColumns;
    let oldRows = [];
    try {
      const api_response = await generateReport(
        branchName,
        filterLoanCategories,
        filterUserChannelValues,
        filterStartDate,
        filterEndDate,
        dimensionValue,
        1
      );
      api_response?.data?.forEach((item) => {
        oldRows.push({ dimension: item.dimension, ...item.dimensional_data });
      });
      setTotalPages(api_response?.total_pages);
      [newCols, newRows] = transposeData(oldCols, oldRows,dimensionValue);
      setReportCols(newCols);
      setReportRows(newRows);
      setIsReportTableActive(true);
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsSessionExpired(true);
      }
    }
  };

  const handleOnClickPageChange = async (event, action) => {
    let localCurrentPage = currentPage;
    if (currentPage > 1 && action === "previous") {
      setCurrentPage(currentPage - 1);
      localCurrentPage = currentPage - 1;
    }
    if (currentPage < totalPages && action === "next") {
      setCurrentPage(currentPage + 1);
      localCurrentPage = currentPage + 1;
    }

    let newCols = [];
    let newRows = [];
    let oldCols = reportDataColumns;
    let oldRows = [];
    const api_response = await generateReport(
      branchName,
      filterLoanCategories,
      filterUserChannelValues,
      filterStartDate,
      filterEndDate,
      dimensionValue,
      localCurrentPage
    );
    api_response?.data?.forEach((item) => {
      oldRows.push({ dimension: item.dimension, ...item.dimensional_data });
    });
    setTotalPages(api_response?.total_pages);
    [newCols, newRows] = transposeData(oldCols, oldRows, dimensionValue);
    setReportCols(newCols);
    setReportRows(newRows);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleStartDateChange = (date) => {
    setFilterStartDate(formatDate(date.toDate()));
  };

  const handleEndDateChange = (date) => {
    setFilterEndDate(formatDate(date.toDate()));
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <SessionModal isOpen={isSessionExpired} onClose={handleCloseErrorModal} />
      <Box sx={{ marginTop: "-6%" }}>
        <Typography
          sx={{ pt: 2, pb: 2 }}
          component="div"
          style={{ fontWeight: "800", color: "#3f48cc", fontSize: "20px" }}
        >
          Generate Reports
        </Typography>
        <Grid container sx={{ p: 1 }}>
          {/* Filter for Loan Cateogory */}
          <Grid item xs={6} sm={3} md={8}>
            <Typography
              sx={{ pt: 2, pb: 2 }}
              component="div"
              style={{ fontWeight: "600", fontSize: "15px", textAlign: "left" }}
            >
              Filters
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3} md={4}>
            <Typography
              sx={{ pt: 2, pb: 2 }}
              component="div"
              style={{ fontWeight: "600", fontSize: "15px", textAlign: "left" }}
            >
              Select Dates
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ p: 1 }}>
          {/* Filter for Loan Cateogory */}
          <Grid item xs={6} sm={3} md={8}>
            <Grid container sx={{ p: 1 }}>
              <Grid item xs={6} sm={3} md={4} sx={{ pr: 3 }}>
                <FormControl fullWidth>
                  <InputLabel>Loan Category</InputLabel>
                  <Select
                    multiple
                    label="Loan Category"
                    fullWidth
                    name="loanCategory"
                    variant="outlined"
                    onChange={handleOnLoanCategoryChange}
                    value={filterLoanCategories}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                  >
                    {allLoanCategoryValues.map((item) => (
                      <MenuItem
                        key={item.loan_category}
                        value={item.loan_product}
                      >
                        {" "}
                        {item.loan_product}{" "}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {filterLoanCategories && filterLoanCategories?.length > 0 ? (
                  <Button onClick={handleClearAll}>Clear</Button>
                ) : null}
              </Grid>

              {/* filter for Branch Name */}
              <Grid item xs={6} sm={3} md={4} sx={{ pr: 3 }}>
                <FormControl fullWidth>
                  <InputLabel>Branch Name</InputLabel>
                  <Select
                    multiple
                    label="Branch Name"
                    fullWidth
                    name="branchName"
                    variant="outlined"
                    onChange={handleOnBranchNameChange}
                    value={branchName}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                  >
                    {allBranchValues.map((item) => (
                      <MenuItem key={item.Branch_Code} value={item.Branch_Name}>
                        {" "}
                        {item.Branch_Name}{" "}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {branchName && branchName?.length > 0 ? (
                  <Button onClick={handleClearAllBranchName}>Clear</Button>
                ) : null}
              </Grid>

              {/* filter for user channel */}
              <Grid item xs={6} sm={3} md={4} sx={{ pr: 3 }}>
                <FormControl fullWidth>
                  <InputLabel>Channel Name</InputLabel>
                  <Select
                    multiple
                    label="Channel Name"
                    fullWidth
                    name="channelName"
                    variant="outlined"
                    onChange={handleOnUserChannelChange}
                    value={filterUserChannelValues}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                  >
                    {allUserChannelValues.map((item) => (
                      <MenuItem
                        key={item.channel_display_name}
                        value={item.channel_display_name}
                      >
                        {" "}
                        {item.channel_display_name}{" "}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {filterUserChannelValues &&
                filterUserChannelValues?.length > 0 ? (
                  <Button onClick={handleClearAllChannel}>Clear</Button>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          {/* Filter for Loan Cateogory */}
          <Grid item xs={6} sm={6} md={4}>
            <Grid container sx={{ p: 1 }}>
              {/* filter for start dates */}
              <Grid item xs={6} sm={2} md={6} sx={{ pr: 3 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    fullWidth
                    disableFuture
                    label="Start Date"
                    views={["year", "month", "day"]}
                    // value={filterStartDate}
                    onChange={handleStartDateChange}
                  />
                </LocalizationProvider>
              </Grid>

              {/* filter for end dates */}
              <Grid item xs={6} sm={2} md={6} sx={{ pr: 3 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    fullWidth
                    disableFuture
                    label="End Date"
                    views={["year", "month", "day"]}
                    // value={filterEndDate}
                    onChange={handleEndDateChange}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Typography
          sx={{ pt: 2, pb: 2 }}
          component="div"
          style={{ fontWeight: "600", fontSize: "15px", textAlign: "left" }}
        >
          Group By
        </Typography>
        <Grid container sx={{ p: 1 }}>
          {/* Dimensions */}
          <Grid item xs={6} sm={3} md={3} sx={{ pr: 3 }}>
            <FormControl fullWidth>
              <InputLabel>Dimension</InputLabel>
              <Select
                label="Dimension"
                fullWidth
                name="dimension"
                variant="outlined"
                onChange={handleOnDimensionChange}
                value={dimensionValue}
              >
                <MenuItem key={-1} value="<clear>">
                  {"<clear>"}
                </MenuItem>
                {allDimensions.map((item) => (
                  <MenuItem key={item.key} value={item.key}>
                    {" "}
                    {item.label}{" "}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-end" sx={{ p: 1 }}>
          {/* Generate report button */}
          <Grid item xs={6} sm={3} md={3} sx={{ pr: 3 }}>
            <Button
              variant="contained"
              onClick={handleOnGenerateReport}
              className="download-button"
            >
              Generate/Update Report
            </Button>
          </Grid>
        </Grid>

        <hr></hr>

        {isRportTableActive && (
          <>
            <Grid container>
              <Grid item xs={8} sm={12} md={12}>
                <DataGrid
                  rows={reportRows}
                  columns={reportCols}
                  getRowId={(row) => row.metric}
                  hideFooterPagination={true}
                  style={{ height: "50vh" }}
                  sx={{
                    "&.MuiDataGrid-root": {
                      whiteSpace: "normal !important",
                      wordWrap: "break-word !important",
                      border: "none",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                      outline: "none !important",
                    },
                    // "&.MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
                    //   outline: "none !important",
                    // },
                    "& .MuiDataGrid-cell": {
                      whiteSpace: "normal !important",
                      wordWrap: "break-word !important",
                      "&:first-of-type": {
                        borderRight: "1px solid gray",
                      },
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      borderBottom: "1px solid gray",
                    },
                  }}
                />
              </Grid>
              <Grid container justifyContent="flex-end">
                <Grid item xs={2} sm={3} md={4}>
                  <IconButton
                    color="primary"
                    onClick={(event) => {
                      handleOnClickPageChange(event, "previous");
                    }}
                  >
                    <ArrowCircleLeftIcon />
                  </IconButton>
                  Page {currentPage} of {totalPages}
                  <IconButton
                    color="primary"
                    onClick={(event) => {
                      handleOnClickPageChange(event, "next");
                    }}
                  >
                    <ArrowCircleRigthButton />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </ThemeProvider>
  );
}
