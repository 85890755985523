import { ActionTypes } from "../constants/ActionTypes";

export const setLoanApplications = (applications) => {
  return {
    type: ActionTypes.SET_APPLICATION,
    payload: applications,
  };
};

export const selectedApplication = (application) => {
  return {
    type: ActionTypes.SELECTED_APPLICATION,
    payload: application,
  };
};
export const selectedApplicationdropdown = (application) => {
  return {
    type: ActionTypes.ALL_APPLICATION_DROPDOWN,
    payload: application,
  };
};
export const loginDetails = (application) => {
  return {
    type: ActionTypes.LOGIN_DETAILS,
    payload: application,
  };
};
export const precisaBankDetails = (bankDetails) => {
  return {
    type: ActionTypes.BANK_DETAILS,
    payload: bankDetails,
  };
};
export const makerCheckAccess = (access) => {
  return {
    type: ActionTypes.MAKER_CHECKER,
    payload: access,
  };
};
export const currentUserDetails = (user) => {
  return {
    type: ActionTypes.CURRENT_USER,
    payload: user,
  };
};
