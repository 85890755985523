import * as React from "react";
import Box from "@mui/material/Box";
import Axios from "axios";
import { base_url1 } from "../../constants";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { TextField } from "@material-ui/core";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS, Legend, ArcElement } from "chart.js";
import { CategoryScale, registerables } from "chart.js";
import { pennyDrop } from "./../apiCalls/api";
import Button from "@mui/material/Button";
// for pie: ArcElement, not using any scales
ChartJS.register(Legend, ArcElement, CategoryScale, ...registerables);
export const Options = {
  layout: {
    padding: {
      top: 10,
    },
  },
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "right",
      align: "start",
      labels: {
        usePointStyle: true, // set this to true
        boxWidth: 1, // set the box width to the desired size
      },
    },
    datalabels: {
      color: "#000",
      anchor: "end",
      align: "start",
      font: {
        weight: "bold",
        size: "0",
      },
    },
  },
  barThickness: 10,
};
function Overview() {
  const [overViewDetails, setoverViewDetails] = React.useState({});
  const [irregularities, setirregularities] = React.useState([]);
  const [monthly, setMonthly] = React.useState([]);
  const [months, setMonths] = React.useState([]);
  const [average, setAverage] = React.useState([]);
  const [bgColor, setBgColor] = React.useState([]);
  const [borderColor, setBorderColor] = React.useState([]);
  const [inflow, setInflow] = React.useState([]);
  const [outflow, setOutflow] = React.useState([]);
  const reduxData = useSelector(
    (state) => state.applications?.loan_basic_info?.Loan_Application_id
  );
  const bankDetails = useSelector((state) => state.bankDetails);
  const getBankOverview = () => {
    const BankOverviewDetails = bankDetails.filter(
      (item) => item.report_type == "monthwiseSummary"
    );
    const Data1 = JSON.parse(
      BankOverviewDetails[0].report_result_json.replace(/\\/g, "")
    );
    setoverViewDetails(Data1.bank_account);
  };
  const getirregularities = () => {
    const data = bankDetails.filter(
      (item) => item.report_type == "irregularities"
    );
    const inputJson = JSON.parse(data[0].report_json.replace(/\\/g, ""));
    // Find the index of the object with key "files"
    const filesIndex = inputJson.findIndex((item) =>
      item.hasOwnProperty("files")
    );

    // Remove the object at the filesIndex
    if (filesIndex !== -1) {
      inputJson.splice(filesIndex, 1);
    }
    const outputArray = inputJson.map((item) => {
      if (item.transactions != undefined) {
        return {
          activity: item.activity,
          count: item.transactions.length,
        };
      } else {
        return {
          activity: item.activity,
          count: item.monthlyTransactions.length,
        };
      }
    });
    setirregularities(outputArray);
  };
  const getMonthlyAverageGraph = () => {
    const BankCashFlowDetails = bankDetails.filter(
      (item) => item.report_type == "cashflow"
    );
    const Data1 = JSON.parse(
      BankCashFlowDetails[0].report_json.replace(/\\/g, "")
    );
    Data1.monthly.splice(-4);
    setMonthly(Data1.monthly);
    const monthsArray = Data1.monthly.map((item) => item.month);
    const avgDailyBalanceArray = Data1.monthly.map(
      (item) => item.avgDailyBalance
    );
    setMonths(monthsArray);
    setAverage(avgDailyBalanceArray);
    var colorLength = Math.max(monthsArray.length);
    for (var i = 0; i < colorLength; i++) {
      setBgColor((prev) => [...prev, getRandomColor()]);
      setBorderColor((prev) => [...prev, "#fff"]);
    }
  };
  const monthlyApplicationGraph = {
    labels: months,
    datasets: [
      {
        data: average,
        backgroundColor: "#3f48cc",
        borderColor: "#3f48cc",
        borderWidth: 1,
      },
    ],
  };
  const pennyDrop1 = async () => {
    try {
      const result = await pennyDrop(
        reduxData,
        overViewDetails.IFSC,
        overViewDetails.accountNumber
      );
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const getInflowOutflow = () => {
    const BankTransactionDetails = bankDetails.filter(
      (item) => item.report_type == "transactionSummary"
    );
    const Data1 = JSON.parse(
      BankTransactionDetails[0].report_json.replace(/\\/g, "")
    );
    const Deposits = Data1.dataDeposits;
    const Withdrawals = Data1.dataWithdrawals;
    const transactions1 = Deposits.Deposits.data.flatMap(
      (item) => item.transactions
    );
    const transactions2 = Withdrawals.Withdrawals.data.flatMap(
      (item) => item.transactions
    );
    const mergedTransactions = transactions1.reduce((merged, transaction) => {
      const index = merged.findIndex(
        (item) => item.categories === transaction.categories
      );

      if (index !== -1) {
        // If category exists, add the amount to the existing item
        merged[index].amount += transaction.amount;
      } else {
        // If category doesn't exist, add a new item
        merged.push(transaction);
      }

      return merged;
    }, []);
    const mergedTransactions1 = transactions2.reduce((merged, transaction) => {
      const index = merged.findIndex(
        (item) => item.categories === transaction.categories
      );

      if (index !== -1) {
        // If category exists, add the amount to the existing item
        merged[index].amount += transaction.amount;
      } else {
        // If category doesn't exist, add a new item
        merged.push(transaction);
      }

      return merged;
    }, []);
    setInflow(mergedTransactions);
    setOutflow(mergedTransactions1);
    const mergeArray = [...transactions1, ...transactions2];
  };

  useEffect(() => {
    async function getData() {
      getBankOverview();
      // getirregularities();
      // getMonthlyAverageGraph();
      // getInflowOutflow();
    }
    getData();
  }, [reduxData, bankDetails]);
  function getRandomColor() {
    let r = Math.floor(Math.random() * 256);
    let g = Math.floor(Math.random() * 256);
    let b = Math.floor(Math.random() * 256);
    let a = (Math.random() * (0.7 - 0.4) + 0.4).toFixed(2);

    let color = `rgba(${r}, ${g}, ${b}, ${a})`;
    return color;
  }
  return (
    <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
      <div style={{ marginTop: "-4%" }}>
        <Grid item xs={12} sx={{ pt: 4 }}>
          <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
            <Grid item xs={12} sm={12} md={12}>
              {overViewDetails != undefined ? (
                <div
                  className="overview-box"
                  style={{ height: "56vh", overflow: "auto" }}
                >
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    style={{
                      width: "100%",
                    }}
                  >
                    <Button
                      sx={{ textTransform: "none" }}
                      variant="contained"
                      className="download-button"
                      onClick={pennyDrop1}
                    >
                      Penny Drop
                    </Button>
                  </Grid>
                  <Typography
                    sx={{ m: 3, flex: 1 }}
                    component="div"
                    style={{ fontWeight: "800", textAlign: "center" }}
                  >
                    Bank Account Details
                  </Typography>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    m={1}
                    sx={{
                      display: "flex",
                      color: "#263238",
                    }}
                  >
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography>Bank Name</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography>{overViewDetails.bankName}</Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    m={1}
                    sx={{
                      display: "flex",
                      color: "#263238",
                    }}
                  >
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography>IFSC & MICR Code</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography>IFSC: {overViewDetails.IFSC}</Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    m={1}
                    sx={{
                      display: "flex",
                      color: "#263238",
                    }}
                  >
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography>Current Balance</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography>
                        {overViewDetails && overViewDetails.currentBalance
                          ?  Number(overViewDetails.currentBalance).toLocaleString()
                          : "N/A"}{" "}
                        {/* Provide a fallback value */}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    m={1}
                    sx={{
                      display: "flex",
                      color: "#263238",
                    }}
                  >
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography>Account No.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography>{overViewDetails.accountNumber}</Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    m={1}
                    sx={{
                      display: "flex",
                      color: "#263238",
                    }}
                  >
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography>Account type</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography>{overViewDetails.accountType}</Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    m={1}
                    sx={{
                      display: "flex",
                      color: "#263238",
                    }}
                  >
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography>Account Holder Name</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography>{overViewDetails.accountName}</Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    m={1}
                    sx={{
                      display: "flex",
                      color: "#263238",
                    }}
                  >
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography>PAN</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography>{overViewDetails.pan}</Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    m={1}
                    sx={{
                      display: "flex",
                      color: "#263238",
                    }}
                  >
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography>Account Holder Address</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography>{overViewDetails.address}</Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    m={1}
                    sx={{
                      display: "flex",
                      color: "#263238",
                    }}
                  >
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography>Contact Details</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography>{overViewDetails.mobile}</Typography>
                    </Grid>
                  </Grid>
                </div>
              ) : null}
            </Grid>
            {/* <Grid
              item
              xs={6}
              sm={6}
              md={6}
              style={{ paddingLeft: "0% !important" }}
            >
              <div
                className="overview-box"
                style={{ height: "56vh", overflow: "auto" }}
              >
                <Typography
                  sx={{ m: 3, flex: 1 }}
                  component="div"
                  style={{ fontWeight: "800", textAlign: "center" }}
                >
                  Irregularities
                </Typography>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  m={1}
                  sx={{
                    display: "flex",
                    color: "#263238",
                  }}
                >
                  <Grid item xs={12} sm={6} md={6} mt={1}>
                    <Typography
                      sx={{ mr: 4, flex: 1 }}
                      component="div"
                      style={{ fontWeight: "800" }}
                    >
                      Activity
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} m={1}>
                    <Typography
                      sx={{ mr: 4, flex: 1 }}
                      component="div"
                      style={{ fontWeight: "800" }}
                    >
                      Incidences
                    </Typography>
                  </Grid>
                </Grid>

                {irregularities.map((subList, index) => (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      m={1}
                      sx={{
                        display: "flex",
                        color: "#263238",
                      }}
                    >
                      {Object.entries(subList).map(([key, value]) => (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          sx={{
                            display: "flex",
                            color: "#263238",
                          }}
                        >
                          <Grid key={key} item xs={12} sm={12} md={12}>
                            <Typography>{value}</Typography>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                ))}
              </div>
            </Grid> */}
          </Grid>
          {/* <Grid item xs={12} sx={{ pt: 4 }}>
            <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
              <Grid item xs={6} sm={6} md={6}>
                <div className="overview-box">
                  <Typography
                    component="div"
                    sx={{
                      m: 2,
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    Monthly Average Balance
                  </Typography>
                  <CardContent
                    sx={{
                      display: "grid",
                      alignContent: "center",
                      justifyContent: "center",
                      padding: "0px 8px",
                      height: "40vh !important",
                    }}
                  >
                    <Bar
                      data={monthlyApplicationGraph}
                      options={Options}
                      plugins={[ChartDataLabels]}
                      className="monthlyApps"
                    />
                  </CardContent>
                </div>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <div
                  className="overview-box"
                  style={{
                    height: "50vh",
                    overflow: "auto",
                    background: "#3f48cc",
                    color: "#ffffff",
                  }}
                >
                  <Typography
                    sx={{ m: 3, flex: 1 }}
                    component="div"
                    style={{ fontWeight: "800", textAlign: "center" }}
                  >
                    Outflow
                  </Typography>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    m={1}
                    sx={{
                      display: "flex",
                      color: "#263238",
                    }}
                  >
                    <Grid item xs={12} sm={8} md={8} mt={1}>
                      <Typography
                        sx={{ mr: 4, flex: 1 }}
                        component="div"
                        style={{ fontWeight: "800", color: "#ffffff" }}
                      >
                        Category
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} m={1}>
                      <Typography
                        sx={{ mr: 4, flex: 1 }}
                        component="div"
                        style={{ fontWeight: "800", color: "#ffffff" }}
                      >
                        Amount
                      </Typography>
                    </Grid>
                  </Grid>
                  {outflow.map((subList) => (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        m={1}
                        sx={{
                          display: "flex",
                          color: "#263238",
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          sx={{
                            display: "flex",
                            color: "#263238",
                          }}
                        >
                          <Grid item xs={12} sm={8} md={8}>
                            <Typography style={{ color: "#ffffff" }}>
                              {subList.categories}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography style={{ color: "#ffffff" }}>
                              {subList.amount}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  ))}
                </div>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <div
                  className="overview-box"
                  style={{
                    background: "#3f48cc",
                    color: "#ffffff",
                    height: "50vh",
                    overflow: "auto",
                  }}
                >
                  <Typography
                    sx={{ m: 3, flex: 1 }}
                    component="div"
                    style={{ fontWeight: "800", textAlign: "center" }}
                  >
                    Inflow
                  </Typography>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    m={1}
                    sx={{
                      display: "flex",
                      color: "#263238",
                    }}
                  >
                    <Grid item xs={12} sm={8} md={8} mt={1}>
                      <Typography
                        sx={{ mr: 4, flex: 1 }}
                        component="div"
                        style={{ fontWeight: "800", color: "#ffffff" }}
                      >
                        Category
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} m={1}>
                      <Typography
                        sx={{ mr: 4, flex: 1 }}
                        component="div"
                        style={{ fontWeight: "800", color: "#ffffff" }}
                      >
                        Amount
                      </Typography>
                    </Grid>
                  </Grid>
                  {inflow.map((subList) => (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        m={1}
                        sx={{
                          display: "flex",
                          color: "#263238",
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          sx={{
                            display: "flex",
                            color: "#263238",
                          }}
                        >
                          <Grid item xs={12} sm={8} md={8}>
                            <Typography style={{ color: "#ffffff" }}>
                              {subList.categories}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography style={{ color: "#ffffff" }}>
                              {subList.amount}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  ))}
                </div>
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      </div>
    </Box>
  );
}

export default function overview() {
  return <Overview />;
}
