import React, { useState } from "react";
import {
  Box,
  Container,
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
  TextField,
  Divider,
  Link,
} from "@material-ui/core";
import Alert from "@mui/material/Alert";
import "./../styles.css";
import Typography from "@mui/material/Typography";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import { base_url1 } from "../../constants";
import List from "@mui/material/List";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Button, ListItem, ListItemText } from "@mui/material";
import {
  GridRenderCellParams,
  GridColDef,
  useGridApiContext,
} from "@mui/x-data-grid";
import {
  mainListItems2,
  secondaryListItems,
  externalMenu,
} from "../Side_Nav/listItems";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TaskIcon from "@mui/icons-material/Task";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoanApplications,
  selectedApplication,
  selectedApplicationdropdown,
} from "../../redux/actions/LoanActions";
import LogoutIcon from "@mui/icons-material/Logout";
import LinearProgress from "@mui/material/LinearProgress";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import SessionModal from "../Common/SessionModal";
import { sendBulkMessages } from "../apiCalls/api";
import AppHeader from "../Common/header";
import Footer from "../Common/footer";
import DeleteIcon from "@mui/icons-material/Delete";
import { notificationServices } from "../Side_Nav/listItems";
export const theme = createTheme({
  typography: {
    // fontFamily: "Times New Roman",
  },
});

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export default function NotificationService() {
  const navigate = useNavigate();
  const [selectedApplications, setDetails] = React.useState();
  const [errorMessage, seterrorMessage] = React.useState("");
  const [isAPILoading, setisAPILoading] = React.useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [templateDescription, setTemplateDescription] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [amount, setAmount] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [amountError, setAmountError] = useState("");
  const nameRegex = /^[a-zA-Z\s]+$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^\d{12}$/;
  const amountRegex = /^\d+$/;
  const [users, setUsers] = useState([]);
  const currentUser = useSelector((state) => state.currentUserDetails);
  const [templates] = useState([
    {
      value: "Gosree Offer",
      label: "Gosree Offer",
      description:
        "Welcome to Gosree Finance Limited. \n\nHumanising the experience of Finance! \n\nYou are pre-selected for an instant loan up to INR 150000 with Gosree Finance Limited. \n\nUsing this service you can effortlessly apply for loans for your business with just a few taps - quick, seamless and at your fingertips.",
    },
    // Add more templates as needed
  ]);

  const handleTemplateChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedTemplate(selectedValue);
    const selectedTemplate = templates.find(
      (template) => template.value === selectedValue
    );
    setTemplateDescription(selectedTemplate.description);
  };
  const dispatch = useDispatch();
  dispatch(selectedApplicationdropdown([selectedApplications]));
  const [open, setOpen] = React.useState(true);
  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    navigate("/login");
  };
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const sendWhatsappMessage = async () => {
    seterrorMessage(false);
    try {
      setisAPILoading(true);
      const result = await sendBulkMessages(rows, selectedTemplate);
      if (result.data?.response_data) {
        setisAPILoading(false);
        setUsers([]);
        setName("");
        setEmail("");
        setPhone("");
        setAmount("");
        setSelectedTemplate("");
        setTemplateDescription("");
      } else {
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setisAPILoading(false);
        setIsErrorModalOpen(true);
        console.error(error);
      } else {
        seterrorMessage(true);
      }
      setisAPILoading(false);
    }
  };
  const handleAddUser = () => {
    let isValid = true;

    if (!name.match(nameRegex)) {
      setNameError("Invalid name");
      isValid = false;
    } else {
      setNameError("");
    }

    if (!email.match(emailRegex)) {
      setEmailError("Invalid email");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!phone.match(phoneRegex)) {
      setPhoneError("Invalid phone number");
      isValid = false;
    } else {
      setPhoneError("");
    }

    if (!amount.match(amountRegex)) {
      setAmountError("Invalid amount");
      isValid = false;
    } else {
      setAmountError("");
    }

    if (isValid) {
      const newUser = { name, email, phone, amount };
      setUsers([...users, newUser]);
      setName("");
      setEmail("");
      setPhone("");
      setAmount("");
    }
  };
  const handleDelete = (email) => {
    const updatedUsers = users.filter((user) => user.email !== email);
    setUsers(updatedUsers);
  };
  const columns = [
    { field: "id", headerName: "Sno", width: 50 },
    { field: "name", headerName: "Name", width: 150 },
    { field: "email_id", headerName: "Email", width: 200 },
    { field: "phone_no", headerName: "Phone No", width: 120 },
    { field: "amount", headerName: "Amount", width: 100 },
    {
      field: "delete",
      headerName: "Delete",
      width: 80,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleDelete(params.row.email_id)}
          style={{ color: "#FF0000" }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const rows = users.map((user, index) => ({
    id: index + 1,
    name: user.name,
    email_id: user.email,
    phone_no: user.phone,
    amount: user.amount,
  }));
  return (
    <div>
      {" "}
      <ThemeProvider theme={responsiveFontSizes(theme)}>
        <div>
          {/* Your component content */}
          <SessionModal
            isOpen={isErrorModalOpen}
            onClose={handleCloseErrorModal}
          />
        </div>
        <Box sx={{ display: "flex", mb: 6 }}>
          <CssBaseline />
          <AppBar position="absolute" open={open}>
            <Toolbar
              sx={{
                pr: "24px", // keep right padding when drawer closed
              }}
            >
              <AppHeader
                open={open}
                toggleDrawer={toggleDrawer}
                title="Notification Services"
              />
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open}>
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                px: [1],
              }}
            >
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav">
              {notificationServices}
              <Divider sx={{ my: 1 }} />
              {/* {secondaryListItems} */}
            </List>
          </Drawer>
          <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
            {/* <img src={pic1} style={{ margin: "1%" }} /> */}
            <Container maxWidth align="center">
              {isAPILoading ? (
                <Grid item xs={12}>
                  <LinearProgress
                    sx={{
                      color: "#3f48cc",
                    }}
                    size={40}
                    thickness={4}
                  />
                </Grid>
              ) : null}
              <Grid item xs={12} sx={{ pt: 4 }}>
                <Grid>
                  <div style={{ width: "98%" }}>
                    <b>Whatsapp Blast</b>
                  </div>
                  {errorMessage ? (
                    <Alert severity="error">
                      Error sending whatsapp message.
                    </Alert>
                  ) : null}
                  <Grid container spacing={2}>
                    <Grid item xs={7} sx={{ mt: 4 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6">Add User</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            label="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            style={{ margin: "2%" }}
                            error={!!nameError}
                            helperText={nameError}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            variant="outlined"
                            fullWidth
                            style={{ margin: "2%" }}
                            error={!!emailError}
                            helperText={emailError}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            label="Phone No."
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            variant="outlined"
                            fullWidth
                            style={{ margin: "2%" }}
                            error={!!phoneError}
                            helperText={phoneError}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            label="Amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            variant="outlined"
                            fullWidth
                            style={{ margin: "2%" }}
                            error={!!amountError}
                            helperText={amountError}
                          />
                        </Grid>
                      </Grid>
                      <div style={{ marginTop: "2%" }}>
                        {users.length > 0 ? (
                          <>
                            <Typography variant="h6">User List:</Typography>
                            {/* <List>
                              {users.map((user, index) => (
                                <ListItem key={index}>
                                  <ListItemText
                                    primary={`Sno: ${index + 1}, Name: ${
                                      user.name
                                    }, Email: ${user.email}, Phone: ${
                                      user.phone
                                    }, Amount: ${user.amount}`}
                                  />
                                </ListItem>
                              ))}
                            </List> */}
                            <div style={{ height: "30vh", width: "100%" }}>
                              <DataGrid
                                rows={rows}
                                columns={columns}
                                pageSize={5}
                              />
                            </div>
                          </>
                        ) : null}
                      </div>
                      <Grid
                        item
                        xs={6}
                        sx={{ m: 4 }}
                        style={{ alignContent: "right" }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleAddUser}
                          fullWidth
                        >
                          Add User
                        </Button>
                      </Grid>
                    </Grid>

                    <Grid item xs={4} sx={{ m: 5 }}>
                      <Typography variant="h6">Select Template</Typography>
                      <TextField
                        label="Select Template"
                        select
                        value={selectedTemplate}
                        onChange={handleTemplateChange}
                        variant="outlined"
                        fullWidth
                        style={{ margin: "2%" }}
                      >
                        {templates.map((template) => (
                          <MenuItem
                            sx={{
                              fontFamily: "'Roboto Condensed', sans-serif",
                              "&:hover": {
                                borderLeft: "5px solid #0093fb",
                                borderRadius: 1,
                              },
                            }}
                            key={template.value}
                            value={template.value}
                          >
                            {template.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        label="Template Description"
                        value={templateDescription}
                        variant="outlined"
                        fullWidth
                        style={{ margin: "2%" }}
                        multiline
                        rows={12}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                      <Grid
                        item
                        xs={6}
                        sx={{ m: 4 }}
                        style={{ alignContent: "right" }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={users.length == 0 || selectedTemplate == ""}
                          onClick={sendWhatsappMessage}
                          fullWidth
                        >
                          Send
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
        <Footer />
      </ThemeProvider>
    </div>
  );
}
