import React, { useState } from "react";
import {
  Box,
  Container,
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
  TextField,
  Divider,
  Link,
} from "@material-ui/core";
import "./../styles.css";
import Typography from "@mui/material/Typography";
import Axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import { base_url1 } from "../../constants";
import List from "@mui/material/List";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  GridRenderCellParams,
  GridColDef,
  useGridApiContext,
} from "@mui/x-data-grid";
import { mainListItems2, secondaryListItems } from "../Side_Nav/listItems";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TaskIcon from "@mui/icons-material/Task";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import {
  setLoanApplications,
  selectedApplication,
  selectedApplicationdropdown,
} from "../../redux/actions/LoanActions";
import LogoutIcon from "@mui/icons-material/Logout";
import DLASettings from "./dlaSettings";
import CMSSettings from "./cmsSettings";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Footer from "./../Common/footer";
export const theme = createTheme({
  typography: {
    // fontFamily: "Times New Roman",
  },
});

const drawerWidth = 240;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));
export default function Settings() {
  const navigate = useNavigate();
  const [selectedApplications, setDetails] = React.useState();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    const email = sessionStorage.getItem("Email");
    // if (email != "gosreefinance@corestrat.ai") {
    //   navigate("/login");
    // }
  }, []);
  dispatch(selectedApplicationdropdown([selectedApplications]));
  const [open, setOpen] = React.useState(true);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const toggleDrawer = () => {
    setOpen(!open);
  };
  return (
    <div>
      {" "}
      <ThemeProvider theme={responsiveFontSizes(theme)}>
        <Box sx={{ display: "flex", mb: 6 }}>
          <CssBaseline />
          <AppBar position="absolute">
            <Toolbar
              sx={{
                pr: "24px", // keep right padding when drawer closed
              }}
            >
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                Settings
              </Typography>
              <Tooltip title="Logout " sx={{ mt: -1 }}>
                <IconButton color="inherit" href="/login">
                  <LogoutIcon />
                </IconButton>
              </Tooltip>
            </Toolbar>
          </AppBar>
          <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="DLA Settings"
                  {...a11yProps(0)}
                  style={{ textTransform: "capitalize" }}
                />
                <Tab
                  label="CMS Settings"
                  {...a11yProps(1)}
                  style={{ textTransform: "capitalize" }}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <DLASettings />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <CMSSettings />
            </TabPanel>
          </Box>
          {/* <DLASettings /> */}
        </Box>
        <Footer />
      </ThemeProvider>
    </div>
  );
}
