import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  verificationSideNav,
  secondaryListItems,
  disbursementSideNav,
} from "../Side_Nav/listItems";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TextField } from "@material-ui/core";
import LoanDetails from "../Common/basicLoanDetails";
import { useSelector } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import Tooltip from "@mui/material/Tooltip";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { base_url1 } from "../../constants";
import Axios from "axios";
import AppHeader from "./../Common/header";
import Footer from "./../Common/footer";
import { useNavigate } from "react-router-dom";
import SessionModal from "./../Common/SessionModal";
import {
  createModifyGenerateDisbursement,
  getGenerateDisbursement,
} from "./../apiCalls/api";
import { useState } from "react";
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();
const handleChangeInput = (event) => {};
function CollateralVerification() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [open1, setOpen1] = React.useState(false);
  const reduxData = useSelector(
    (state) => state.applications?.loan_basic_info?.Loan_Application_id
  );
  const handleClick = () => {
    setOpen1(!open1);
  };
  const [updateDisbursementList, setupdateDisbursementList] = React.useState(
    {}
  );
  const navigate = useNavigate();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [beneficiary_details, setbeneficiary_details] = React.useState({});
  const [disbursement_release, setdisbursement_release] = React.useState({});
  const [disbursement_summary, setdisbursement_summary] = React.useState({});
  useEffect(() => {
    async function getData() {
      getGenerateData();
    }
    getData();
  }, [reduxData]);
  const beneficiaryDetailsChange = (e) => {
    setbeneficiary_details({
      ...beneficiary_details,
      [e.target.name]: e.target.value,
    });
  };
  const disbursementReleaseChange = (e) => {
    setdisbursement_release({
      ...disbursement_release,
      [e.target.name]: e.target.value,
    });
  };
  const disbursementSummaryChange = (e) => {
    setdisbursement_summary({
      ...disbursement_summary,
      [e.target.name]: e.target.value,
    });
  };
  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    navigate("/login");
  };
  const getGenerateData = async () => {
    try {
      const result = await getGenerateDisbursement(reduxData);
      if (result.data.data == "") {
        postGenerateData();
      } else {
        setupdateDisbursementList(result.data.data.beneficiary_details);
        setbeneficiary_details(result.data.data.beneficiary_details);
        setdisbursement_release(result.data.data.disbursement_release);
        setdisbursement_summary(result.data.data.disbursement_summary);
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const postGenerateData = async () => {
    const item = {
      id: 0,
      loan_id: reduxData,
      collateral_id: "",
      collateral_type: "",
      approved_loan_amount: "0",
      total_disbursable_amount: "0",
      current_disbursable_amount: "0",
      amount_disbursed_till_date: "0",
      undisbursed_amount: "0",
      percentage_disbursed: "0",
      tranche_number: "0",
      mode_of_disbursement: "Bank Transfer",
      disbursement_account: "",
      requested_amount: "0",
      down_payment: "0",
      beneficiary_name: "",
      bank_name: "",
      account_number: "",
      ifsc_code: "",
      micr_code: "",
      account_type: "",
    };
    try {
      const result = await createModifyGenerateDisbursement(item);
      if (result) {
        getGenerateData();
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const update = async () => {
    const item = {
      id: reduxData,
      loan_id: reduxData,
      collateral_id: disbursement_release.collateral_id,
      collateral_type: disbursement_release.collateral_type,
      approved_loan_amount: disbursement_release.approved_loan_amount,
      total_disbursable_amount: disbursement_release.total_disbursable_amount,
      current_disbursable_amount:
        disbursement_release.current_disbursable_amount,
      amount_disbursed_till_date:
        disbursement_release.amount_disbursed_till_date,
      undisbursed_amount: disbursement_release.undisbursed_amount,
      percentage_disbursed: disbursement_release.percentage_disbursed,
      tranche_number: disbursement_summary.tranche_number,
      mode_of_disbursement: disbursement_summary.mode_of_disbursement,
      disbursement_account: disbursement_summary.disbursement_account,
      requested_amount: disbursement_summary.requested_amount,
      down_payment: disbursement_summary.down_payment,
      beneficiary_name: beneficiary_details.beneficiary_name,
      bank_name: beneficiary_details.bank_name,
      account_number: beneficiary_details.account_number,
      ifsc_code: beneficiary_details.ifsc_code,
      micr_code: beneficiary_details.micr_code,
      account_type: beneficiary_details.account_type,
    };
    try {
      const result = await createModifyGenerateDisbursement(item);
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <div>
        {/* Your component content */}
        <SessionModal
          isOpen={isErrorModalOpen}
          onClose={handleCloseErrorModal}
        />
      </div>
      <Box sx={{ display: "flex", mb: 6 }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <AppHeader
              open={open}
              toggleDrawer={toggleDrawer}
              title="Generate Disbursement"
            />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {disbursementSideNav}
            <Divider sx={{ my: 1 }} />
            {/* {secondaryListItems} */}
          </List>
        </Drawer>
        <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
          <div style={{ marginTop: "1%" }}>
            <LoanDetails></LoanDetails>
            <div className="details-box">
              <Grid item xs={12} sx={{ pt: 4 }}>
                <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                  {disbursement_release != undefined ? (
                    <Grid item xs={12} sx={{ p: 4 }}>
                      <Grid container spacing={4} sx={{ pl: 1, pl: 1 }}>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            sx={{ flex: 1, pt: 2, pb: 2 }}
                            component="div"
                            style={{ fontWeight: "800", color: "#3f48cc" }}
                          >
                            Disbursement Release
                          </Typography>
                        </Grid>
                        {Object.entries(disbursement_release).map(
                          ([key, value]) => (
                            <>
                              {key != "id" && key != "loan_id" ? (
                                <Grid key={key} item xs={12} sm={6} md={6}>
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name={key}
                                    label={key
                                      .replace(/_/g, " ")
                                      .toLowerCase()
                                      .split(" ")
                                      .map(
                                        (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                      )
                                      .join(" ")}
                                    value={
                                      isNaN(value)
                                        ? value
                                        : Number(value).toLocaleString()
                                    }
                                    onChange={(e) => {
                                      const newValue = e.target.value.replace(
                                        /,/g,
                                        ""
                                      ); // Remove commas
                                      disbursementReleaseChange({
                                        target: {
                                          name: key,
                                          value: newValue,
                                        },
                                      });
                                    }}
                                  />
                                </Grid>
                              ) : null}{" "}
                            </>
                          )
                        )}
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
                <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                  {disbursement_summary != undefined ? (
                    <Grid item xs={12} sx={{ p: 4 }}>
                      <Grid container spacing={4} sx={{ pl: 1, pl: 1 }}>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            sx={{ flex: 1, pt: 2, pb: 2 }}
                            component="div"
                            style={{ fontWeight: "800", color: "#3f48cc" }}
                          >
                            Disbursement Summary
                          </Typography>
                        </Grid>
                        {Object.entries(disbursement_summary).map(
                          ([key, value]) => (
                            <>
                              {key != "id" && key != "loan_id" ? (
                                <Grid key={key} item xs={12} sm={6} md={6}>
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name={key}
                                    label={key
                                      .replace(/_/g, " ")
                                      .toLowerCase()
                                      .split(" ")
                                      .map(
                                        (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                      )
                                      .join(" ")}
                                    value={value}
                                    onChange={(e) =>
                                      disbursementSummaryChange(e)
                                    }
                                  />
                                </Grid>
                              ) : null}
                            </>
                          )
                        )}
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
                <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                  {beneficiary_details != undefined ? (
                    <Grid item xs={12} sx={{ p: 4 }}>
                      <Grid container spacing={4} sx={{ pl: 1, pl: 1 }}>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            sx={{ flex: 1, pt: 2, pb: 2 }}
                            component="div"
                            style={{ fontWeight: "800", color: "#3f48cc" }}
                          >
                            Beneficiary Details
                          </Typography>
                        </Grid>
                        {Object.entries(beneficiary_details).map(
                          ([key, value]) => (
                            <Grid key={key} item xs={12} sm={6} md={6}>
                              <TextField
                                fullWidth
                                type="text"
                                variant="outlined"
                                name={key}
                                label={key
                                  .replace(/_/g, " ")
                                  .toLowerCase()
                                  .split(" ")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(" ")}
                                value={value}
                                onChange={(e) => beneficiaryDetailsChange(e)}
                              />
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Grid>
                  ) : null}
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    style={{
                      width: "100%",
                    }}
                  >
                    <Button
                      sx={{ textTransform: "none" }}
                      variant="contained"
                      className="download-button"
                      onClick={update}
                    >
                      Update
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </Box>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}

export default function collateralverification() {
  return <CollateralVerification />;
}
