import React, { useState } from "react";
import {
  Box,
  Container,
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
  TextField,
  Divider,
  Link,
} from "@material-ui/core";
import "./../styles.css";
import Typography from "@mui/material/Typography";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import { base_url1 } from "../../constants";
import List from "@mui/material/List";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  GridRenderCellParams,
  GridColDef,
  useGridApiContext,
} from "@mui/x-data-grid";
import {
  mainListItems2,
  secondaryListItems,
  externalMenu,
} from "../Side_Nav/listItems";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TaskIcon from "@mui/icons-material/Task";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoanApplications,
  selectedApplication,
  selectedApplicationdropdown,
} from "../../redux/actions/LoanActions";
import LogoutIcon from "@mui/icons-material/Logout";
import LinearProgress from "@mui/material/LinearProgress";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import SessionModal from "./../Common/SessionModal";
import { getAllApplications, getApplication } from "./../apiCalls/api";
import AppHeader from "./../Common/header";
import Footer from "./../Common/footer";
export const theme = createTheme({
  typography: {
    // fontFamily: "Times New Roman",
  },
});

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const SelectArray = [
  { value: 0, title: "Approved" },
  { value: 1, title: "Declined" },
  { value: 2, title: "pre-approved" },
];
const settings = ["Profile", "Account", "Dashboard", "Logout"];
function SelectEditInputCell(props) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = async (event) => {
    await apiRef.current.setEditCellValue({
      id,
      field,
      value: event.target.value,
    });
    apiRef.current.stopCellEditMode({ id, field });
  };

  return (
    <Select
      value={value}
      onChange={handleChange}
      size="small"
      sx={{ width: "100%" }}
      autoFocus
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      {SelectArray.map((i) => {
        return <MenuItem value={i.title}>{i.title}</MenuItem>;
      })}
    </Select>
  );
}
export default function Details() {
  const navigate = useNavigate();
  const [rows, setRows] = useStateWithCallbackLazy([]);
  const [data, setData] = useStateWithCallbackLazy([]);
  const [selectedApplications, setDetails] = React.useState();
  const [isAPILoading, setisAPILoading] = React.useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const currentUser = useSelector((state) => state.currentUserDetails);
  const dispatch = useDispatch();
  useEffect(() => {
    async function getData() {
      setisAPILoading(true);
      try {
        const result = await getAllApplications();
        if (result.data.data) {
          const sortedData = [...result.data.data].sort((a, b) => a.id - b.id);
          setData(sortedData);
          setRows(sortedData);
          dispatch(setLoanApplications(sortedData));
          setDetails(filteringDataToSelect(sortedData));
          setisAPILoading(false);
        } else {
          // if there is no data in the database
          setRows([]);
          setData([]);
          setisAPILoading(false);
        }
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setisAPILoading(false);
          setIsErrorModalOpen(true);
          console.error(error);
        }
        setisAPILoading(false);
      }
    }
    getData();
  }, []);
  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    navigate("/login");
  };
  const filteringDataToSelect = (data) => {
    return data.map((filteredItem) => {
      return {
        label: filteredItem.loan_reference_number,
        value: filteredItem.Loan_Application_id,
      };
    });
  };
  dispatch(selectedApplicationdropdown([selectedApplications]));
  const [open, setOpen] = React.useState(true);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const columns = [
    {
      field: "id",
      headerName: "S.No.",
      flex: 1,
      maxWidth: 50,
      type: "number",
      sortable: true,
    },
    {
      field: "loan_reference_number",
      headerName: "Reference Number",
      flex: 1,
      minWidth: 50,
    },
    {
      field: "prosper_loan_acct_no",
      headerName: "L Code",
      flex: 1,
      minWidth: 50,
    },
    {
      field: "loan_category",
      headerName: "Loan Category",
      flex: 1,
      minWidth: 50,
    },
    {
      field: "loan_product_name",
      headerName: "Loan Type",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "customer_name",
      headerName: "Customer Name",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "loan_amount",
      headerName: "Requested Loan Amount",
      flex: 1,
      minWidth: 90,
      renderCell: (params) => {
        const formattedLoanAmount = isNaN(params.row.loan_amount)
          ? params.row.loan_amount
          : Number(params.row.loan_amount).toLocaleString();
        return <div>{formattedLoanAmount}</div>;
      },
    },
    {
      field: "create_date",
      headerName: "Created Date",
      flex: 1,
      minWidth: 90,
      type: "date", // Set the column type to "date" for proper sorting
      valueFormatter: (params) => {
        // Parse the date string into a JavaScript Date object
        const date = new Date(params.value);

        // Convert the date to IST
        const istDate = convertGMTtoIST(date); // Assuming 'convertGMTtoIST' returns a Date object
        return istDate.toLocaleString(); // Format the date as needed
      },
      sortComparator: (a, b, columnParams) => {
        // Custom date sorting logic here
        const dateA = new Date(a);
        const dateB = new Date(b);

        // You can implement your own custom date comparison logic here
        // For example, to sort in descending order, you can return -(dateA - dateB)
        // For ascending order, return dateA - dateB
        return dateA - dateB; // Sort in ascending order
      },
    },
    {
      // editable: true,
      field: "loan_status",
      flex: 1,
      minWidth: 90,
      headerName: "Loan Status",
      renderCell: (params) => {
        return (
          <div
            className={`approved-${
              params.row.loan_status
                ? params.row.loan_status.toString().toLowerCase()
                : ""
            }`}
          >
            {params.row.loan_status}
          </div>
        );
      },
      // type: "singleSelect",
      // valueOptions: ["Approved", "Declined", "pre-approved"],
      // renderCell: (params) => {
      //   return (
      //     <div style={{ width: "100%", display: "contents" }}>
      //       <span style={{ width: "80%" }}>
      //         {params.row.loan_status_description}{" "}
      //       </span>
      //       <IconButton
      //         style={{ width: "20%" }}
      //         edge="end"
      //         color="inherit"
      //         aria-label="close"
      //       >
      //         <ExpandMoreIcon />
      //       </IconButton>
      //     </div>
      //   );
      // },
    },
  ];
  const Role = sessionStorage.getItem("Role");
  const actionColumn = [
    {
      field: "action",
      headerName: "Actions",
      width: 90,
      flex: 1,
      minWidth: 90,
      renderCell: (params) => {
        if (
          currentUser.role_name != "external_agency" &&
          currentUser.role_name != "lawyer" &&
          currentUser.role_name != "branch_manager"
        ) {
          if (
            params.row.loan_status != null &&
            (params.row.loan_status.toLowerCase() == "approved in principle" ||
              params.row.loan_status.toLowerCase() == "approve in principle" ||
              params.row.loan_status.toLowerCase() == "decline" ||
              params.row.loan_status.toLowerCase() == "referred" ||
              params.row.loan_status.toLowerCase() == "approved" ||
              params.row.loan_status.toLowerCase() == "processing" ||
              params.row.loan_status.toLowerCase() == "declined" ||
              params.row.loan_status.toLowerCase() == "denied" ||
              params.row.loan_status.toLowerCase() == "in-principle approval" ||
              params.row.loan_status.toLowerCase() == "in-review" ||
              params.row.loan_status.toLowerCase() == "approved_in_principle")
          ) {
            return (
              <div className="cellAction">
                <div className="viewButton">
                  <Tooltip title="Details " sx={{ mt: -1 }}>
                    <IconButton
                      onClick={(e) => routeChange(e, params.row, "details")}
                      style={{ color: "#3f48cc", minWidth: "10px", maxWidth: "26px"  }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                {Role == 1 ? (
                  <>
                    {currentUser.role_name != "credit_head" &&
                    (params.row.loan_status.toLowerCase() == "decline" ||
                      params.row.loan_status.toLowerCase() == "declined" ||
                      params.row.loan_status.toLowerCase() == "denied") ? (
                      <div className="viewButton">
                        <Tooltip title="Initiate Verification " sx={{ mt: -1 }}>
                          <IconButton style={{ color: "grey", minWidth: "10px", maxWidth: "26px"  }}>
                            <TaskIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    ) : (
                      <>
                        <div className="viewButton">
                          <Tooltip
                            title="Initiate Verification "
                            sx={{ mt: -1 }}
                          >
                            <IconButton
                              onClick={(e) =>
                                routeChange(e, params.row, "verification")
                              }
                              style={{ color: "#3f48cc", minWidth: "10px", maxWidth: "26px"  }}
                            >
                              <TaskIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                        {params.row.loan_status != " " &&
                        params.row.user_decision == "APPROVED" &&
                        params.row.loan_status.toLowerCase() == "approved" ? (
                          <div className="viewButton">
                            <Tooltip title="Disbursement " sx={{ mt: -1 }}>
                              <IconButton
                                onClick={(e) =>
                                  routeChange(e, params.row, "disbursement")
                                }
                                style={{ color: "#3f48cc", minWidth: "10px", maxWidth: "26px"  }}
                              >
                                <CreditScoreIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        ) : null}
                      </>
                    )}
                  </>
                ) : null}
              </div>
            );
          } else {
            return (
              <div className="cellAction">
                <div className="viewButton">
                  <Tooltip title="Details " sx={{ mt: -1 }}>
                    <IconButton
                      onClick={(e) => routeChange(e, params.row, "details")}
                      style={{ color: "#3f48cc" , minWidth: "10px", maxWidth: "26px" }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                {Role == 1 ? (
                  <>
                    {currentUser.role_name == "credit_head" ? (
                      <div className="viewButton">
                        <Tooltip title="Initiate Verification " sx={{ mt: -1 }}>
                          <IconButton
                            onClick={(e) =>
                              routeChange(e, params.row, "verification")
                            }
                            style={{ color: "#3f48cc", minWidth: "10px", maxWidth: "26px"  }}
                          >
                            <TaskIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    ) : (
                      <div className="viewButton">
                        <Tooltip title="Initiate Verification " sx={{ mt: -1 }}>
                          <span>
                            <IconButton
                              //disabled={true}
                              // onClick={(e) =>
                              //   routeChange(e, params.row, "verification")
                              // }
                              style={{ color: "grey", minWidth: "10px", maxWidth: "26px"  }}
                            >
                              <TaskIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </div>
                    )}
                  </>
                ) : null}
                {params.row.loan_status != " " &&
                params.row.user_decision == "APPROVED" &&
                params.row.loan_status.toLowerCase() == "approved" ? (
                  <div className="viewButton">
                    <Tooltip title="Disbursement " sx={{ mt: -1 }}>
                      <IconButton
                        onClick={(e) =>
                          routeChange(e, params.row, "disbursement")
                        }
                        style={{ color: "#3f48cc" , minWidth: "10px", maxWidth: "26px" }}
                      >
                        <CreditScoreIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : null}
              </div>
            );
          }
        }
        if (currentUser.role_name == "branch_manager") {
          return (
            <div className="cellAction">
              <div className="viewButton">
                <Tooltip title="Details " sx={{ mt: -1 }}>
                  <IconButton
                    onClick={(e) => routeChange(e, params.row, "details")}
                    style={{ color: "#3f48cc", minWidth: "10px", maxWidth: "26px"  }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          );
        }
        if (currentUser.role_name == "external_agency") {
          return (
            <div className="cellAction">
              <div className="viewButton">
                <Tooltip title="Details " sx={{ mt: -1 }}>
                  <IconButton
                    onClick={(e) => routeChange(e, params.row, "details")}
                    style={{ color: "#3f48cc", minWidth: "10px", maxWidth: "26px"  }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <div className="viewButton">
                <Tooltip title="Verification " sx={{ mt: -1 }}>
                  <IconButton
                    onClick={(e) =>
                      routeChange(e, params.row, "branchverification")
                    }
                    style={{ color: "#3f48cc", minWidth: "10px", maxWidth: "26px"  }}
                  >
                    <TaskIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          );
        }
        if (currentUser.role_name == "lawyer") {
          return (
            <div className="cellAction">
              <div className="viewButton">
                <Tooltip title="Details " sx={{ mt: -1 }}>
                  <IconButton
                    onClick={(e) => routeChange(e, params.row, "details")}
                    style={{ color: "#3f48cc", minWidth: "10px", maxWidth: "26px"  }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <div className="viewButton">
                <Tooltip title="Verification " sx={{ mt: -1 }}>
                  <IconButton
                    onClick={(e) =>
                      routeChange(e, params.row, "lawyerverification")
                    }
                    style={{ color: "#3f48cc", minWidth: "10px", maxWidth: "26px"  }}
                  >
                    <TaskIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          );
        }
      },
    },
  ];
  const routeChange = async (e, selectedApplication1, action) => {
    setisAPILoading(true);
    try {
      const result = await getApplication(
        selectedApplication1.Loan_Application_id
      );
      setisAPILoading(false);
      if (result.data.data) {
        dispatch(selectedApplication(result.data.data[0]));
        if (action == "details") {
          navigate("/detailview");
        }
        if (action == "disbursement") {
          navigate("/checklist");
        }
        if (action == "verification") {
          navigate("/verification");
        }
        if (action == "branchverification") {
          navigate("/documentVerification");
        }
        if (action == "lawyerverification") {
          navigate("/legalVerification");
        }
      } else {
        dispatch(selectedApplication([]));
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setisAPILoading(false);
        setIsErrorModalOpen(true);
      }
      setisAPILoading(false);
    }
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) => rows.find((row) => row.id === id));
    setRowSelected(selectedRowsData);
  };
  const convertGMTtoIST = (gmtDate) => {
    const options = {
      weekday: "short", // Short weekday name (e.g., Mon)
      day: "2-digit", // 2-digit day of the month (e.g., 07)
      month: "short", // Short month name (e.g., Aug)
      year: "numeric", // 4-digit year (e.g., 2023)
      hour: "2-digit", // 2-digit hour (e.g., 19)
      minute: "2-digit", // 2-digit minute (e.g., 18)
      timeZoneName: "short", // Short time zone name (e.g., IST)
    };

    const date = new Date(gmtDate);
    const istOffset = 5.5 * 60 * 60 * 1000; // India Standard Time offset from GMT in milliseconds (IST is 5 hours and 30 minutes ahead of GMT)
    const istTime = new Date(date.getTime());
    return istTime.toLocaleString("en-IN", options);
  };
  return (
    <div>
      {" "}
      <ThemeProvider theme={responsiveFontSizes(theme)}>
        <div>
          {/* Your component content */}
          <SessionModal
            isOpen={isErrorModalOpen}
            onClose={handleCloseErrorModal}
          />
        </div>
        <Box sx={{ display: "flex", mb: 6 }}>
          <CssBaseline />
          <AppBar position="absolute" open={open}>
            <Toolbar
              sx={{
                pr: "24px", // keep right padding when drawer closed
              }}
            >
              <AppHeader
                open={open}
                toggleDrawer={toggleDrawer}
                title="Case Review"
              />
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open}>
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                px: [1],
              }}
            >
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            {currentUser.role_name != "external_agency" &&
            currentUser.role_name != "lawyer" ? (
              <List component="nav">
                {mainListItems2}
                <Divider sx={{ my: 1 }} />
                {/* {secondaryListItems} */}
              </List>
            ) : (
              <List component="nav">
                {externalMenu}
                <Divider sx={{ my: 1 }} />
                {/* {secondaryListItems} */}
              </List>
            )}
          </Drawer>
          <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
            {/* <img src={pic1} style={{ margin: "1%" }} /> */}
            <Container maxWidth align="center">
              {isAPILoading ? (
                <Grid item xs={12}>
                  <LinearProgress
                    sx={{
                      color: "#3f48cc",
                    }}
                    size={40}
                    thickness={4}
                  />
                </Grid>
              ) : null}
              <Grid item xs={12} sx={{ pt: 4 }}>
                <Grid>
                  <div style={{ width: "98%" }}>
                    <b>Loan Application Details</b>
                    <div className="table-container">
                      <DataGrid
                        rows={data}
                        columns={columns.concat(actionColumn)}
                        slots={{ toolbar: GridToolbar }}
                        density="comfortable"
                        onSelectionModelChange={(ids) =>
                          onRowsSelectionHandler(ids)
                        }
                        // onCellClick={handleEvent}
                        disableSelectionOnClick={true} // disable selecting whole row
                        sx={{
                          "&.MuiDataGrid-root": {
                            border: "none ",
                          },
                          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                            outline: "none !important",
                          },
                          "&.MuiDataGrid-root .MuiDataGrid-columnHeader--sortable":
                            {
                              outline: "none !important",
                            },
                          "&.MuiDataGrid-root .MuiDataGrid-cell": {
                            whiteSpace: "normal !important",
                            wordWrap: "break-word !important",
                          },
                        }}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
        <Footer />
      </ThemeProvider>
    </div>
  );
}
