import * as React from "react";
import Box from "@mui/material/Box";
import Axios from "axios";
import { base_url1 } from "../../constants";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

function Category() {
  const [BankInflows, setBankInflows] = React.useState([]);
  const [BankOutFlows, setBankOutFlows] = React.useState([]);
  const reduxData = useSelector(
    (state) => state.applications?.loan_basic_info?.Loan_Application_id
  );
  const columns = [
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      maxWidth: 90,
    },
    {
      field: "particular",
      headerName: "Particular",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "cheque_number",
      headerName: "Cheque Number/Ref No",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "counterparty",
      headerName: "Counterparty",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "balance",
      headerName: "Balance(Rs)",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "computedBalance",
      headerName: "Computed Balance(Rs)",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "categories",
      headerName: "Categories",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "tags",
      headerName: "Tags",
      flex: 1,
      minWidth: 90,
    },
  ];
  const bankDetails = useSelector((state) => state.bankDetails);
  useEffect(() => {
    async function getData() {
      const BankTransactionDetails = bankDetails.filter(
        (item) => item.report_type == "categories"
      );
      const Data1 = JSON.parse(
        BankTransactionDetails[0].report_json.replace(/\\/g, "")
      );
      const Inflows = Data1.inflows;
      const Outflows = Data1.outflows;
      const transactions1 = Inflows.flatMap((item) => item.transactions);
      const transactions2 = Outflows.flatMap((item) => item.transactions);
      //   const mergeArray = [...transactions1, ...transactions2];
      //   setBankTransactions(mergeArray);
      setBankInflows(transactions1);
      setBankOutFlows(transactions2);
    }
    getData();
  }, [reduxData, bankDetails]);
  return (
    <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              Inflows
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <DataGrid
                rows={BankInflows}
                columns={columns}
                slots={{ toolbar: GridToolbar }}
                density="comfortable"
                sx={{
                  "&.MuiDataGrid-root": {
                    border: "none ",
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
                    outline: "none !important",
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-cell": {
                    whiteSpace: "normal !important",
                    wordWrap: "break-word !important",
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Outflows
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <DataGrid
                rows={BankOutFlows}
                columns={columns}
                slots={{ toolbar: GridToolbar }}
                density="comfortable"
                sx={{
                  "&.MuiDataGrid-root": {
                    border: "none ",
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
                    outline: "none !important",
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-cell": {
                    whiteSpace: "normal !important",
                    wordWrap: "break-word !important",
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </Box>
  );
}

export default function category() {
  return <Category />;
}
