import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf";
import Grid from "@mui/material/Grid";

const PdfViewerPopup = ({ open, onClose, pdfFileName, fileName }) => {
  const dialogStyle = {
    width: "70vw !important",
    height: "95vh !important",
    maxWidth: "70vw !important",
  };
  const [documentBase64, setDocumentBase64] = useState("");
  const isPDF = fileName.toLowerCase().endsWith(".pdf");
  const [pageNumber, setPageNumber] = React.useState(1);
  const [numPages, setNumPages] = React.useState(null);

  useEffect(() => {
    if (isPDF) {
      convertBase64toPDF();
    } else {
      displayImage();
    }
  }, [pdfFileName, isPDF]);
  const displayImage = () => {
    // Assuming pdfFileName contains Base64-encoded image data
    setDocumentBase64(
      `data:image/${fileName.split(".").pop()};base64,${pdfFileName}`
    );
  };
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber((prevPage) => prevPage - 1);
    }
  };

  const convertBase64toPDF = () => {
    const base64Content = pdfFileName;
    const base64Data = base64Content;
    const pdfData = atob(base64Data);
    const uint8Array = new Uint8Array(pdfData.length);
    for (let i = 0; i < pdfData.length; i++) {
      uint8Array[i] = pdfData.charCodeAt(i);
    }
    const pdfBlob = new Blob([uint8Array], { type: "application/pdf" });
    const pdfUrl = URL.createObjectURL(pdfBlob);
    setDocumentBase64(pdfUrl);

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: dialogStyle }}>
      <DialogTitle>Document View</DialogTitle>
      <DialogContent>
        {isPDF ? (
          // // Display the PDF content here
          // <Document
          //   file={documentBase64}
          //   onLoadSuccess={({ numPages }) =>
          //     console.log(`Document loaded with ${numPages} pages`)
          //   }
          //   options={{ workerSrc: "/pdf.worker.js" }}
          // >
          //   <Page pageNumber={1} />
          // </Document>
          <Grid item xs={12} md={6} sx={{ border: "1px solid #000000", mt: 1 }}>
            <div
              style={{
                height: "100%",
                width: "100%",
                overflow: "auto",
              }}
            >
              <div style={{ height: "100%", width: "100%" }}>
                <Document
                  style={{ height: "100%", width: "100%" }}
                  file={documentBase64}
                  onLoadSuccess={onDocumentLoadSuccess}
                  options={{ workerSrc: "/pdf.worker.js" }}
                >
                  <Page pageNumber={pageNumber} />
                </Document>
              </div>
              {numPages && (
                <p>
                  Page {pageNumber} of {numPages}
                </p>
              )}
            </div>
            {numPages && (
              <Grid container>
                <Grid item xs={12} md={9}>
                  <div>
                    <Button
                      sx={{ textTransform: "capitalize" }}
                      onClick={handlePrevPage}
                      disabled={pageNumber === 1}
                    >
                      Previous Page
                    </Button>
                    <Button
                      sx={{ textTransform: "capitalize" }}
                      onClick={handleNextPage}
                      disabled={pageNumber === numPages}
                    >
                      Next Page
                    </Button>
                  </div>
                </Grid>
              </Grid>
            )}
          </Grid>
        ) : (
          // Display the image content here (replace with your image viewer)
          <img
            src={documentBase64}
            alt="Image Preview"
            style={{ maxWidth: "100%" }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PdfViewerPopup;
