import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  mainListItems,
  secondaryListItems,
  mainListItemsIndividual,
} from "../Side_Nav/listItems";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TextField } from "@material-ui/core";
import LoanDetails from "../Common/basicLoanDetails";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Axios from "axios";
import { base_url1 } from "../../constants";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import Tooltip from "@mui/material/Tooltip";
import { useRef } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AppHeader from "./../Common/header";
import SessionModal from "./../Common/SessionModal";
import { useState } from "react";
import {
  uploadCMSDocuments,
  getCMSDocuments,
  getGSTInformation,
  getDownloadUrl
} from "./../apiCalls/api";
import Footer from "./../Common/footer";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();
const handleChangeInput = (event) => {};
function GST() {
  const navigate = useNavigate();
  const [gstInformation, setGST] = React.useState();
  const [documentList, setdocumentList] = React.useState([]);
  const [filteredDocuments, setFilteredDocuments] = React.useState([]);
  const [filterValue, setFilterValue] = React.useState("GSTN");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [downloadUrl, setDownloadUrl] = React.useState();
  const reduxData = useSelector(
    (state) => state.applications?.loan_basic_info?.Loan_Application_id
  );
  const currentUser = useSelector((state) => state.currentUserDetails);
  const loanType = useSelector(
    (state) => state.applications?.loan_basic_info?.loan_type
  );
  useEffect(() => {
    async function getData() {
      try {
        const result = await getGSTInformation(reduxData);
        if (result.data.data.length != 0) {
          setGST(result.data.data);
        } else {
          setGST([]);
        }
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setIsErrorModalOpen(true);
          console.error(error);
        }
      }
      // getting data from database
      try {
        const result = await getCMSDocuments(reduxData);
        if (result.data.data.length != 0) {
          setdocumentList(result.data.data);
          const filteredList = result.data.data.filter((item) => {
            return item.DocumentType.startsWith(filterValue);
          });
          setFilteredDocuments(filteredList);
        } else {
          setdocumentList([]);
          setFilteredDocuments([]);
        }
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setIsErrorModalOpen(true);
          console.error(error);
        }
      }
    }
    getData();
  }, [reduxData]);
  const columns = [
    {
      field: "Filename",
      headerName: "File Name",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "cms_upload",
      headerName: "Uploaded from Credit Ops",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "DocumentType",
      headerName: "Document Type",
      flex: 1,
      minWidth: 90,
    },
  ];
  const actionColumn = [
    {
      field: "action",
      headerName: "Actions",
      width: 90,
      flex: 1,
      minWidth: 90,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div className="viewButton">
              <Tooltip title="Download File" sx={{ mt: -1 }}>
                <IconButton
                  onClick={(e) => downloadSingleFile(params.row)}
                  style={{ color: "#3f48cc" }}
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="View File" sx={{ mt: -1 }}>
                <IconButton
                  onClick={(e) => handleViewClick(params.row)}
                  style={{ color: "#3f48cc" }}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];
  const downloadSingleFile = async (e) => {
    try {
      const result = await getDownloadUrl(
        e.Filepath
      );
      setDownloadUrl(result.data.response_data.download_url);
      window.open(result.data.response_data.download_url, "_blank");
    }catch (error) {
      if (error.message) {
        console.error("Error:", error);
      }
    }  
  };
  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    navigate("/login");
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const inputRef = useRef(null);
  const viewDocument = async () => {
    const filteredList = documentList.filter((item) => {
      return item.DocumentType.startsWith(filterValue);
    });
    downloadFile(filteredList);

    // window.open(bankDetails.bank_file_name_with_path, "_blank");
  };
  const downloadFile = async (filteredList) => {
    try {
        for (const item of filteredList) {
            try {
                const result = await getDownloadUrl(item.Filepath);
                const downloadUrl = result.data.response_data.download_url;
                setDownloadUrl(downloadUrl);
                window.open(result.data.response_data.download_url, "_blank");
            } catch (error) {
                if (error.message) {
                  console.error("Error:", error);
                }
            }
        }
    } catch (error) {
        console.error("Error:", error);
    }
  };
  const handleViewClick = (e) => {
    const blobUrl = e.Filepath;
    viewFile(blobUrl);
  };
  const viewFile = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;

    const isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    if (isChrome) {
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.target = "_blank";
      anchor.click();
    }
  };

  const fileCheck = (file) => {
    const fileType = file.type;
    const maxFilesize = 15 * 1024 * 1024;
  
    if (file.size > maxFilesize) {
      return 'File size exceeds the limit (15MB).';
    } else if (!allowedFileTypes.includes(fileType)) {
      return 'Invalid File Type. Only JPEG, PNG and PDF allowed.';
    } else {
      return null; // No error
    }
  };

  const onFileInputChange = async (event) => {
    const selectedFile = (event.target.files[0]);
    const error = fileCheck(selectedFile);
    
    if (error) {
      setError(error);
      event.target.value = '';
      alert(error);
    } else {
      const loanApplicationId = reduxData;
      let data = null;
      data = new FormData();
      data.append("LoanApplicationId", loanApplicationId);
      data.append("GSTN1", event.target.files[0]);
      data.append("document_comment", "Testing");
      try {
        const documents = await uploadCMSDocuments(data);

        const result = await getCMSDocuments(reduxData);
        if (result.data.data.length != 0) {
          setdocumentList(result.data.data);
          const filteredList = result.data.data.filter((item) => {
            return item.DocumentType.startsWith(filterValue);
          });
          setFilteredDocuments(filteredList);
        } else {
          setdocumentList([]);
          setFilteredDocuments([]);
        }
      } catch (error) {
        if (error.message === "AxiosError: Request failed with status code 401") {
          setIsErrorModalOpen(true);
          console.error(error);
        }
      }
    }
  };

  const fileUpload = () => {
    inputRef.current.click();
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <div>
        {/* Your component content */}
        <SessionModal
          isOpen={isErrorModalOpen}
          onClose={handleCloseErrorModal}
        />
      </div>
      <Box sx={{ display: "flex", mb: 6 }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <AppHeader open={open} toggleDrawer={toggleDrawer} title="GST" />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          {loanType == "Individual" ? (
            <List component="nav">
              {mainListItemsIndividual}
              <Divider />
              {currentUser.role_name === "credit_head" ||
              currentUser.role_name === "credit_ops" ? (
                <>{secondaryListItems}</>
              ) : null}
            </List>
          ) : (
            <List component="nav">
              {mainListItems}
              <Divider />
              {currentUser.role_name === "credit_head" ||
              currentUser.role_name === "credit_ops" ? (
                <>{secondaryListItems}</>
              ) : null}
            </List>
          )}
        </Drawer>
        <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
          <div style={{ marginTop: "1%" }}>
            <LoanDetails></LoanDetails>
            <Grid
              item
              xs={12}
              md={2}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              style={{
                width: "100%",
              }}
            >
              <Button
                sx={{ textTransform: "none" }}
                variant="contained"
                className="download-button"
                onClick={viewDocument}
              >
                Download Documents
              </Button>
              <Button
                sx={{ textTransform: "none" }}
                variant="contained"
                className="download-button"
                onClick={fileUpload}
              >
                Upload Document
                <input
                  hidden
                  ref={inputRef}
                  type="file"
                  onChange={onFileInputChange}
                />
              </Button>
            </Grid>
            {gstInformation != undefined && gstInformation.length > 0 ? (
              <div className="details-box">
                <Grid item xs={12} sx={{ pt: 4 }}>
                  {gstInformation.map((subList, index) => (
                    <Grid
                      container
                      spacing={4}
                      sx={{ pl: 1, pr: 1 }}
                      key={index}
                    >
                      <Grid item xs={12} sm={12} md={12} mt={3}>
                        <Typography
                          sx={{ mr: 4, flex: 1, pt: 2, pb: 2 }}
                          component="div"
                          style={{ fontWeight: "800", color: "#3f48cc" }}
                        >
                          GST Documentation
                        </Typography>
                      </Grid>
                      {Object.entries(subList).map(([key, value]) => (
                        <Grid key={key} item xs={12} sm={6} md={6} mt={3}>
                          <TextField
                            fullWidth
                            type="text"
                            variant="outlined"
                            name="estimatedValue"
                            label={key
                              .replace(/_/g, " ")
                              .toLowerCase()
                              .split(" ")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ")}
                            value={
                              isNaN(value)
                                ? value
                                : Number(value).toLocaleString()
                            }
                          />
                        </Grid>
                      ))}
                    </Grid>
                  ))}
                </Grid>
              </div>
            ) : null}
            {filteredDocuments.length > 0 ? (
              <div className="details-box">
                <Grid item xs={12} sx={{ pt: 4 }}>
                  <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
                    {/* <img src={pic1} style={{ margin: "1%" }} /> */}
                    <Container maxWidth align="center">
                      <Grid item xs={12} sx={{ pt: 4 }}>
                        <Grid>
                          <div style={{ width: "98%" }}>
                            <b>List of Documents</b>
                            <div className="table-container1">
                              <DataGrid
                                rows={filteredDocuments}
                                columns={columns.concat(actionColumn)}
                                getRowId={(row) => row.ApplicantDocumentId}
                                density="comfortable"
                                // onCellClick={handleEvent}
                                disableSelectionOnClick={true} // disable selecting whole row
                                sx={{
                                  "&.MuiDataGrid-root": {
                                    border: "none ",
                                  },
                                  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within":
                                    {
                                      outline: "none !important",
                                    },
                                  "&.MuiDataGrid-root .MuiDataGrid-columnHeader--sortable":
                                    {
                                      outline: "none !important",
                                    },
                                  "&.MuiDataGrid-root .MuiDataGrid-cell": {
                                    whiteSpace: "normal !important",
                                    wordWrap: "break-word !important",
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Container>
                  </Box>
                </Grid>
              </div>
            ) : null}
          </div>
        </Box>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}

export default function Gst() {
  return <GST />;
}
