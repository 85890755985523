import { combineReducers } from "redux";
import {
  applicationReducer,
  selectedApplicationReducer,
  ApplicationdropdownReducer,
  LoginDetailsReducer,
  bankDetailsReducer,
  makerCheckerReducer,
  currentUserDetailsReducer,
} from "./LoanReducer";
const reducers = combineReducers({
  allApplications: applicationReducer,
  applications: selectedApplicationReducer,
  applicationsdropdown: ApplicationdropdownReducer,
  loginDetails: LoginDetailsReducer,
  bankDetails: bankDetailsReducer,
  makerCheckAccess: makerCheckerReducer,
  currentUserDetails: currentUserDetailsReducer,
});
export default reducers;
