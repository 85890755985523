import * as React from "react";
import Box from "@mui/material/Box";
import Axios from "axios";
import { base_url1 } from "../../constants";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

function CashFlow() {
  const [Monthly, setMonthly] = React.useState([]);
  const [Quartarly, setQuartarly] = React.useState([]);
  const reduxData = useSelector(
    (state) => state.applications?.loan_basic_info?.Loan_Application_id
  );
  const columns = [
    {
      field: "month",
      headerName: "Month",
      flex: 1,
      maxWidth: 190,
    },
    {
      field: "inflow",
      headerName: "Inflow",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "outflow",
      headerName: "Outflow",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "netCashFlow",
      headerName: "Net Cash Flow",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "avgDailyBalance",
      headerName: "Monthly Average Balance",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "inflowTransactionCount",
      headerName: "Inflow Transaction Count",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "outflowTransactionCount",
      headerName: "Outflow Transaction Count",
      flex: 1,
      minWidth: 90,
    },
  ];
  const bankDetails = useSelector((state) => state.bankDetails);
  useEffect(() => {
    async function getData() {
      const BankCashFlowDetails = bankDetails.filter(
        (item) => item.report_type == "monthwiseSummary"
      );
      const Data1 = JSON.parse(
        BankCashFlowDetails[0].report_result_json.replace(/\\/g, "")
      );
    }
    getData();
  }, [reduxData]);
  return (
    <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
      <div>
        <div className="details-box">
          <DataGrid
            rows={Monthly}
            columns={columns}
            slots={{ toolbar: GridToolbar }}
            getRowId={(row) => row.month}
            density="comfortable"
            sx={{
              "&.MuiDataGrid-root": {
                border: "none ",
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "&.MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
                outline: "none !important",
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell": {
                whiteSpace: "normal !important",
                wordWrap: "break-word !important",
              },
            }}
          />
        </div>
        <div className="details-box">
          <DataGrid
            rows={Quartarly}
            columns={columns}
            slots={{ toolbar: GridToolbar }}
            getRowId={(row) => row.month}
            density="comfortable"
            sx={{
              "&.MuiDataGrid-root": {
                border: "none ",
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "&.MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
                outline: "none !important",
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell": {
                whiteSpace: "normal !important",
                wordWrap: "break-word !important",
              },
            }}
          />
        </div>
      </div>
    </Box>
  );
}

export default function cashFlow() {
  return <CashFlow />;
}
