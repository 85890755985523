import React from "react";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

// Creating a Dialog (functionality is the same as pop up and modal)
export const CustomDialog = ({ isOpen, handleClose, children }) => {
  return (
    <>
      <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleClose}>
        <DialogActions>
          <IconButton
            aria-label="delete"
            onClick={handleClose}
            sx={{
              ":hover": { color: "#0093db", backgroundColor: "transparent" },
              color: "#000000",
              position: "absolute",
              top: "5px",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </>
  );
};

// Defining the property that needed to pass when called
CustomDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};
