import React from "react";
import Modal from "react-modal";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Typography, Button } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import pic from "./../../assets/authentication.svg";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const SessionModal = ({ isOpen, onClose }) => {
  const handleClose = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "./login";
  };
  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
    >
      <DialogContent>
        <img
          src={pic}
          style={{
            height: "15vh",
            width: "97%",
            margin: "2%",
          }}
        />
        <Typography
          sx={{ mr: 4, flex: 1 }}
          component="div"
          style={{ fontWeight: "800", textAlign: "center" }}
        >
          Session Expired.
        </Typography>
        <Typography
          sx={{ mr: 4, flex: 1 }}
          component="div"
          style={{ textAlign: "center" }}
        >
          Your Session has been timed out. Please log in again.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className="download-button">
          Ok
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default SessionModal;
