import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  verificationSideNav,
  secondaryListItems,
  disbursementSideNav,
} from "../Side_Nav/listItems";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TextField } from "@material-ui/core";
import LoanDetails from "../Common/basicLoanDetails";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import VerifiedIcon from "@mui/icons-material/Verified";
import LogoutIcon from "@mui/icons-material/Logout";
import Tooltip from "@mui/material/Tooltip";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SummarizeIcon from "@mui/icons-material/Summarize";
import PeopleIcon from "@mui/icons-material/People";
import ApartmentIcon from "@mui/icons-material/Apartment";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import Checkbox from "@mui/material/Checkbox";
import FeedIcon from "@mui/icons-material/Feed";
import GradingIcon from "@mui/icons-material/Grading";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import PrintIcon from "@mui/icons-material/Print";
import AppHeader from "./../Common/header";
import Footer from "./../Common/footer";
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();
const handleChangeInput = (event) => {};
function CollateralVerification() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [open1, setOpen1] = React.useState(false);

  const handleClick = () => {
    setOpen1(!open1);
  };
  const [technicalCollateral, setFinancialDetails] = React.useState([
    {
      collateralID: "34PROP0000000003210",
      collateralType: "Residential",
      approved: "67886868",
      totaldisbursement: "78345686",
      current: "500000",
      tilldate: "80000",
      undisbursedamount: "89989",
      percentagedisbursement: "80%",
      trancheno: "2",
      date: "05/09/2024",
      mode: "",
      account: "HDFC",
    },
  ]);
  const [legalCollateral, setlegalDetails] = React.useState([
    {
      collateralID: "34PROP0000000003210",
      absolute: "Yes",
      originalLatest: "Yes",
      immediate: "Yes",
      type: "Simple Mortgage",
      nature: "Full Ownership",
      validmortage: "Yes",
    },
  ]);
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex", mb: 6 }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <AppHeader
              open={open}
              toggleDrawer={toggleDrawer}
              title="Request Disbursement"
            />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {disbursementSideNav}
            <Divider sx={{ my: 1 }} />
            {/* {secondaryListItems} */}
          </List>
        </Drawer>
        <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
          <div style={{ marginTop: "1%" }}>
            <LoanDetails></LoanDetails>
            <div className="details-box">
              <Grid item xs={12} sx={{ pt: 4 }}>
                <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography
                      sx={{ pt: 2, pb: 2 }}
                      component="div"
                      style={{ fontWeight: "800", color: "#3f48cc" }}
                    >
                      Disbursement Request
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} mt={2}>
                    <Typography
                      sx={{ mr: 4, flex: 1 }}
                      component="div"
                      style={{ fontWeight: "800" }}
                    >
                      Collateral ID:
                    </Typography>
                    <Typography sx={{ mr: 4, flex: 1 }} component="div">
                      {technicalCollateral.map((data) => (
                        <p>{data.collateralID}</p>
                      ))}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} mt={2}>
                    {technicalCollateral.map((data) => (
                      <TextField
                        fullWidth
                        type="text"
                        variant="outlined"
                        name="collateralType"
                        label="collateral Type:"
                        value={data.collateralType}
                        onChange={(event) => handleChangeInput(event)}
                      />
                    ))}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} mt={2}>
                    {technicalCollateral.map((data) => (
                      <TextField
                        fullWidth
                        type="text"
                        variant="outlined"
                        name="approved"
                        label="Apporved Loan Amount:"
                        value={data.approved}
                        onChange={(event) => handleChangeInput(event)}
                      />
                    ))}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} mt={2}>
                    {technicalCollateral.map((data) => (
                      <TextField
                        fullWidth
                        type="text"
                        variant="outlined"
                        name="totaldisbursement"
                        label="Total Disbursable Amount:"
                        value={data.totaldisbursement}
                        onChange={(event) => handleChangeInput(event)}
                      />
                    ))}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} mt={2}>
                    {technicalCollateral.map((data) => (
                      <TextField
                        fullWidth
                        type="text"
                        variant="outlined"
                        name="current"
                        label="Current Disbursable Amount:"
                        value={data.current}
                        onChange={(event) => handleChangeInput(event)}
                      />
                    ))}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} mt={2}>
                    {technicalCollateral.map((data) => (
                      <TextField
                        fullWidth
                        type="text"
                        variant="outlined"
                        name="tilldate"
                        label="Amount Disbursed till date:"
                        value={data.tilldate}
                        onChange={(event) => handleChangeInput(event)}
                      />
                    ))}
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} mt={2}>
                    {technicalCollateral.map((data) => (
                      <TextField
                        fullWidth
                        type="text"
                        variant="outlined"
                        name="undisbursedamount"
                        label="Undisbursed Amount:"
                        value={data.undisbursedamount}
                        onChange={(event) => handleChangeInput(event)}
                      />
                    ))}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} mt={2}>
                    {technicalCollateral.map((data) => (
                      <TextField
                        fullWidth
                        type="text"
                        variant="outlined"
                        name="percentagedisbursement"
                        label="% Disbursed:"
                        value={data.percentagedisbursement}
                        onChange={(event) => handleChangeInput(event)}
                      />
                    ))}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} mt={2}>
                    {technicalCollateral.map((data) => (
                      <TextField
                        fullWidth
                        type="text"
                        variant="outlined"
                        name="trancheno"
                        label="Tranche number:"
                        value={data.trancheno}
                        onChange={(event) => handleChangeInput(event)}
                      />
                    ))}
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} mt={2}>
                    {technicalCollateral.map((data) => (
                      <TextField
                        fullWidth
                        type="text"
                        variant="outlined"
                        name="date"
                        label="Disbursement Date:"
                        value={data.date}
                        onChange={(event) => handleChangeInput(event)}
                      />
                    ))}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} mt={2}>
                    {technicalCollateral.map((data) => (
                      <TextField
                        fullWidth
                        type="text"
                        variant="outlined"
                        name="mode"
                        label="Mode of Disbursement:"
                        value={data.mode}
                        onChange={(event) => handleChangeInput(event)}
                      />
                    ))}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} mt={2}>
                    {technicalCollateral.map((data) => (
                      <TextField
                        fullWidth
                        type="text"
                        variant="outlined"
                        name="account"
                        label="Disbursement Account:"
                        value={data.account}
                        onChange={(event) => handleChangeInput(event)}
                      />
                    ))}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} mt={2}>
                    <table className="mracTable table table-bordered text-center table-sm">
                      <thead>
                        <tr>
                          <th scope="col">S. No</th>
                          <th scope="col">Borrower's Name</th>
                          <th scope="col">Bank Name</th>
                          <th scope="col">Branch Code</th>
                          <th scope="col">Account Number</th>
                          <th scope="col">Account Type</th>
                          <th scope="col">Disbursed Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>
                            <textarea></textarea>
                          </td>
                          <td>
                            <select
                              name="verification"
                              id="verification"
                              className="form-control input100"
                            >
                              <option defaultValue value="office">
                                HDFC Bank
                              </option>
                              <option value="residential">ICICI Bank</option>
                              <option value="tele">Axis Bank</option>
                              <option value="tele">Kotak Mahindra Bank</option>
                              <option value="tele">Yes Bank</option>
                              <option value="tele">State Bank of India</option>
                              <option value="tele">Bank of Baroda</option>
                            </select>
                          </td>
                          <td>
                            <textarea></textarea>
                          </td>
                          <td>
                            <textarea></textarea>
                          </td>
                          <td>
                            <select
                              name="channel"
                              id="channel"
                              className="form-control input100"
                            >
                              <option defaultValue value="external">
                                Current Account
                              </option>
                              <option value="internal">Savings Account</option>
                            </select>
                          </td>
                          <td>
                            <textarea></textarea>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </Box>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}

export default function collateralverification() {
  return <CollateralVerification />;
}
