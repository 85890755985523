import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  verificationSideNavLawyer,
  secondaryListItems,
  mainListItemsIndividual,
} from "../Side_Nav/listItems";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TextField } from "@material-ui/core";
import LoanDetails from "../Common/basicLoanDetails";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Axios from "axios";
import { base_url1 } from "../../constants";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import AppHeader from "./../Common/header";
import SessionModal from "./../Common/SessionModal";
import { useState } from "react";
import { postLegalCollateral } from "./../apiCalls/api";
import Footer from "./../Common/footer";
const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();
const handleChangeInput = (event) => {};
function LegalVerification() {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [fileData, setfileData] = useState("");
  const [error, setError] = useState(null);
  const allowedFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];

  const reduxData = useSelector(
    (state) => state.applications?.loan_basic_info?.Loan_Application_id
  );
  const [legalVerification, setlegalVerification] = useState();
  const handleInputChange = (e) => {
    setlegalVerification({
      ...legalVerification,
      [e.target.name]: e.target.value,
    });
  };
  const loanType = useSelector(
    (state) => state.applications?.loan_basic_info?.loan_type
  );
  const fileUpload = () => {
    inputRef.current.click();
  };
  const submitLegalCollateral = async () => {
    console.log(legalVerification);
    let data = null;
    data = new FormData();
    data.append("legal_doc", fileData);
    data.append("loan_id", legalVerification.loan_id);
    data.append(
      "absolute_clear_marketable",
      legalVerification.absolute_clear_marketable
    );
    data.append("mortgage_type", legalVerification.mortgage_type);
    data.append("owner_nature", legalVerification.owner_nature);
    data.append("valid_mortgage", legalVerification.valid_mortgage);
    data.append(
      "prior_charges_against_property",
      legalVerification.prior_charges_against_property
    );
    data.append(
      "original_latest_title_deeds_verified",
      legalVerification.original_latest_title_deeds_verified
    );
    data.append("provision_applied", legalVerification.provision_applied);
    try {
      const result = await postLegalCollateral(data);
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  useEffect(() => {
    async function getData() {
      const data = {
        loan_id: reduxData,
        absolute_clear_marketable: "",
        mortgage_type: "",
        owner_nature: "",
        valid_mortgage: "",
        prior_charges_against_property: "",
        original_latest_title_deeds_verified: "",
        provision_applied: "",
      };
      setlegalVerification(data);
      //   try {
      //     const result = await getTechnicalVerification(reduxData);
      //     if (result.data.data.length != 0) {
      //       console.log("Results", result.data.data);
      //     } else {
      //     }
      //   } catch (error) {
      //     if (
      //       error.message === "AxiosError: Request failed with status code 401"
      //     ) {
      //       setIsErrorModalOpen(true);
      //       console.error(error);
      //     }
      //   }
      // getting data from database
    }
    getData();
  }, [reduxData]);

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    navigate("/login");
  };

  const fileCheck = (file) => {
    const fileType = file.type;
    const maxFilesize = 15 * 1024 * 1024;
  
    if (file.size > maxFilesize) {
      return 'File size exceeds the limit (15MB).';
    } else if (!allowedFileTypes.includes(fileType)) {
      return 'Invalid File Type. Only JPEG, PNG and PDF allowed.';
    } else {
      return null; // No error
    }
  };

  const onFileInputChange = async (event) => {
    const selectedFile = (event.target.files[0]);
    const error = fileCheck(selectedFile);
    
    if (error) {
      setError(error);
      event.target.value = '';
      alert(error);
    } else {
      setfileData(event.target.files[0]);
    }
  };
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <div>
        {/* Your component content */}
        <SessionModal
          isOpen={isErrorModalOpen}
          onClose={handleCloseErrorModal}
        />
      </div>
      <Box sx={{ display: "flex", mb: 6 }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <AppHeader
              open={open}
              toggleDrawer={toggleDrawer}
              title="Technical Verification"
            />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {verificationSideNavLawyer}
            <Divider sx={{ my: 1 }} />
            {/* {secondaryListItems} */}
          </List>
        </Drawer>
        <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
          <div style={{ marginTop: "1%" }}>
            <LoanDetails></LoanDetails>
            <div className="details-box">
              <Grid item xs={12} style={{ width: "92%" }}>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    sx={{ m: 4, flex: 1, pt: 2, pb: 2 }}
                    component="div"
                    style={{
                      fontWeight: "800",
                      color: "#3f48cc",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Legal Collateral
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  style={{
                    width: "100%",
                  }}
                >
                  <Button
                    sx={{ textTransform: "none" }}
                    variant="contained"
                    className="download-button"
                    onClick={fileUpload}
                  >
                    Upload Document
                    <input
                      hidden
                      ref={inputRef}
                      type="file"
                      onChange={onFileInputChange}
                    />
                  </Button>
                </Grid>
                <Grid container spacing={4} sx={{ pl: 1, pr: 1, m: 2 }}>
                  {legalVerification != undefined ? (
                    <>
                      {Object.entries(legalVerification).map(([key, value]) => (
                        <>
                          {key != "loan_id" ? (
                            <Grid key={key} item xs={12} sm={6} md={6}>
                              <TextField
                                fullWidth
                                type="text"
                                variant="outlined"
                                name={key}
                                label={key
                                  .replace(/_/g, " ")
                                  .toLowerCase()
                                  .split(" ")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(" ")}
                                value={value}
                                onChange={(e) => handleInputChange(e)}
                              />
                            </Grid>
                          ) : null}
                        </>
                      ))}
                    </>
                  ) : null}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                style={{
                  width: "100%",
                }}
              >
                <Button
                  sx={{ textTransform: "none" }}
                  variant="contained"
                  className="download-button"
                  onClick={submitLegalCollateral}
                >
                  Submit
                </Button>
              </Grid>
            </div>
          </div>
        </Box>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}

export default function legalVerification() {
  return <LegalVerification />;
}
