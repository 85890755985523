import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  verificationSideNav,
  secondaryListItemsDetails,
} from "../Side_Nav/listItems";
import Button from "@mui/material/Button";
import { TextField } from "@material-ui/core";
import LoanDetails from "../Common/basicLoanDetails";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import Select from "react-select";
import LinearProgress from "@mui/material/LinearProgress";
import AppHeader from "./../Common/header";
import { useNavigate } from "react-router-dom";
import SessionModal from "./../Common/SessionModal";
import {
  postFinalDecision,
  getFinalDecision,
  getEmail,
  sendRepaymentSchedule,
  modifyRepaymentSchedule,
  companyConfirmation,
} from "./../apiCalls/api";
import Footer from "./../Common/footer";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/en";
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();
const handleChangeInput = (event) => {};
function FinalDecision() {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [open1, setOpen1] = React.useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isAPILoading, setisAPILoading] = React.useState(false);
  const [maxLoanAmtCheck, setmaxLoanAmtCheck] = React.useState(false);
  const data = [
    {
      name: "disputed_property_reject_reason",
      value: 1,
      label: "Overvalued property (greater than 20%)",
    },
    {
      name: "disputed_property_reject_reason",
      value: 2,
      label: "Property location outside the bank policy",
    },
    {
      name: "disputed_property_reject_reason",
      value: 3,
      label: "Technical Mismatch",
    },
    {
      name: "disputed_property_reject_reason",
      value: 4,
      label: "Disputed Property",
    },
    {
      name: "disputed_property_reject_reason",
      value: 5,
      label: "Property not found",
    },
    {
      name: "disputed_property_reject_reason",
      value: 6,
      label: "Credit quality assessment doesn’t follow guidelines",
    },
    {
      name: "disputed_property_reject_reason",
      value: 7,
      label: "Others",
    },
  ];
  const data1 = [
    {
      value: 2,
      label: "APPROVED",
    },
    {
      value: 3,
      label: "DECLINED",
    },
  ];
  const repayment_frequency = [
    {
      value: 1,
      label: "ONETIME",
      isDisabled: true,
    },
    {
      value: 2,
      label: "ADHOC",
      isDisabled: true,
    },
    {
      value: 3,
      label: "INTRADAY",
      isDisabled: true,
    },
    {
      value: 4,
      label: "DAILY",
      isDisabled: true,
    },
    {
      value: 5,
      label: "WEEKLY",
      isDisabled: true,
    },
    {
      value: 6,
      label: "MONTHLY",
      isDisabled: false,
    },
    {
      value: 7,
      label: "QUARTERLY",
      isDisabled: true,
    },
    {
      value: 8,
      label: "SEMIANNUALLY",
      isDisabled: true,
    },
    {
      value: 9,
      label: "YEARLY",
      isDisabled: true,
    },
    {
      value: 10,
      label: "BIMONTHLY",
      isDisabled: true,
    },
  ];
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption1, setSelectedOption12] = useState(null);
  const [selectedDecision, setFinalDecision] = useState(null);
  const [finalDecision, setfinalDecision] = React.useState();
  const [maxLoanAmount, setmaxLoanAmount] = React.useState();
  const [startDate, setStartDate] = React.useState(new Date());
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [endDate, setendDate] = React.useState(null);
  const [userEmail, setuserEmail] = React.useState();
  const makerChecker = useSelector((state) => state.makerCheckAccess);
  const currentUser = useSelector((state) => state.currentUserDetails);
  // handle onChange event of the dropdown
  const handleChangeDropdownChange = (e) => {
    setSelectedOption(e);
    const updatedList = finalDecision;
    updatedList.disputed_property_reject_reason = e.label;
    setfinalDecision(updatedList);
  };
  const handlechangeRepayment = (e) => {
    setSelectedOption12(e);
    const updatedList = finalDecision;
    updatedList.payment_frequency = e.label;
    setfinalDecision(updatedList);
  };
  const handleChangeFinalDecision = (e) => {
    setFinalDecision(e);
    const updatedList1 = finalDecision;
    updatedList1.final_decision_loan_status = e.label;
    setfinalDecision(updatedList1);
  };
  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    navigate("/login");
  };
  const reduxData = useSelector(
    (state) => state.applications?.loan_basic_info?.Loan_Application_id
  );
  const basicInfo = useSelector((state) => state.applications?.loan_basic_info);
  const companyInfo = useSelector(
    (state) => state.applications?.company_details
  );
  const personalInfo = useSelector(
    (state) => state.applications?.loan_personal_info
  );
  function convertDateToSupportedFormat(dateString) {
    const dateObject = dayjs(dateString);

    // Example: Format the date for display
    const formattedDate = dateObject.format("YYYY-MM-DD HH:mm:ss.SSSSSS");
    return formattedDate;
  }
  useEffect(() => {
    async function getData() {
      setisAPILoading(true);
      try {
        const result = await getFinalDecision(reduxData);
        setmaxLoanAmount(result.data.maximum_loan_eligibility);
        if (result.data.data.length == 0) {
          const finalDecision1 = {
            id: "0",
            loan_id: reduxData,
            collateral_id: "",
            collateral_type: basicInfo.collatral_type,
            date_created: basicInfo.create_date,
            applicant_name: companyInfo.name_of_organization
              ? companyInfo.name_of_organization
              : personalInfo.Full_Name,
            loan_amount: basicInfo.loan_amount,
            approved_loan_amount: basicInfo.loan_amount,
            tenure: parseInt(result.data.tenure),
            interest_rate: result.data.interest_rate,
            asset_description: "",
            risk_description: "",
            mitigant_description: "",
            disputed_property_reject_reason: "",
            remark_description: "",
            final_decision_loan_status: "",
            role_name: currentUser.role_name,
            payment_frequency: "",
            processing_charges: "",
            processing_charges_amount: "",
            cheque_bounce_charges: "",
            cheque_swapping_charges: "",
            late_payment_charges: "",
            foreclosure_charges: "",
            cancellation_rebooking_reschedulement_charges: "",
            transaction_fees_other: "",
            advanced_emi_nos: "",
            advanced_emi_amount: "",
            start_date: result.data.start_date,
            end_date: result.data.end_date,
            emi_scheme: "",
            place_of_agreement: "",
            repayment_schedule_charges: "",
          };
          try {
            // const result1 = await postFinalDecision(finalDecision1);
            if (basicInfo.loan_status.toLowerCase() == "approved") {
              setFinalDecision({ value: 2, label: "APPROVED" });
            }
            if (
              basicInfo.loan_status.toLowerCase() == "decline" ||
              basicInfo.loan_status.toLowerCase() == "declined"
            ) {
              setFinalDecision({ value: 3, label: "DECLINED" });
            }
            // if (basicInfo.loan_status.toLowerCase() == "processing") {
            //   setFinalDecision({ value: 4, label: "PROCESSING" });
            // }
            // if (basicInfo.loan_status.toLowerCase() == "in-review") {
            //   setFinalDecision({ value: 5, label: "IN-REVIEW" });
            // }
            // if (basicInfo.loan_status.toLowerCase() == "referred") {
            //   setFinalDecision({ value: 6, label: "REFERRED" });
            // }
            // if (
            //   basicInfo.loan_status.toLowerCase() == "in-principle approval"
            // ) {
            //   setFinalDecision({
            //     value: 1,
            //     label: "IN-PRINCIPLE APPROVAL",
            //   });
            // }
            setfinalDecision(finalDecision1);
            setisAPILoading(false);
            if (
              result.data.start_date != "0000-00-00 00:00:00" &&
              result.data.start_date != ""
            ) {
              const startDate = dayjs(result.data.start_date);
              setSelectedDate(startDate);
            }
            if (
              result.data.end_date != "0000-00-00 00:00:00" &&
              result.data.end_date != ""
            ) {
              const endate = dayjs(result.data.end_date);
              setendDate(endate);
            }
          } catch (error) {
            if (
              error.message ===
              "AxiosError: Request failed with status code 401"
            ) {
              setisAPILoading(false);
              setIsErrorModalOpen(true);
              console.error(error);
            }
          }
          if (
            parseInt(result.data.maximum_loan_eligibility.replace(/,/g, "")) <
            parseInt(basicInfo.loan_amount)
          ) {
            setmaxLoanAmtCheck(true);
          } else {
            setmaxLoanAmtCheck(false);
          }
        } else {
          const decision = {
            advanced_emi_amount: result.data.data[0].advanced_emi_amount,
            advanced_emi_nos: result.data.data[0].advanced_emi_nos,
            applicant_name: result.data.data[0].applicant_name,
            approved_loan_amount: result.data.data[0].approved_loan_amount,
            tenure: result.data.tenure,
            interest_rate: result.data.interest_rate,
            asset_description: result.data.data[0].asset_description,
            cancellation_rebooking_reschedulement_charges:
              result.data.data[0].cancellation_rebooking_reschedulement_charges,
            cheque_bounce_charges: result.data.data[0].cheque_bounce_charges,
            cheque_swapping_charges:
              result.data.data[0].cheque_swapping_charges,
            collateral_id: result.data.data[0].collateral_id,
            collateral_type: result.data.data[0].collateral_type,
            create_date: result.data.data[0].create_date,
            date_created: result.data.data[0].date_created,
            disputed_property_reject_reason:
              result.data.data[0].disputed_property_reject_reason,
            emi_scheme: result.data.data[0].emi_scheme,
            end_date: result.data.end_date,
            final_decision_loan_status:
              result.data.data[0].final_decision_loan_status,
            foreclosure_charges: result.data.data[0].foreclosure_charges,
            id: result.data.data[0].id,
            late_payment_charges: result.data.data[0].late_payment_charges,
            loan_amount: result.data.data[0].loan_amount,
            loan_id: result.data.data[0].loan_id,
            mitigant_description: result.data.data[0].mitigant_description,
            modify_date: result.data.data[0].modify_date,
            payment_frequency: result.data.data[0].payment_frequency,
            place_of_agreement: result.data.data[0].place_of_agreement,
            processing_charges: result.data.data[0].processing_charges,
            processing_charges_amount:
              result.data.data[0].processing_charges_amount,
            remark_description: result.data.data[0].remark_description,
            repayment_schedule_charges:
              result.data.data[0].repayment_schedule_charges,
            risk_description: result.data.data[0].risk_description,
            start_date: result.data.start_date,
            transaction_fees_other: result.data.data[0].transaction_fees_other,
          };
          setfinalDecision(decision);
          if (
            result.data.start_date != "0000-00-00 00:00:00" &&
            result.data.start_date != ""
          ) {
            const startDate = dayjs(result.data.start_date);
            setSelectedDate(startDate);
          }
          if (
            result.data.end_date != "0000-00-00 00:00:00" &&
            result.data.end_date != ""
          ) {
            const endate = dayjs(result.data.end_date);
            setendDate(endate);
          }
          const selectedValueObj = repayment_frequency.find(
            (option) => option.label === result.data.data[0].payment_frequency
          );
          setSelectedOption12(selectedValueObj);
          console.log("Selected Value", selectedValueObj);
          if (basicInfo.loan_status.toLowerCase() == "approved") {
            setFinalDecision({ value: 2, label: "APPROVED" });
          }
          if (
            basicInfo.loan_status.toLowerCase() == "decline" ||
            basicInfo.loan_status.toLowerCase() == "declined"
          ) {
            setFinalDecision({ value: 3, label: "DECLINED" });
          }
          if (
            parseInt(result.data.maximum_loan_eligibility?.replace(/,/g, "")) <
            parseInt(result.data.data[0].approved_loan_amount)
          ) {
            setmaxLoanAmtCheck(true);
          } else {
            setmaxLoanAmtCheck(false);
          }
          // if (basicInfo.loan_status.toLowerCase() == "processing") {
          //   setFinalDecision({ value: 4, label: "PROCESSING" });
          // }
          // if (basicInfo.loan_status.toLowerCase() == "in-review") {
          //   setFinalDecision({ value: 5, label: "IN-REVIEW" });
          // }
          // if (basicInfo.loan_status.toLowerCase() == "referred") {
          //   setFinalDecision({ value: 6, label: "REFERRED" });
          // }
          // if (basicInfo.loan_status.toLowerCase() == "in-principle approval") {
          //   setFinalDecision({
          //     value: 1,
          //     label: "IN-PRINCIPLE APPROVAL",
          //   });
          // }
          setisAPILoading(false);
        }
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setisAPILoading(false);
          setIsErrorModalOpen(true);
          console.error(error);
        }
      }
      try {
        const email = await getEmail(reduxData);
        if (email.data.length != 0) {
          setuserEmail(result.data[0].email_id);
        }
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setIsErrorModalOpen(true);
          console.error(error);
        }
      }
    }
    getData();
  }, [reduxData]);
  const finalDecisionandPricing = async () => {
    setisAPILoading(true);
    const newData = {
      ...finalDecision,
      role_name: currentUser.role_name,
    };
    try {
      const result1 = await postFinalDecision(newData);
      navigate("/details");
      if (
        finalDecision.final_decision_loan_status.toLowerCase() == "approved"
      ) {
        // try {
        //   const schedule1 = await modifyRepaymentSchedule(reduxData);
        //   setisAPILoading(false);
        // } catch (error) {
        //   if (
        //     error.message === "AxiosError: Request failed with status code 401"
        //   ) {
        //     setisAPILoading(false);
        //     setIsErrorModalOpen(true);
        //     console.error(error);
        //   }
        // }
        // try {
        //   const schedule = await sendRepaymentSchedule(reduxData);
        //   setisAPILoading(false);
        // } catch (error) {
        //   if (
        //     error.message === "AxiosError: Request failed with status code 401"
        //   ) {
        //     setisAPILoading(false);
        //     setIsErrorModalOpen(true);
        //     console.error(error);
        //   }
        // }
      } else {
        const sendMail = await companyConfirmation(reduxData, userEmail);
        setisAPILoading(false);
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setisAPILoading(false);
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const handleClick = () => {
    setOpen1(!open1);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function numberWithCommas(x) {
    if (!isNaN(x)) {
      // Check if it's a number
      return x.toLocaleString();
    }
    return x;
  }
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const handleInputChange = (e) => {
    setfinalDecision({ ...finalDecision, [e.target.name]: e.target.value });
    if (e.target.name == "approved_loan_amount") {
      if (
        parseInt(maxLoanAmount?.replace(/,/g, "")) < parseInt(e.target.value)
      ) {
        setmaxLoanAmtCheck(true);
      } else {
        setmaxLoanAmtCheck(false);
      }
    }
  };
  const handleDateChange = (date) => {
    setStartDate(date);
    // Check if the date is valid
    if (dayjs(date).isValid()) {
      const formattedDate = dayjs(date).format("DD/MM/YYYY");
      setfinalDecision((prevState) => ({
        ...prevState,
        start_date: formattedDate,
      }));
      if (
        finalDecision.tenure != -99 &&
        finalDecision.tenure != "" &&
        finalDecision.tenure != undefined &&
        finalDecision.tenure != null
      ) {
        setendDate(
          dayjs(date).add(parseInt(finalDecision.tenure) - 1, "month")
        );
        const endDate = dayjs(date)
          .add(parseInt(finalDecision.tenure) - 1, "month")
          .format("DD/MM/YYYY");

        setfinalDecision((prevState) => ({
          ...prevState,
          end_date: endDate,
        }));
      }
    }
  };
  const handleendDateChange = (date) => {
    setendDate(dayjs(date));
    const endDate = dayjs(date).format("DD/MM/YYYY");
    setfinalDecision((prevState) => ({
      ...prevState,
      end_date: endDate,
    }));
  };
  const shouldDisableDate = (day) => {
    // Disable dates earlier than today
    return dayjs(day).isBefore(dayjs(), "day");
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <div>
        {/* Your component content */}
        <SessionModal
          isOpen={isErrorModalOpen}
          onClose={handleCloseErrorModal}
        />
      </div>
      <Box sx={{ display: "flex", mb: 6 }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <AppHeader
              open={open}
              toggleDrawer={toggleDrawer}
              title="Final Decision"
            />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {verificationSideNav}
            <Divider />
            {currentUser.role_name === "credit_head" ||
            currentUser.role_name === "credit_ops" ? (
              <>{secondaryListItemsDetails}</>
            ) : null}
          </List>
        </Drawer>
        <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
          {isAPILoading ? (
            <Grid item xs={12}>
              <LinearProgress
                sx={{
                  color: "#3f48cc",
                }}
                size={40}
                thickness={4}
              />
            </Grid>
          ) : null}
          <div style={{ marginTop: "1%" }}>
            <LoanDetails></LoanDetails>
            {finalDecision != undefined ? (
              <div className="details-box">
                <Grid item xs={12} sx={{ pt: 4 }}>
                  <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                    <Grid item xs={12} sm={6} md={6} mt={2}>
                      <TextField
                        fullWidth
                        type="text"
                        disabled={true}
                        variant="outlined"
                        name="Maximum Loan Amount"
                        label="Maximum Loan Amount"
                        value={maxLoanAmount}
                      />
                    </Grid>

                    {Object.entries(finalDecision)?.map(([key, value]) =>
                      key == "approved_loan_amount" ? (
                        <Grid key={key} item xs={12} sm={6} md={6} mt={2}>
                          <TextField
                            fullWidth
                            type="text"
                            variant="outlined"
                            name={key}
                            label={key
                              ?.replace(/_/g, " ")
                              ?.toLowerCase()
                              ?.split(" ")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ")}
                            value={
                              isNaN(value)
                                ? value
                                : Number(value).toLocaleString()
                            }
                            onChange={(e) => {
                              const newValue = e.target.value?.replace(/,/g, ""); // Remove commas
                              handleInputChange({
                                target: {
                                  name: key,
                                  value: newValue,
                                },
                              });
                            }}
                          />
                        </Grid>
                      ) : null
                    )}
                    {/* <Grid item xs={12} sm={6} md={6} mt={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Select Start Date"
                          value={selectedDate}
                          disabled={true}
                          onChange={handleDateChange}
                          shouldDisableDate={shouldDisableDate}
                          format="DD/MM/YYYY"
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                          className="dateCSS"
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} mt={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          fullWidth
                          label="Select End Date"
                          value={endDate}
                          disabled={true}
                          format="DD/MM/YYYY"
                          onChange={handleendDateChange}
                          shouldDisableDate={shouldDisableDate}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                          className="dateCSS"
                        />
                      </LocalizationProvider>
                    </Grid> */}
                    {Object.entries(finalDecision).map(([key, value]) =>
                      key != "disputed_property_reject_reason" &&
                      key != "final_decision_loan_status" &&
                      key != "role_name" &&
                      key != "id" &&
                      key != "loan_id" &&
                      key != "loan_amount" &&
                      key != "approved_loan_amount" &&
                      key != "payment_frequency" &&
                      key != "start_date" &&
                      key != "end_date" ? (
                        <>
                          {key == "processing_charges" ||
                          key == "processing_charges_amount" ||
                          key == "cheque_bounce_charges" ||
                          key == "cheque_swapping_charges" ||
                          key == "late_payment_charges" ||
                          key == "foreclosure_charges" ||
                          key ==
                            "cancellation_rebooking_reschedulement_charges" ||
                          key == "transaction_fees_other" ||
                          key == "advanced_emi_amount" ||
                          key == "repayment_schedule_charges" ? (
                            <Grid key={key} item xs={12} sm={6} md={6} mt={2}>
                              <TextField
                                fullWidth
                                type="text"
                                variant="outlined"
                                name={key}
                                label={key
                                  ?.replace(/_/g, " ")
                                  ?.toLowerCase()
                                  ?.split(" ")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(" ")}
                                value={
                                  isNaN(value)
                                    ? value
                                    : Number(value).toLocaleString()
                                }
                                onChange={(e) => {
                                  const newValue = e.target.value?.replace(
                                    /,/g,
                                    ""
                                  ); // Remove commas
                                  handleInputChange({
                                    target: {
                                      name: key,
                                      value: newValue,
                                    },
                                  });
                                }}
                              />
                            </Grid>
                          ) : (
                            <Grid key={key} item xs={12} sm={6} md={6} mt={2}>
                              <TextField
                                fullWidth
                                type="text"
                                variant="outlined"
                                name={key}
                                label={key
                                  ?.replace(/_/g, " ")
                                  ?.toLowerCase()
                                  ?.split(" ")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(" ")}
                                value={value}
                                onChange={(e) => {
                                  handleInputChange(e);
                                }}
                              />
                            </Grid>
                          )}
                        </>
                      ) : null
                    )}
                    {Object.entries(finalDecision).map(([key, value]) =>
                      key == "payment_frequency" ? (
                        <Grid key={key} item xs={12} sm={6} md={6} mt={2}>
                          <Select
                            placeholder="Payment Frequency *"
                            value={selectedOption1} // set selected value
                            options={repayment_frequency} // set list of the data
                            onChange={handlechangeRepayment} // assign onChange function
                          />
                        </Grid>
                      ) : null
                    )}
                    {Object.entries(finalDecision).map(([key, value]) =>
                      key == "disputed_property_reject_reason" &&
                      selectedDecision?.label == "DECLINED" ? (
                        <Grid key={key} item xs={12} sm={6} md={6} mt={2}>
                          <Select
                            placeholder="Select Reject Reason"
                            value={selectedOption} // set selected value
                            options={data} // set list of the data
                            onChange={handleChangeDropdownChange} // assign onChange function
                          />
                        </Grid>
                      ) : null
                    )}
                    {Object.entries(finalDecision).map(([key, value]) =>
                      key == "final_decision_loan_status" ? (
                        <Grid key={key} item xs={12} sm={6} md={6} mt={2}>
                          <Select
                            placeholder="Select Final Decision"
                            value={selectedDecision} // set selected value
                            options={data1} // set list of the data
                            onChange={handleChangeFinalDecision} // assign onChange function
                          />
                        </Grid>
                      ) : null
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  style={{
                    width: "100%",
                  }}
                >
                  <Button
                    disabled={
                      !makerChecker || // Check makerChecker condition first
                      (selectedDecision?.label !== "DECLINED" && // Check selectedDecision.label condition
                        (finalDecision.payment_frequency === "" ||
                          maxLoanAmtCheck ||
                          finalDecision.final_decision_loan_status === ""))
                    }
                    sx={{ textTransform: "none" }}
                    variant="contained"
                    className="download-button"
                    onClick={finalDecisionandPricing}
                  >
                    Finalize Decision
                  </Button>
                </Grid>
              </div>
            ) : null}
          </div>
        </Box>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}

export default function finalDecision() {
  return <FinalDecision />;
}
