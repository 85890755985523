import { ActionTypes } from "../constants/ActionTypes";
const intialState = {
  applications: [],
};

export const applicationReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_APPLICATION:
      return { ...state, applications: payload };
    default:
      return state;
  }
};

export const selectedApplicationReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case ActionTypes.SELECTED_APPLICATION:
      return { ...state, ...payload };
    default:
      return state;
  }
};
export const ApplicationdropdownReducer = (state = [], { type, payload }) => {
  switch (type) {
    case ActionTypes.ALL_APPLICATION_DROPDOWN:
      return { ...state, ...payload };
    default:
      return state;
  }
};
export const LoginDetailsReducer = (state = [], { type, payload }) => {
  switch (type) {
    case ActionTypes.LOGIN_DETAILS:
      return { ...state, ...payload };
    default:
      return state;
  }
};
export const bankDetailsReducer = (state = [], { type, payload }) => {
  switch (type) {
    case ActionTypes.BANK_DETAILS:
      return payload;
    default:
      return state;
  }
};
export const makerCheckerReducer = (state = [], { type, payload }) => {
  switch (type) {
    case ActionTypes.MAKER_CHECKER:
      return payload;
    default:
      return state;
  }
};
export const currentUserDetailsReducer = (state = [], { type, payload }) => {
  switch (type) {
    case ActionTypes.CURRENT_USER:
      return { ...state, ...payload };
    default:
      return state;
  }
};
