import React, { useState } from "react";
import {
  Box,
  Container,
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
  TextField,
  Divider,
  Link,
} from "@material-ui/core";
import "./../styles.css";
import Typography from "@mui/material/Typography";
import Axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import { base_url1 } from "../../constants";
import List from "@mui/material/List";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  GridRenderCellParams,
  GridColDef,
  useGridApiContext,
} from "@mui/x-data-grid";
import { mainListItems2, secondaryListItems } from "../Side_Nav/listItems";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TaskIcon from "@mui/icons-material/Task";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import {
  setLoanApplications,
  selectedApplication,
  selectedApplicationdropdown,
} from "../../redux/actions/LoanActions";
import LogoutIcon from "@mui/icons-material/Logout";
import LinearProgress from "@mui/material/LinearProgress";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { DialogActions } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { blueButtonStyle, greyButtonStyle } from "../Common/buttonStyling";
import { CustomDialog } from "../Common/dialog";
import SessionModal from "./../Common/SessionModal";
import {
  modifyUserInLDAP,
  deleteUserInLDAP,
  addUserInLDAP,
  getLDAPusersCMS,
} from "./../apiCalls/api";
export const theme = createTheme({
  typography: {
    // fontFamily: "Times New Roman",
  },
});

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
export default function CMSSettings() {
  const navigate = useNavigate();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [rows, setRows] = useStateWithCallbackLazy([]);
  const [data, setData] = useStateWithCallbackLazy([]);
  const [selectedApplications, setDetails] = React.useState({});
  const [isAPILoading, setisAPILoading] = React.useState(false);
  const [addUser, setaddUser] = React.useState(false);
  const [isUpdate, setisUpdate] = React.useState(false);
  const [emailError, setEmailError] = React.useState("");

  const [userDetails, setuserDetails] = useState({
    userName: "",
    email: "",
    mobileNo: "",
    roleName: "",
    branchName: "",
  });
  const resetUserDetails = () => {
    setuserDetails({
      userName: "",
      email: "",
      mobileNo: "",
      roleName: "",
      branchName: "",
    });
  };
  const handleUserDetails = (e) => {
    setuserDetails({
      ...userDetails,
      [e.target.name]: e.target.value,
    });
    if (e.target.name == "email") {
      validateEmail(e.target.value);
    }
  };
  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    navigate("/login");
  };
  const handleChangeRoleName = (e) => {
    setuserDetails((prevState) => ({
      ...prevState,
      roleName: e,
    }));
  };
  const validateEmail = (email) => {
    // Regular expression to validate email format
    const emailRegex = /^\S+@\S+\.\S+$/;

    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };
  const dispatch = useDispatch();

  useEffect(() => {
    async function getData() {
      refreshData();
    }
    getData();
  }, []);
  const addUserToDB = () => {
    setaddUser(true);
  };
  const handleClose = () => {
    setEmailError("");
    setaddUser(false);
    resetUserDetails();
    setisUpdate(false);
  };
  const handleCloseDelete = () => {
    setIsDialogOpen(false);
  };
  const removeItem = (id) => {
    const updatedData = rows.filter((item) => item.user_id !== id);
    setData(updatedData);
    setRows(updatedData);
  };
  const updateSingleRecord = (id, updatedData) => {
    refreshData();
    setisUpdate(false);
    resetUserDetails();
  };
  const updateRecord = async () => {
    setisAPILoading(true);
    const json = {
      user_id: userDetails.email,
      user_name: userDetails.userName,
      mobile_no: userDetails.mobileNo,
      role_name: userDetails.roleName,
      branch_name: userDetails.branchName,
      table: "cms",
    };
    try {
      const result = await modifyUserInLDAP(json);
      if (result) {
        setisAPILoading(false);
        handleClose();
        updateSingleRecord(userDetails.email, userDetails);
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setisAPILoading(false);
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const refreshData = async () => {
    setisAPILoading(true);
    // setisAPILoading(true);
    try {
      const result = await getLDAPusersCMS();
      if (result.data.data != []) {
        setData(result.data.data);
        setRows(result.data.data);
        setisAPILoading(false);
      } else {
        // if there is no data in the database
        setRows([]);
        setData([]);
        setisAPILoading(false);
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setisAPILoading(false);
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const handleDelete = async (e) => {
    setisAPILoading(true);
    try {
      const result = await deleteUserInLDAP(
        selectedApplications.user_id,
        selectedApplications.role_name,
        "cms"
      );
      if (result) {
        setIsDialogOpen(false);
        removeItem(selectedApplications.email_id);
        setisAPILoading(false);
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setisAPILoading(false);
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const [open, setOpen] = React.useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const columns = [
    {
      field: "user_id",
      headerName: "Email Id",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "mobile_no",
      headerName: "Mobile No.",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "role_name",
      headerName: "Role Name",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "user_name",
      headerName: "User Name",
      flex: 1,
      minWidth: 90,
    },
  ];
  const roleNameList = [
    {
      label: "Credit Ops",
      value: "credit_ops",
    },
    {
      label: "Headquarter Manager",
      value: "headquarter_manager",
    },
    {
      label: "Credit Head",
      value: "credit_head",
    },
    {
      label: "Lawyer",
      value: "lawyer",
    },
    {
      label: "External Agency",
      value: "external_agency",
    },
    {
      label: "Sales Ops",
      value: "sales_ops",
    },
  ];
  const Role = sessionStorage.getItem("Role");
  const actionColumn = [
    {
      field: "action",
      headerName: "Actions",
      width: 90,
      flex: 1,
      minWidth: 90,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div className="viewButton">
              <Tooltip title="Edit User" sx={{ mt: -1 }}>
                <IconButton
                  onClick={(e) => editandDelete(e, params.row, "edit")}
                  style={{ color: "#3f48cc" }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </div>
            <div className="viewButton">
              <Tooltip title="Delete User" sx={{ mt: -1 }}>
                <IconButton
                  onClick={(e) => editandDelete(e, params.row, "delete")}
                  style={{ color: "#ff0000" }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              <Dialog
                style={{ opacity: "0.3" }}
                fullWidth
                maxWidth="md"
                open={isDialogOpen}
                onClose={handleCloseDelete}
              >
                <DialogActions>
                  <IconButton
                    aria-label="delete"
                    onClick={handleCloseDelete}
                    sx={{
                      ":hover": {
                        color: "#0093db",
                        backgroundColor: "transparent",
                      },
                      color: "#000000",
                      position: "absolute",
                      top: "5px",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogActions>
                <DialogContent>
                  {" "}
                  <div style={{ textAlign: "center" }}>
                    <DeleteForeverIcon
                      sx={{ fontSize: "15vh", color: "#3f48cc" }}
                    />
                    <h3 style={{ marginTop: 0 }}>
                      You are about to delete a user
                    </h3>
                    <h5 style={{ fontWeight: "normal", marginBottom: 0 }}>
                      This will delete the user from the system
                    </h5>
                    <h5 style={{ fontWeight: "normal", marginTop: 0 }}>
                      Are you sure?
                    </h5>
                    <Stack
                      direction="row"
                      spacing={3}
                      sx={{ float: "right", marginBottom: "10px" }}
                    >
                      <Button onClick={handleCloseDelete} sx={greyButtonStyle}>
                        Cancel
                      </Button>
                      <Button
                        onClick={() => handleDelete(params.row)}
                        sx={blueButtonStyle}
                      >
                        Delete
                      </Button>
                    </Stack>
                  </div>
                </DialogContent>
              </Dialog>
            </div>
          </div>
        );
      },
    },
  ];
  const saveUserData = async (e) => {
    setisAPILoading(true);
    const json = {
      user_id: userDetails.email,
      user_name: userDetails.userName,
      mobile_no: userDetails.mobileNo,
      role_name: userDetails.roleName,
      branch_name: userDetails.branchName,
      table: "cms",
    };
    try {
      const result = await addUserInLDAP(json);
      if (result) {
        setisUpdate(false);
        refreshData();
        setisAPILoading(false);
        if (e == "Save") {
          handleClose();
        }
        resetUserDetails();
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setisAPILoading(false);
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const editandDelete = (e, selectedApplication1, action) => {
    setDetails(selectedApplication1);
    let bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    if (action == "delete") {
      setIsDialogOpen(true);
    }
    if (action == "edit") {
      setaddUser(true);
      setisUpdate(true);
      setuserDetails((prevState) => ({
        ...prevState,
        userName: selectedApplication1.user_name,
        email: selectedApplication1.user_id,
        mobileNo: selectedApplication1.mobile_no,
        roleName: selectedApplication1.role_name,
        branchName: selectedApplication1.BranchName,
      }));
    }
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) => rows.find((row) => row.id === id));
    setRowSelected(selectedRowsData);
  };
  return (
    <Container maxWidth align="center" style={{ marginTop: "-5%" }}>
      <div>
        {/* Your component content */}
        <SessionModal
          isOpen={isErrorModalOpen}
          onClose={handleCloseErrorModal}
        />
      </div>
      <Grid item xs={12}>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
          style={{
            width: "100%",
          }}
        >
          <Button
            sx={{ textTransform: "none" }}
            variant="contained"
            className="download-button"
            onClick={addUserToDB}
          >
            <AddIcon />
            Add User
          </Button>
          {addUser ? (
            <BootstrapDialog
              className={`addUser ${addUser ? "show" : ""}`}
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={addUser}
            >
              {isAPILoading ? (
                <Grid item xs={12}>
                  <LinearProgress
                    sx={{
                      color: "#3f48cc",
                    }}
                    size={40}
                    thickness={4}
                  />
                </Grid>
              ) : null}
              <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
                style={{
                  fontWeight: "800",
                  color: "#3f48cc",
                  display: "grid",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                Add User
              </BootstrapDialogTitle>

              <DialogContent>
                {" "}
                <Grid item xs={12} md={6} sx={{ mt: 4 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        disabled={isUpdate}
                        required
                        variant="outlined"
                        label="Email"
                        value={userDetails.email}
                        onChange={(e) => handleUserDetails(e)}
                        name="email"
                        id="email"
                        error={!!emailError}
                        helperText={emailError}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        required
                        variant="outlined"
                        label="User Name"
                        value={userDetails.userName}
                        onChange={(e) => handleUserDetails(e)}
                        name="userName"
                        id="userName"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        type={"number"}
                        fullWidth
                        required
                        variant="outlined"
                        label="Mobile Number"
                        value={userDetails.mobileNo}
                        onChange={(e) => handleUserDetails(e)}
                        name="mobileNo"
                        id="mobileNo"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        select
                        onChange={(e) => handleChangeRoleName(e.target.value)}
                        value={userDetails.roleName}
                        fullWidth
                        variant="outlined"
                        label="Role Name"
                      >
                        {roleNameList.map((option, key) => (
                          <MenuItem
                            key={key}
                            value={option.value}
                            sx={{
                              fontFamily: "'Roboto Condensed', sans-serif",
                              "&:hover": {
                                borderLeft: "5px solid #3f48cc",
                                borderRadius: 1,
                              },
                            }}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 5,
                      }}
                      style={{
                        width: "100%",
                      }}
                    >
                      {!isUpdate ? (
                        <>
                          <Button
                            sx={{ textTransform: "none" }}
                            variant="contained"
                            className="download-button"
                            onClick={(e) => saveUserData("Save")}
                            disabled={userDetails.email == "" || emailError}
                          >
                            Save
                          </Button>
                          <Button
                            sx={{ textTransform: "none" }}
                            variant="contained"
                            className="download-button"
                            onClick={(e) => saveUserData("SaveandAdd")}
                            disabled={userDetails.email == "" || emailError}
                          >
                            Save & Add Another
                          </Button>
                          <Button
                            sx={{ textTransform: "none" }}
                            variant="contained"
                            className="decline-button"
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            sx={{ textTransform: "none" }}
                            variant="contained"
                            className="download-button"
                            onClick={updateRecord}
                          >
                            Update
                          </Button>
                          <Button
                            sx={{ textTransform: "none" }}
                            variant="contained"
                            className="decline-button"
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
            </BootstrapDialog>
          ) : null}
        </Grid>
        <Grid>
          <div style={{ width: "98%" }}>
            <b>CMS Users</b>
            <div className="table-container">
              <DataGrid
                rows={data}
                columns={columns.concat(actionColumn)}
                getRowId={(row) => row.user_id}
                slots={{ toolbar: GridToolbar }}
                density="comfortable"
                onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                // onCellClick={handleEvent}
                disableSelectionOnClick={true} // disable selecting whole row
                sx={{
                  "&.MuiDataGrid-root": {
                    border: "none ",
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
                    outline: "none !important",
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-cell": {
                    whiteSpace: "normal !important",
                    wordWrap: "break-word !important",
                  },
                }}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
