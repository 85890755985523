import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  mainListItems,
  secondaryListItems,
  mainListItemsIndividual,
} from "../Side_Nav/listItems";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import LogoutIcon from "@mui/icons-material/Logout";
import Tooltip from "@mui/material/Tooltip";
import LoanDetails from "../Common/basicLoanDetails";
import { base_url1 } from "../../constants";
import { useSelector } from "react-redux";
import Axios from "axios";
import { useEffect } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AppHeader from "./../Common/header";
import SessionModal from "./../Common/SessionModal";
import { useNavigate } from "react-router-dom";
import { getCMSDocuments,getDownloadUrl } from "./../apiCalls/api";
import { useState } from "react";
import Footer from "./../Common/footer";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();
const handleChangeInput = (event) => {};
function TaxFiling() {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [filterValueGuarantor, setFilterValueGuarantor] =
    React.useState("GuarantorITR");
  const [filteredDocumentsGuarantor, setFilteredDocumentsGuarantor] =
    React.useState([]);
  const [filterValue, setFilterValue] = React.useState("ITR");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [downloadUrl, setDownloadUrl] = React.useState();
  const [filteredDocuments, setFilteredDocuments] = React.useState([]);
  const reduxData = useSelector(
    (state) => state.applications?.loan_basic_info?.Loan_Application_id
  );
   const currentUser = useSelector((state) => state.currentUserDetails);
  const loanType = useSelector(
    (state) => state.applications?.loan_basic_info?.loan_type
  );
  useEffect(() => {
    async function getData() {
      try {
        const result = await getCMSDocuments(reduxData);
        if (result.data.data.length != 0) {
          const filteredList1 = result.data.data.filter((item) => {
            return item.DocumentType.startsWith(filterValueGuarantor);
          });
          const filteredList2 = result.data.data.filter((item) => {
            return item.DocumentType.startsWith(filterValue);
          });
          setFilteredDocuments(filteredList2);
          setFilteredDocumentsGuarantor(filteredList1);
        } else {
          setFilteredDocumentsGuarantor([]);
          setFilteredDocuments([]);
        }
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setIsErrorModalOpen(true);
          console.error(error);
        }
      }
    }
    getData();
  }, [reduxData]);
  const columns = [
    {
      field: "Filename",
      headerName: "File Name",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "cms_upload",
      headerName: "Uploaded from Credit Ops",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "DocumentType",
      headerName: "Document Type",
      flex: 1,
      minWidth: 90,
    },
  ];
  const actionColumn = [
    {
      field: "action",
      headerName: "Actions",
      width: 90,
      flex: 1,
      minWidth: 90,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div className="viewButton">
              <Tooltip title="Download File" sx={{ mt: -1 }}>
                <IconButton
                  onClick={(e) => downloadSingleFile(params.row)}
                  style={{ color: "#3f48cc" }}
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="View File" sx={{ mt: -1 }}>
                <IconButton
                  onClick={(e) => handleViewClick(params.row)}
                  style={{ color: "#3f48cc" }}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];
  const downloadSingleFile = async (e) => {
    try {
      const result = await getDownloadUrl(
        e.Filepath
      );
      setDownloadUrl(result.data.response_data.download_url);
      window.open(result.data.response_data.download_url, "_blank");
    }catch (error) {
      if (error.message) {
        console.error("Error:", error);
      }
    }  
  };
  const handleViewClick = (e) => {
    const blobUrl = e.Filepath;
    viewFile(blobUrl);
  };
  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    navigate("/login");
  };
  const viewFile = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;

    const isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    if (isChrome) {
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.target = "_blank";
      anchor.click();
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <div>
        {/* Your component content */}
        <SessionModal
          isOpen={isErrorModalOpen}
          onClose={handleCloseErrorModal}
        />
      </div>
      <Box sx={{ display: "flex", mb: 6 }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <AppHeader
              open={open}
              toggleDrawer={toggleDrawer}
              title="Tax Filing"
            />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          {loanType == "Individual" ? (
            <List component="nav">
              {mainListItemsIndividual}
              <Divider />
              {currentUser.role_name === "credit_head" ||
              currentUser.role_name === "credit_ops" ? (
                <>{secondaryListItems}</>
              ) : null}
            </List>
          ) : (
            <List component="nav">
              {mainListItems}
              <Divider />
              {currentUser.role_name === "credit_head" ||
              currentUser.role_name === "credit_ops" ? (
                <>{secondaryListItems}</>
              ) : null}
            </List>
          )}
        </Drawer>
        <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Individual ITR Document"
                {...a11yProps(0)}
                style={{ textTransform: "capitalize" }}
              />
              <Tab
                label="Guarantor's ITR"
                {...a11yProps(1)}
                style={{ textTransform: "capitalize" }}
              />
            </Tabs>
          </Box>
          <div style={{ marginTop: "1%" }}>
            <LoanDetails></LoanDetails>
            <TabPanel value={value} index={0}>
              {filteredDocuments.length > 0 ? (
                <div className="details-box">
                  <Grid item xs={12} sx={{ pt: 4 }}>
                    <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography
                          sx={{ mr: 4, flex: 1, pt: 2, pb: 2 }}
                          component="div"
                          style={{ fontWeight: "800", color: "#3f48cc" }}
                        >
                          Individual ITR Document
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {filteredDocuments.length > 0 ? (
                    <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
                      {/* <img src={pic1} style={{ margin: "1%" }} /> */}
                      <Container maxWidth align="center">
                        <Grid item xs={12} sx={{ pt: 4 }}>
                          <Grid>
                            <div style={{ width: "98%" }}>
                              <b>List of Documents</b>
                              <div className="table-container1">
                                <DataGrid
                                  rows={filteredDocuments}
                                  columns={columns.concat(actionColumn)}
                                  getRowId={(row) => row.ApplicantDocumentId}
                                  density="comfortable"
                                  // onCellClick={handleEvent}
                                  disableSelectionOnClick={true} // disable selecting whole row
                                  sx={{
                                    "&.MuiDataGrid-root": {
                                      border: "none ",
                                    },
                                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within":
                                      {
                                        outline: "none !important",
                                      },
                                    "&.MuiDataGrid-root .MuiDataGrid-columnHeader--sortable":
                                      {
                                        outline: "none !important",
                                      },
                                    "&.MuiDataGrid-root .MuiDataGrid-cell": {
                                      whiteSpace: "normal !important",
                                      wordWrap: "break-word !important",
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Container>
                    </Box>
                  ) : null}
                </div>
              ) : null}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {filteredDocumentsGuarantor.length > 0 ? (
                <div className="details-box">
                  <Grid item xs={12} sx={{ pt: 4 }}>
                    <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography
                          sx={{ mr: 4, flex: 1, pt: 2, pb: 2 }}
                          component="div"
                          style={{ fontWeight: "800", color: "#3f48cc" }}
                        >
                          Guarantor's ITR
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {filteredDocumentsGuarantor.length > 0 ? (
                    <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
                      {/* <img src={pic1} style={{ margin: "1%" }} /> */}
                      <Container maxWidth align="center">
                        <Grid item xs={12} sx={{ pt: 4 }}>
                          <Grid>
                            <div style={{ width: "98%" }}>
                              <b>List of Documents</b>
                              <div className="table-container1">
                                <DataGrid
                                  rows={filteredDocumentsGuarantor}
                                  columns={columns.concat(actionColumn)}
                                  getRowId={(row) => row.ApplicantDocumentId}
                                  density="comfortable"
                                  // onCellClick={handleEvent}
                                  disableSelectionOnClick={true} // disable selecting whole row
                                  sx={{
                                    "&.MuiDataGrid-root": {
                                      border: "none ",
                                    },
                                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within":
                                      {
                                        outline: "none !important",
                                      },
                                    "&.MuiDataGrid-root .MuiDataGrid-columnHeader--sortable":
                                      {
                                        outline: "none !important",
                                      },
                                    "&.MuiDataGrid-root .MuiDataGrid-cell": {
                                      whiteSpace: "normal !important",
                                      wordWrap: "break-word !important",
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Container>
                    </Box>
                  ) : null}
                </div>
              ) : null}
            </TabPanel>
          </div>
        </Box>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}

export default function taxFiling() {
  return <TaxFiling />;
}
