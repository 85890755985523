import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { mainListItems2, secondaryListItems } from "../Side_Nav/listItems";
import { Dropdown } from "react-bootstrap";
import LogoutIcon from "@mui/icons-material/Logout";
import Tooltip from "@mui/material/Tooltip";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Bar, Line, Pie, Doughnut, Radar } from "react-chartjs-2";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import Axios from "axios";
import { base_url1 } from "../../constants";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoanApplications } from "../../redux/actions/LoanActions";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS, Legend, ArcElement } from "chart.js";
import { CategoryScale, registerables } from "chart.js";
import { Card, CardContent } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import Reports from "./../reports/reportsv2";
import { getAllApplications, getDashboardData } from "./../apiCalls/api";
import { useNavigate } from "react-router-dom";
import SessionModal from "./../Common/SessionModal";
import AppHeader from "./../Common/header";
import Footer from "./../Common/footer";
// for pie: ArcElement, not using any scales
ChartJS.register(Legend, ArcElement, CategoryScale, ...registerables);
// Chart.register(CategoryScale);

const drawerWidth = 240;
// Graph Option Configuration
export const Options = {
  layout: {
    padding: {
      top: 10,
    },
  },
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "right",
      align: "start",
      labels: {
        usePointStyle: true, // set this to true
        boxWidth: 7, // set the box width to the desired size
      },
    },
    datalabels: {
      color: "#000",
      anchor: "end",
      align: "start",
      font: {
        weight: "bold",
        size: "15",
      },
    },
  },
};
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));
function getRandomColor() {
  let r = Math.floor(Math.random() * 256); // Random red value between 0 and 255
  let g = Math.floor(Math.random() * 256); // Random green value between 0 and 255
  let b = Math.floor(Math.random() * 256); // Random blue value between 0 and 255
  let a = Math.random(); // Random alpha value between 0 and 1

  let color = `rgba(${r}, ${g}, ${b}, ${a.toFixed(2)})`;
  return color;
}

function getRandomColors(n, shade) {
  let random_color_list = [];
  for (var i = 0; i < n; i++) {
    random_color_list = [...random_color_list, getRandomColor(shade)];
  }

  return random_color_list;
}
const mdTheme = createTheme();
function MainDashboard() {
  const [open, setOpen] = React.useState(true);
  const [isDashboard, setisDashboard] = React.useState(true);
  const [dataLoaded, setdataloaded] = React.useState(false);
  const [bgColor, setBgColor] = useState([]);
  const [borderColor, setBorderColor] = useState([]);
  const [data, setData] = useStateWithCallbackLazy([]);
  const [modelPredictionCount, setModelPredictionCount] = useState(0);
  const [filteredModelPrediction, setFilteredModelPrediction] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loanProduct, setLoanProduct] = useState([]);
  const [monthlyApplication, setmonthlyApplication] = useState([]);
  const [branchwiseApplications, setBranchwiseApplications] = useState([]);
  const [pincodewiseApplications, setPincodewiseApplications] = useState([]);
  const [reasonForDecision, setreasonForDecision] = useState([]);
  const [isAPILoading, setisAPILoading] = React.useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const currentUser = useSelector((state) => state.currentUserDetails);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    async function getData() {
      setisAPILoading(true);
      try {
        const results = await getDashboardData();
        if (results) {
          // setLoanProduct(results.data.loan_product);
          setLoanProduct(results?.categorywise);
          setmonthlyApplication(results?.monthwise);
          setBranchwiseApplications(results?.branchwise);
          // setmonthlyApplication(results.data.monthly_applications);
          // setreasonForDecision(results.data.reason_for_decision);
          setPincodewiseApplications(results?.pincodewise);
          setisAPILoading(false);
        }
        // if (result.data.data != []) {
        //   setData(result.data.data);
        //   updatingGraph(result.data.data);
        //   dispatch(setLoanApplications(result.data.data));
        //   const results = await getDashboardData();
        //   if (results.data) {
        //     // setLoanProduct(results.data.loan_product);
        //     setLoanProduct(sample_loan_category_data);
        //     setmonthlyApplication(sample_monthly_data);
        //     setBranchwiseApplications(sample_branch_data);
        //     // setmonthlyApplication(results.data.monthly_applications);
        //     // setreasonForDecision(results.data.reason_for_decision);
        //     setisAPILoading(false);
        //   }
        // } else {
        //   // if there is no data in the database
        //   setRows([]);
        //   setisAPILoading(false);
        // }
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setisAPILoading(false);
          setIsErrorModalOpen(true);
        }
      }
    }
    getData();
  }, []);
  useEffect(() => {
    if (currentUser.role_name == "branch_manager") {
      setisDashboard(!isDashboard);
    }
  }, []);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const changeIsDashboard = () => {
    setisDashboard(!isDashboard);
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    navigate("/login");
  };
  const handleItemClick = (item) => {
    // Handle item click logic here
    setIsOpen(false);
  };
  const CapitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const updatingGraph = (data) => {
    const getAllModelPrediction = data.map((obj, index) =>
      obj.loan_product_name && obj.loan_product_name.trim() !== ""
        ? CapitalizeWords(obj.loan_product_name)
        : ""
    );
    const filteredModelPrediction = getAllModelPrediction.filter(
      (element, index) => getAllModelPrediction.indexOf(element) === index
    );
    const modelPredictionCount = filteredModelPrediction.map((item) => {
      var count = 0;
      getAllModelPrediction.map((element) => {
        if (CapitalizeWords(element) === item) {
          count += 1;
        }
        return "";
      });
      return count;
    });
    setFilteredModelPrediction(filteredModelPrediction);
    setModelPredictionCount(modelPredictionCount);
    var colorLength = Math.max(filteredModelPrediction.length);
    for (var i = 0; i < colorLength; i++) {
      setBgColor((prev) => [...prev, getRandomColor("green")]);
      setBorderColor((prev) => [...prev, "#fff"]);
    }
    setdataloaded(true);
  };

  const loanProductGraph = {
    labels: loanProduct.labels,
    datasets: [
      {
        data: loanProduct.data,
        fill: false,
        backgroundColor: getRandomColors(loanProduct?.data?.length, "blue"),
        borderColor: ["#fff"],
        borderWidth: 1,
      },
    ],
  };
  const branchApplicationsGraph = {
    labels: branchwiseApplications.labels,
    datasets: [
      {
        data: branchwiseApplications.data,
        fill: false,
        backgroundColor: getRandomColors(
          branchwiseApplications?.data?.length,
          "blue"
        ),
        borderColor: ["#fff"],
        borderWidth: 1,
      },
    ],
  };
  const monthlyApplicationGraph = {
    labels: monthlyApplication.labels,
    datasets: [
      {
        data: monthlyApplication.data,
        fill: true,
        backgroundColor: getRandomColors(
          monthlyApplication?.data?.length,
          "blue"
        ),
        borderColor: ["#fff"],
        borderWidth: 1,
      },
    ],
  };

  const pincodewiseApplciationsGraph = {
    labels: pincodewiseApplications.labels,
    datasets: [
      {
        data: pincodewiseApplications.data,
        fill: true,
        backgroundColor: getRandomColors(
          pincodewiseApplications?.data?.length,
          "red"
        ),
        borderColor: ["#fff"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <div>
        {/* Your component content */}
        <SessionModal
          isOpen={isErrorModalOpen}
          onClose={handleCloseErrorModal}
        />
      </div>
      <Box sx={{ display: "flex", mb: 6 }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <AppHeader
              open={open}
              toggleDrawer={toggleDrawer}
              title="Dashboard"
            />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems2}
            <Divider sx={{ my: 1 }} />
            {/* {secondaryListItems} */}
          </List>
        </Drawer>
        <Box
          style={{
            marginTop: "3%",
            minWidth: "83%",
            width: "auto",
            maxWidth: "100%",
          }}
        >
          {isAPILoading ? (
            <Grid item xs={12} style={{ marginTop: "2%" }}>
              <LinearProgress
                sx={{
                  color: "#3f48cc",
                }}
                size={40}
                thickness={4}
              />
            </Grid>
          ) : null}
          {/* <img src={pic1} style={{ margin: "1%" }} /> */}
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
            style={{
              width: "100%",
            }}
          >
            {currentUser.role_name != "branch_manager" ? (
              <>
                {isDashboard ? (
                  <Button
                    sx={{ textTransform: "none" }}
                    variant="contained"
                    className="download-button"
                    onClick={changeIsDashboard}
                  >
                    Reports
                  </Button>
                ) : (
                  <Button
                    sx={{ textTransform: "none" }}
                    variant="contained"
                    className="download-button"
                    onClick={changeIsDashboard}
                  >
                    Dashboard
                  </Button>
                )}
              </>
            ) : null}
          </Grid>
          {isDashboard ? (
            <Container maxWidth align="center">
              <Grid item xs={12} mt={3}>
                <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={4}
                    sx={{
                      mt: { xs: 2, sm: 3, md: 4 }, // Top margin: 2 on xs, 3 on sm, 4 on md screens
                      mb: { xs: 2, sm: 3, md: 4 }, // Bottom margin: 2 on xs, 3 on sm, 4 on md screens
                    }}
                  >
                    <Typography component="p" variant="h6">
                      Loan Applications by Category
                    </Typography>
                    <CardContent
                      sx={{
                        display: "grid",
                        alignContent: "center",
                        justifyContent: "center",
                        padding: "0px 8px",
                      }}
                    >
                      <Doughnut
                        data={loanProductGraph}
                        options={Options}
                        plugins={[ChartDataLabels]}
                      />
                    </CardContent>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={4}
                    sx={{
                      mt: { xs: 2, sm: 3, md: 4 }, // Top margin: 2 on xs, 3 on sm, 4 on md screens
                      mb: { xs: 2, sm: 3, md: 4 }, // Bottom margin: 2 on xs, 3 on sm, 4 on md screens
                    }}
                  >
                    <Typography component="p" variant="h6">
                      Monthly Applications
                    </Typography>
                    <CardContent
                      sx={{
                        display: "grid",
                        alignContent: "center",
                        justifyContent: "center",
                        padding: "0px 8px",
                      }}
                    >
                      <Bar
                        data={monthlyApplicationGraph}
                        options={Options}
                        plugins={[ChartDataLabels]}
                      />
                    </CardContent>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={4}
                    sx={{
                      mt: { xs: 2, sm: 3, md: 4 }, // Top margin: 2 on xs, 3 on sm, 4 on md screens
                      mb: { xs: 2, sm: 3, md: 4 }, // Bottom margin: 2 on xs, 3 on sm, 4 on md screens
                    }}
                  >
                    <Typography component="p" variant="h6">
                      Branchwise Loan Applications
                    </Typography>
                    <CardContent
                      sx={{
                        display: "grid",
                        alignContent: "center",
                        justifyContent: "center",
                        padding: "0px 8px",
                      }}
                    >
                      <Pie
                        data={branchApplicationsGraph}
                        options={Options}
                        plugins={[ChartDataLabels]}
                      />
                    </CardContent>
                  </Grid>
                  {/* <Grid item xs={6} sm={4} md={12} 
                    sx={{
                      mt: { xs: 2, sm: 3, md: 4 }, // Top margin: 2 on xs, 3 on sm, 4 on md screens
                      mb: { xs: 2, sm: 3, md: 4 }, // Bottom margin: 2 on xs, 3 on sm, 4 on md screens
                    }}
                  >
                    <Typography component="p" variant="h6">Pincodewise Loan Applications</Typography>
                    <CardContent
                      sx={{
                        display: "grid",
                        alignContent: "center",
                        justifyContent: "center",
                        padding: "0px 8px",
                      }}
                    >
                      <Bar
                        data={pincodewiseApplciationsGraph}
                        options={Options}
                        plugins={[ChartDataLabels]}
                      />
                    </CardContent>
                  </Grid> */}
                </Grid>
              </Grid>
            </Container>
          ) : (
            <Container maxWidth align="center">
              <Grid item xs={12} mt={3}>
                {currentUser.role_name == "branch_manager" ? (
                  <Grid item xs={12} mt={10}></Grid>
                ) : null}
                <Reports />
              </Grid>
            </Container>
          )}
        </Box>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}

export default function Maindashboard() {
  return <MainDashboard />;
}
